<template>
  <!--    图片添加修改-->
  <div class="imageBox" v-loading="showLoading">
    <el-form ref="form" :model="funnyPlayItem" label-width="120px">
      <el-form-item label="标题">
        <el-input v-model="funnyPlayItem.title" class="input"
                  :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="提示">
        <el-input v-model="funnyPlayItem.toast" class="input"
                  :disabled="true"
        ></el-input>
      </el-form-item>
      <el-form-item label="图标">
        <el-upload
            class="avatar-uploader"
            action="none"
            :show-file-list="false"
            :on-remove="handleRemove"
            :on-change="fileChange"
            :before-upload="beforeAvatarUpload"
            :auto-upload="false">
          <!-- 图片预览 -->
          <div class="avatar-wrapper">
            <img v-if="getImgUrl(funnyPlayItem)" :src="getImgUrl(funnyPlayItem)" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            <!-- 删除按钮 -->
            <i v-if="getImgUrl(funnyPlayItem)" class="el-icon-delete-solid delete-icon" @click="handleRemove"></i>
          </div>
          <div slot="tip" class="el-upload__tip">
            上传一张图片，且不超过1MB，注意图标尺寸，正方形图标，建议128x128或者256x256
          </div>
        </el-upload>
      </el-form-item>

      <el-form-item label="跳转链接">
        <el-input v-model="funnyPlayItem.url" class="input"
                  :disabled="true"
        ></el-input>
      </el-form-item>
      <el-form-item label="类型tag">
        <el-input v-model="funnyPlayItem.tag" class="input"
                  :disabled="true"
        ></el-input>
      </el-form-item>
      <el-form-item class="btn">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "FunnyPlayEdit",
  props: ['funnyPlayItem', "isUpdate"],
  data: function () {
    return {
      updateFunnyPlayItem: {},
      showLoading: false,
      isUpdateImgUrl: false,
      imageUrl: "",
      imageFile: null
    }
  },
  mounted() {
  },
  methods: {
    //获取图标地址
    getImgUrl(funnyPlayerItem) {
      //如果没有更新，使用原来的趣玩原始图标
      if (!this.isUpdateImgUrl && funnyPlayerItem && funnyPlayerItem.imgUrl) {
        this.imageUrl = funnyPlayerItem.imgUrl;
      }
      return this.imageUrl;
    },
    // 处理图片选择
    fileChange(file) {
      this.imageFile = file.raw;
      // 判断文件是否存在
      if (file.raw) {
        // 使用 URL.createObjectURL 来生成临时的 URL 预览图像
        this.imageUrl = URL.createObjectURL(file.raw);
      }
      this.isUpdateImgUrl = true;
      console.log("imageUrl:" + this.imageUrl)
    },
    // 销毁之前的预览 URL，避免内存泄漏
    handleRemove() {
      event.stopPropagation(); // 阻止事件冒泡
      this.imageUrl = '';
      this.isUpdateImgUrl = true;
      this.imageFile = null;
    },
    beforeAvatarUpload(file) {
      const isImage = file.type.startsWith('image/'); // 判断是否为图片格式
      const isLt1M = file.size / 1024 / 1024 < 1;     // 判断文件大小是否小于1MB
      if (!isImage) {
        this.$message.error('上传的文件必须是图片格式!');
      }
      if (!isLt1M) {
        this.$message.error('上传图片大小不能超过 1MB!');
      }
      return isImage && isLt1M;
    },
    save: function () {
      if (!this.isUpdate) {
        this.addNewFunnyPlay()
      } else {
        this.updateFunnyPlay()
      }
    },
    cancel: function () {
      this.$emit('saveStatus', false)
    },
    addNewFunnyPlay: function () {
      //先上传图标，上传成功后获取图片地址后，调用新增趣玩接口
      if (this.funnyPlayItem.title === undefined || this.funnyPlayItem.title === "") {
        this.showErrorMessage("请输入标题")
        return
      }

      if (this.funnyPlayItem.imgUrl === undefined || this.funnyPlayItem.imgUrl === "") {
        this.showErrorMessage("请上传图标")
        return
      }
      const params = this.funnyPlayItem
      console.log("上传参数", params)
      // const that = this;
      // this.showLoading = true;
      // this.$request.post("/funnyPlay/addFunnyPlay", params, {
      //   // json格式
      //   headers: {
      //     "Content-Type": "application/json"
      //   },
      // }).then(function (data) {
      //   that.showLoading = false
      //   if (data.status === 200) {
      //     that.$confirm("添加成功", "提示", {
      //       confirmButtonText: '确定',
      //       showClose: false,
      //       showCancelButton: false,
      //       center: true,
      //       type: 'success'
      //     }).then(function () {
      //       that.$emit('saveStatus', true)
      //     })
      //   } else {
      //     that.showErrorMessage(data.data)
      //   }
      // }).catch(function (err) {
      //   that.showLoading = false
      //   that.showErrorMessage("网络错误")
      // })
    },
    async updateFunnyPlay() {
      const that = this;
      // Upload the file and get the image URL
      const imgPath = await this.uploadFile();
      this.imageUrl = "http://file.baozouptu.intelimeditor.com/" + imgPath
      //上传图片，返回图片路径，
      const params = {
        "id": this.funnyPlayItem.id,
        "imgUrl": this.imageUrl
      }
      console.log("params", params)
      this.showLoading = true;
      await this.$request.post("/funnyPlay/updateFunnyPlay", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        }
      }).then(function (data) {
        that.showLoading = false
        if (data.status === 200) {
          that.$confirm("修改成功", "提示", {
            confirmButtonText: '确定',
            showClose: false,
            showCancelButton: false,
            center: true,
            type: 'success'
          }).then(function () {
            that.$emit('saveStatus', true)
          })
        } else {
          that.showErrorMessage(data.data)
        }
      }).catch(function (err) {
        that.showLoading = false
        that.showErrorMessage("网络错误")
      })
    },// 上传文件
    async uploadFile() {
      try {
        if (this.imageFile === undefined || this.imageFile === null) {
          this.showErrorMessage("请选择一个文件");
          return;
        }
        const params = new FormData();
        params.append("file", this.imageFile);
        // 上传到对应趣玩的目录下
        params.append("path", "funnyPlay/");
        const response = await this.$request.post("/file/upload", params);
        console.log("data = " + JSON.stringify(response));
        if (response.status === 200) {
          return response.data.url; // Assuming the API response contains an 'imgUrl' field
        } else {
          this.showErrorMessage("文件上传失败");
          throw new Error('Upload failed');
        }
      } catch (err) {
        console.error("请求结果返回错误: " + err.message);
        this.showErrorMessage("文件上传失败");
        throw err;
      }
    }
    ,
    /**
     * 显示错误信息弹框
     * @param msg 错误信息弹框
     */
    showErrorMessage: function (msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    }

    ,
  }
}
</script>

<style scoped>
.imageBox {
  width: 100%;
  min-height: 300px;
}

.btn /deep/ .el-form-item__content {
  margin-left: 0 !important;
  text-align: center;
}

.input {
  width: 300px;
}


.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.avatar-wrapper {
  position: relative;
  display: inline-block;
}

.delete-icon {
  position: absolute;
  top: -5px;
  right: -5px;
  color: red;
  font-size: 20px;
  cursor: pointer;
  background-color: white;
  border-radius: 50%;
  padding: 2px;
}

</style>