<template>
  <!--    图片添加修改-->
  <div class="imageBox" v-loading="showLoading">
    <el-form ref="form" :model="audioPack" label-width="120px">
      <el-form-item label="语音包名">
        <el-input v-model="audioPack.name" class="input"></el-input>
      </el-form-item>
      <el-form-item label="标签">
        <el-input v-model="audioPack.tag" class="input"></el-input>
      </el-form-item>
      <el-form-item label="热度">
        <el-input v-model="audioPack.heat" class="input" type="number"></el-input>
      </el-form-item>
      <el-form-item label="语音:" v-show="isShowFontPreview">
        <div class="font_preview">
          <audio ref="audio" controls="" :src="audioPack.url"/>
        </div>
      </el-form-item>
      <el-form-item label="上传语音文件">
        <el-upload
            class="upload-demo"
            action="none"
            :file-list="audioPack.fileList"
            :on-exceed="imageCountOut"
            :on-remove="handleRemove"
            :on-change="fileChange"
            :before-upload="beforeUpload"
            :auto-upload="false"
            :limit="1">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传一个文件，且不超过20MB</div>
        </el-upload>
        <!-- 回显列表 -->
      </el-form-item>
      <el-form-item class="btn">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "AudioPackEdit",
  props: ['audioPack', "isUpdate", "isTemplateInto"],
  data: function () {
    return {
      music_path: "",
      showLoading: false,
      fontName: "",
      name: "",
      isShowFontPreview: false,
      fontImagePreview: null//字体预览文件
    }
  },
  methods: {
    // 文件上传前回调   createObjectURL 会创建一个 url 地址
    beforeUpload(file) {
      this.fileList.push({
        src: URL.createObjectURL(file),
        name: file.name,
        id: new Date().getTime()
      })
    },
    imageCountOut: function () {
      this.showErrorMessage("只能选择一个文件")
    },
    /**
     * 图片移除回调
     * @param file
     * @param fileList
     */
    handleRemove: function (file, fileList) {
      this.audioPack.file = null
      this.isShowFontPreview = false
    },
    /**
     * 图片选择回调
     * @param file
     */
    fileChange: function (file) {
      this.isShowFontPreview = true
      this.audioPack.file = file.raw
      this.audioPack.url = URL.createObjectURL(file.raw)
      console.log(" file " + this.audioPack.url)

      //设置名字、热度
      this.$set(this.audioPack, "name", file.raw.name.substring(0, file.raw.name.indexOf('.')))
      this.$set(this.audioPack, "heat", 4000)
    },
    /**
     * 显示错误信息弹框
     * @param msg 错误信息弹框
     */
    showErrorMessage: function (msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
    cancel: function () {
      this.$emit('saveStatus', false)
    },
    /**
     * 保存上传
     * */
    save() {
      if (!this.isUpdate) {
        this.addAudioPack()
      } else {
        this.updateAudioPack()
      }
    },
    addAudioPack: function () {
      if (this.audioPack.name === undefined || this.audioPack.name === "") {
        this.showErrorMessage("请输入语音名称")
        return
      }
      if (this.audioPack.tag === undefined || this.audioPack.tag === "") {
        this.showErrorMessage("请输入语音所属于的标签")
        return
      }
      if (this.audioPack.heat === undefined || this.audioPack.heat === "") {
        this.showErrorMessage("请输入语音热度")
        return
      }
      if (this.audioPack.file === undefined || this.audioPack.file == null) {
        this.showErrorMessage("请选择一个语音文件")
        return
      }
      this.showLoading = true;
      const that = this;
      const params = new FormData()
      params.append("file", this.audioPack.file)
      params.append("name", this.audioPack.name)
      params.append("tag", this.audioPack.tag)
      params.append("heat", this.audioPack.heat)

      this.$request.post("/audio/addAudioPack", params)
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("添加成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.$emit('saveStatus', true)
              })
            } else {
              that.showErrorMessage(data.message)
            }
          }).catch(function (err) {
        that.showLoading = false
        that.showErrorMessage("网络错误")
      })
    },
    updateAudioPack: function () {
      if (this.audioPack.name === undefined || this.audioPack.name === "") {
        this.showErrorMessage("请输入字体名称")
        return
      }
      if (this.audioPack.tag === undefined || this.audioPack.tag === "") {
        this.showErrorMessage("请输入语音所属于的标签")
        return
      }
      this.showLoading = true;
      const that = this;
      const params = new FormData()
      if (this.audioPack.file !== undefined && null !== this.audioPack.file) {
        params.append("file", this.audioPack.file)
      }
      params.append("id", this.audioPack.id)
      params.append("name", this.audioPack.name)
      params.append("tag", this.audioPack.tag)
      params.append("originUrl", this.audioPack.url)
      params.append("heat", this.audioPack.heat)
      this.$request.post("/audio/updateAudioPack", params)
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("修改成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.$emit('saveStatus', true)
              })
            } else {
              that.showErrorMessage(data.data)
            }
          }).catch(function (err) {
        that.showLoading = false
        that.showErrorMessage("网络错误," + err)
      })
    },
  },
}
</script>

<style scoped>
.imageBox {
  width: 100%;
  min-height: 300px;
}

.btn /deep/ .el-form-item__content {
  margin-left: 0 !important;
  text-align: center;
}

.input {
  width: 300px;
}

.font_preview {
  width: 200px;
  height: 40px;
  font-family: fontPreview;
}

.font_preview canvas, .font_preview img {
  width: 100px;
  height: 40px;
}
</style>