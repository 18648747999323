<template>
    <!--    图片添加修改-->
    <div class="imageBox" v-loading="showLoading">
        <el-form ref="form" :model="tutorial" label-width="120px">
            <el-form-item label="教程标题">
                <el-input v-model="tutorial.title" class="input"></el-input>
            </el-form-item>
            <el-form-item label="教程内容">
                <el-input v-model="tutorial.content" class="input"></el-input>
            </el-form-item>
            <el-form-item label="教程关键字">
                <el-input v-model="tutorial.keyWord" class="input"></el-input>
            </el-form-item>
            <el-form-item label="教程级别">
                <el-input v-model="tutorial.level" class="input" type="number"></el-input>
            </el-form-item>
            <el-form-item label="是否显示更多">
                <el-select v-model="tutorial.isShowMore" placeholder="请选择是否显示更多">
                    <el-option
                            v-for="item in showMore"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="上传图片或者gif教程">
                <el-upload
                        class="upload-demo"
                        action="none"
                        :file-list="tutorial.fileList"
                        :on-exceed="imageCountOut"
                        :on-remove="handleRemove"
                        :on-change="fileChange"
                        :auto-upload="false"
                        :limit="1">
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">只能上传一个文件，且不超过50MB</div>
                </el-upload>
            </el-form-item>
            <el-form-item label="上传视频教程">
                <el-upload
                        class="upload-demo"
                        action="none"
                        :file-list="tutorial.videoFile"
                        :on-exceed="imageCountOut"
                        :on-remove="handleVideoFileRemove"
                        :on-change="videoFileChange"
                        :auto-upload="false"
                        :limit="1">
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">只能上传一个文件，且不超过50MB</div>
                </el-upload>
            </el-form-item>
            <el-form-item class="btn">
                <el-button type="primary" @click="save">保存</el-button>
                <el-button @click="cancel">取消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "TutorialEdit",
        props: ['tutorial', "isUpdate"],
        data: function () {
            return {
                showLoading: false,
                showMore: [
                    {
                        label: "不显示更多",
                        value: 0
                    },
                    {
                        label: "显示更多",
                        value: 1
                    },
                ],
            }
        },
        methods: {
            imageCountOut: function () {
                this.showErrorMessage("只能选择一个文件")
            },
            handleRemove: function (file, fileList) {
                this.tutorial.file = null
                if (this.isUpdate) {
                    this.tutorial.isDelImage = 1;//设置删除之前的图片
                }
            },
            fileChange: function (file, fileList) {
                this.tutorial.file = file.raw
            },
            handleVideoFileRemove: function (file, fileList) {
                this.tutorial.videoFile = null
                if (this.isUpdate) {
                    this.tutorial.isDelVideo = 1;//设置删除之前的视频
                }
            },
            videoFileChange: function (file, videoFileList) {
                this.tutorial.videoFile = file.raw
            },
            save: function () {
                if (!this.isUpdate) {
                    this.addTutorial()
                } else {
                    this.updateTutorial()
                }
            },
            cancel: function () {
                this.$emit('saveStatus', false)
            },
            addTutorial: function () {
                if (this.tutorial.title === undefined || this.tutorial.title === "") {
                    this.showErrorMessage("请输入教程标题")
                    return
                }
                if (this.tutorial.content === undefined || this.tutorial.content === "") {
                    this.showErrorMessage("请输入教程内容")
                    return
                }
                if (this.tutorial.keyWord === undefined || this.tutorial.keyWord === "") {
                    this.showErrorMessage("请输入教程关键字")
                    return
                }
                if (this.tutorial.level === undefined) {
                    this.showErrorMessage("请输入教程级别")
                    return
                }
                if (this.tutorial.isShowMore === undefined) {
                    this.showErrorMessage("请选择是否显示更多")
                    return
                }

                const that = this;
                const params = new FormData()

                params.append("title", this.tutorial.title)
                params.append("content", this.tutorial.content)
                params.append("keyWord", this.tutorial.keyWord)
                params.append("level", this.tutorial.level)
                params.append("isShowMore", this.tutorial.isShowMore)

                if (this.tutorial.file !== undefined && this.tutorial.file != null) {
                    params.append("file", this.tutorial.file)
                }
                if (this.tutorial.videoFile !== undefined && this.tutorial.videoFile != null) {
                    params.append("videoFile", this.tutorial.videoFile)
                }
                this.showLoading = true;
                this.$request.post("/tutorial/addTutorial", params)
                    .then(function (data) {
                        that.showLoading = false
                        if (data.status === 200) {
                            that.$confirm("添加成功", "提示", {
                                confirmButtonText: '确定',
                                showClose: false,
                                showCancelButton: false,
                                center: true,
                                type: 'success'
                            }).then(function () {
                                that.$emit('saveStatus', true)
                            })
                        } else {
                            that.showErrorMessage(data.data)
                        }
                    }).catch(function (err) {
                    that.showLoading = false
                    that.showErrorMessage("网络错误")
                })
            },
            updateTutorial: function () {
                const that = this;
                const params = new FormData()
                params.append("id", this.tutorial.id)
                if (this.tutorial.title !== undefined && this.tutorial.title !== "") {
                    params.append("title", this.tutorial.title)
                }
                if (this.tutorial.content !== undefined && this.tutorial.content !== "") {
                    params.append("content", this.tutorial.content)
                }
                if (this.tutorial.keyWord !== undefined && this.tutorial.keyWord !== "") {
                    params.append("keyWord", this.tutorial.keyWord)
                }
                if (this.tutorial.level !== undefined) {
                    params.append("level", this.tutorial.level)
                }
                if (this.tutorial.isShowMore !== undefined) {
                    params.append("isShowMore", this.tutorial.isShowMore)
                }
                if (this.tutorial.file !== undefined && this.tutorial.file != null) {
                    params.append("file", this.tutorial.file)
                }
                if (this.tutorial.videoFile !== undefined && this.tutorial.videoFile != null) {
                    params.append("videoFile", this.tutorial.videoFile)
                }
                if (this.tutorial.isDelImage !== undefined && this.tutorial.isDelImage == 1) {
                    params.append("isDelImage", 1)
                }
                if (this.tutorial.isDelVideo !== undefined && this.tutorial.isDelVideo == 1) {
                    params.append("isDelVideo", 1)
                }
                this.showLoading = true;
                this.$request.post("/tutorial/updateTutorial", params)
                    .then(function (data) {
                        that.showLoading = false
                        if (data.status === 200) {
                            that.$confirm("修改成功", "提示", {
                                confirmButtonText: '确定',
                                showClose: false,
                                showCancelButton: false,
                                center: true,
                                type: 'success'
                            }).then(function () {
                                that.$emit('saveStatus', true)
                            })
                        } else {
                            that.showErrorMessage(data.data)
                        }
                    }).catch(function (err) {
                    that.showLoading = false
                    that.showErrorMessage("网络错误")
                })
            },
            /**
             * 显示错误信息弹框
             * @param msg 错误信息弹框
             */
            showErrorMessage: function (msg) {
                this.$confirm(msg, "提示", {
                    confirmButtonText: '确定',
                    showClose: false,
                    showCancelButton: false,
                    center: true,
                    type: 'error'
                })
            },
        }
    }
</script>

<style scoped>
    .imageBox {
        width: 100%;
        min-height: 300px;
    }

    .btn /deep/ .el-form-item__content {
        margin-left: 0 !important;
        text-align: center;
    }

    .input {
        width: 300px;
    }
</style>