<template>
    <div class="font_box" v-loading.fullscreen.lock="showLoading">
        <div class="box_operation">
            <!--            操作列表-->
            <div class="input_box">
                <el-input placeholder="请输入字体名称" v-model="searchName" class="input-with-select"></el-input>
            </div>
            <div class="input_box">
                <el-select v-model="selectSupport" placeholder="请选择">
                    <el-option
                            v-for="item in selectSupportEs"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="input_box">
                <el-select v-model="selectVip" placeholder="请选择">
                    <el-option
                            v-for="item in selectVips"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div class="btn_box">
                <el-button type="warning"  @click="search">搜索</el-button>
            </div>
            <div class="btn_box">
                <el-button type="primary"  @click="addFont">添加</el-button>
            </div>
            <div class="btn_box">
                <el-button type="success"  @click="reset">重置</el-button>
            </div>
            <div class="btn_box">
                <el-button type="danger"  @click="getFontList">刷新</el-button>
            </div>
        </div>
        <div class="data_box">
            <el-table
                    class ="table"
                    @sort-change="sortChange"
                    border
                    :data="fonts"
                    style="width: 100%">
                <el-table-column
                        align="center"
                        prop="id"
                        label="id"
                        width="80">
                </el-table-column>
              <el-table-column
                  align="center"
                  label="字体预览"
                  width="140">
                <template slot-scope="scope">
                   <img :src="scope.row.previewImageUrl"style="width: 100px;"/>
                </template>
              </el-table-column>
                <el-table-column
                        align="center"
                        prop="name"
                        label="字体名称"
                        width="200">
                </el-table-column>
                <el-table-column
                        align="center"
                        label="大小(kb)"
                        width="100">
                    <template slot-scope="scope">
                        <p>{{(scope.row.size/1024).toFixed(2)}}</p>
                    </template>
                </el-table-column>
<!--                <el-table-column-->
<!--                        align="center"-->
<!--                        label="是否支持中文"-->
<!--                        width="180">-->
<!--                    <template slot-scope="scope">-->
<!--                        <p>{{(scope.row.supportEs == 1?"是":"否")}}</p>-->
<!--                    </template>-->
<!--                </el-table-column>-->
                <el-table-column
                        align="center"
                        label="是否是VIP"
                        width="180">
                    <template slot-scope="scope">
                        <p>{{(scope.row.isVip == 1?"是":"否")}}</p>
                    </template>
                </el-table-column>
                <el-table-column
                        align="center"
                        sortable="custom"
                        prop="updateTime"
                        label="更新时间"
                        width="180">
                </el-table-column>
                <el-table-column
                        min-width="240"
                        align="center"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="success" size="medium" icon="el-icon-download" @click="downFont(scope.row)"></el-button>
                        <el-button type="primary" size="medium" icon="el-icon-edit" @click="updateFont(scope.row)"></el-button>
                        <el-button type="danger" size="medium" icon="el-icon-delete" @click="delFont(scope.row.id)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page">
                <el-pagination
                        background
                        :page-size="5"
                        :current-page="pageNo"
                        layout="prev, pager, next"
                        :page-count="pageSum"
                        @current-change="changePage">
                </el-pagination>
            </div>
        </div>
        <el-dialog :title="dialogTitle"
                   @close="closeDialog"
                   top="5vh"
                   :visible.sync="showDialog"
                   :close-on-click-modal="false"
                   width="800px"
                   :destroy-on-close="true"
                   :fullscreen="false">
            <FontEdit v-on:saveStatus="saveStatus"  :font="selectFont"  :is-update="isUpdate"/>
        </el-dialog>
    </div>
</template>

<script>
    import FontEdit from "./FontEdit";
    export default {
        name: "FontResource",
        components:{
            FontEdit
        },
        data:function () {
            return{
                dialogTitle:"",
                showLoading:false,
                fonts:[],
                pageNo:1,
                pageCount:20,
                pageSum:1,
                searchName:"",
                selectSupport:-1,
                selectSupportEs:[
                    {
                    label:"全部",
                    value:-1
                },{
                    label:"不支持英文",
                    value:0
                },{
                    label:"支持英文",
                    value:1
                }
                ],
                selectVip:-1,
                selectVips:[
                    {
                        label:"全部",
                        value:-1
                    },
                    {
                        label:"不是VIP",
                        value:0
                    },
                    {
                        label:"VIP可用",
                        value:1
                    },
                ],
                orderType:2,//排序规则
                showDialog:false,//弹框是否显示
                isUpdate:false,
                selectFont:{}
            }
        },
        methods:{
            /**
             * 获取字体列表
             */
            getFontList:function () {
                this.showLoading = true;
                const that = this;
                const params = new URLSearchParams()
                params.append("isPage",1)
                params.append("pageNo",this.pageNo)
                params.append("pageCount",this.pageCount)
                params.append("fontName",this.searchName)
                params.append("orderType", this.orderType)
                if (this.selectSupport !== -1){
                    params.append("supportEs",this.selectSupport)
                }
                if (this.selectVip !== -1){
                    params.append("isVip",this.selectVip)
                }
                this.$request.post("/font/getFontList",params)
                    .then(function (data) {
                        if (data.status === 200){
                            that.fonts = data.data.records
                            that.pageSum = data.data.pages
                            that.showLoading = false
                        }else {
                            that.showMsg(data.data)
                        }
                    }).catch(function (err) {
                        that.showLoading = false
                        that.showMsg("网络错误")
                })
            },
            /**
             * 显示错误消息
             * @param title
             */
            showMsg:function (title) {
                this.$confirm(title, "提示", {
                    confirmButtonText: '确定',
                    showClose:false,
                    showCancelButton:false,
                    center:true,
                    type: 'error'
                })
            },
            /**
             * 页面改变触发
             * @param pageNo 改变的页码
             */
            changePage:function (pageNo) {
                this.pageNo = pageNo;
                this.getFontList()
            },
            search:function(){
               this.pageNo = 1;
               this.getFontList()
            },
            addFont:function () {
                this.dialogTitle = "添加字体文件"
                this.isUpdate = false
                this.showDialog = true
            },
            /**
             * 重置
             */
            reset:function () {
                this.selectSupport = -1;
                this.pageNo = 1;
                this.selectVip = -1;
                this.searchName = ""
                this.getFontList()
            },
            /**
             * 弹框关闭
             */
            closeDialog:function () {
                this.selectFont = {}
            },
            saveStatus:function (flag) {
                if (flag){
                    this.selectSupport = -1;
                    this.pageNo = 1;
                    this.selectVip = -1;
                    this.searchName = "";
                    this.orderType = 2;
                    this.getFontList()
                }
                this.showDialog = false
            },
            /**
             * 排序规则改变
             */
            sortChange:function({ column, prop, order }){
                switch (order) {
                    case "ascending":
                        //升序
                        this.orderType = 1;
                        break;
                    case "descending":
                        //升序
                        this.orderType = 2;
                        break;
                    case null:
                        //升序
                        this.orderType = 0;
                        break;
                }
                this.pageNo = 1;//重置页码
                this.getFontList()
            },
            updateFont:function (font) {
                font.fileList = [{name:font.name,url:font.url}]
                this.dialogTitle = "修改字体"
                this.isUpdate = true
                this.showDialog = true
                this.selectFont = font
            },
            delFont:function (id) {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
                  cancelButtonClass:"messagebox-off-button-color",
                  confirmButtonClass:"messagebox-ok-button-color"
                }).then(() => {
                    this.del(id)
                }).catch(()=>{});
            },
            del:function (id) {
                this.showLoading = true;
                const that = this;
                const params = new URLSearchParams()
                params.append("id",id)
                this.$request.post("/font/delFont",params)
                    .then(function (data) {
                        that.showLoading = false;
                        if (data.status === 200){
                            that.$confirm("删除成功", "提示", {
                                confirmButtonText: '确定',
                                showClose:false,
                                showCancelButton:false,
                                center:true,
                                type: 'success'
                            }).then(function () {
                                that.getFontList()
                            }).catch(function () {

                            })
                        }else {
                            that.showMsg(data.data)
                        }
                    }).catch(function (err) {
                      that.showLoading = false;
                      that.showMsg("网络错误")
                })
            },
            downFont:function (font) {
                window.location.href=font.url
            }
        },
        mounted() {
            this.$bus.$emit("setMenuActiveIndex","1-2-1");
            this.getFontList()
        }
    }
</script>

<style scoped>
    .font_box{
        width: 100%;
        min-height: 100%;
        background: white;
        border-radius: 5px;
        padding: 10px 20px;
    }
    .box_operation{
        width: 100%;
        padding: 20px 0;
    }
    .input_box{
        display: inline-block;
        width: 200px;
        margin: 0 10px;
        margin-top: 10px;
    }
    .btn_box{
        margin-top: 10px;
        height: 40px;
        margin-left: 20px;
        vertical-align: top;
        display: inline-block;
    }
    .input_box_select{
        width: 150px;
    }
    .data_box{
        width: 100%;
    }
    .preview_image{
        width: 80px;
        height: 80px;
    }
    .data_box /deep/ .el-table .warning-row {
        background: oldlace;
    }

    .data_box /deep/ .el-table .success-row {
        background: #f0f9eb;
    }
    .page{
        width: 100%;
        margin: 20px 0;
        text-align: center;
    }
    .table{
      /*position: fixed;*/
      height: 100%;
    }
</style>
