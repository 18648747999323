<template>
  <!--    图片添加修改-->
  <div class="imageBox" v-loading="showLoading">
    <el-form ref="form" :model="matchRules" label-width="120px">
      <el-form-item label="关键字" >
        <el-input v-model="matchRules.searchName" class="input"></el-input>
      </el-form-item>
      <el-form-item label="匹配规则" >
        <el-input v-model="matchRules.matchName" class="input" type="textarea" :rows="6"></el-input>
      </el-form-item>
      <el-form-item class="btn">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "SearchMatchRulesEdit",
  props: ['matchRules',"isUpdate","showStatus"],
  data:function (){
    return{
      showLoading:false,
      matchRule:this.matchRules
    }
  },
  methods:{
    save:function (){
       if (this.isUpdate){
         this.updateMatchRules()
       }else {
         this.addMatchRules()
       }
    },
    cancel:function (){
      this.$emit('saveStatus', false)
    },
    /**
     * 添加一个数据
     */
    addMatchRules:function (){
      if (this.matchRules.searchName === undefined || this.matchRules.searchName === ""){
        this.showError("请输入关键字")
        return;
      }
      if (this.matchRules.matchName === undefined || this.matchRules.matchName === ""){
        this.showError("请输入匹配规则")
        return;
      }
      this.showLoading = true;
      const that = this;
      const params = new URLSearchParams()
      params.append("searchName",this.matchRules.searchName);
      params.append("matchName",this.matchRules.matchName);
      //此处应该调用后台接口
      this.$request.post("/matchName/addMatch", params)
          .then(function (data) {
            that.showLoading = false;
            if (data.status === 200) {
              that.$confirm("添加成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.$emit('saveStatus', true)
              })
            } else {
              that.showError(data.data)
            }
          }).catch(function (err) {
        that.showLoading = false;
        that.showError("网络错误")
      })
    },
    /**
     * 修改
     */
    updateMatchRules:function (){
      if (this.matchRules.searchName === undefined || this.matchRules.searchName === ""){
        this.showError("请输入关键字")
        return;
      }
      if (this.matchRules.matchName === undefined || this.matchRules.matchName === ""){
        this.showError("请输入匹配规则")
        return;
      }
      this.showLoading = true;
      const that = this;
      const params = new URLSearchParams()
      params.append("id",this.matchRules.id);
      params.append("searchName",this.matchRules.searchName);
      params.append("matchName",this.matchRules.matchName);
      //此处应该调用后台接口
      this.$request.post("/matchName/updateMatch", params)
          .then(function (data) {
            that.showLoading = false;
            if (data.status === 200) {
              that.$confirm("修改成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.$emit('saveStatus', true)
              })
            } else {
              that.showError(data.data)
            }
          }).catch(function (err) {
        that.showLoading = false;
        that.showError("网络错误")
      })
    },
    /**
     * 显示错误信息弹框
     * @param msg 错误消息
     */
    showError: function (msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
  }
}
</script>

<style scoped>
  .imageBox {
    width: 100%;
    min-height: 300px;
  }
  .btn /deep/ .el-form-item__content {
    margin-left: 0 !important;
    text-align: center;
  }
  .input{
    width: 300px;
  }
</style>