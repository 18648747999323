export default {
    namespaced:"userOptions",
    actions:{
       saveUserInfo(context,value){
           context.commit("SAVE_USER_INFO",value)
       }
    },
    mutations:{
        SAVE_USER_INFO(state,value){
            state.userInfo = value
            sessionStorage.setItem(process.env.VUE_APP_USER_KEY,JSON.stringify(value));
        }
    },
    state:{
        userInfo:JSON.parse(sessionStorage.getItem(process.env.VUE_APP_USER_KEY))
    },
}