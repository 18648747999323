<template>
  <div class="image_box" v-loading.fullscreen.lock="showLoading">
    <div class="box_operation">
      <div class="input_box">
        <el-select v-model="imageType" placeholder="请选择" @change="reset">
          <el-option
              v-for="item in typeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>

      <div class="input_box">
        <el-cascader
            @change="selectChange"
            v-model="categoryId"
            :options="categoryList"
            :props="{expandTrigger: 'hover', checkStrictly: true,value:'id',label:'categoryName',children:'childList'}"
            clearable>
        </el-cascader>

        <!--        <el-select v-model="categoryId" placeholder="分类" clearable :style="{width: '100%'}" size="big">-->
        <!--          <el-option v-for="(item, index) in categoryList" :key="index" :label="item.categoryName"-->
        <!--                     :value="item.id" :disabled="item.disabled" >-->
        <!--          </el-option>-->
        <!--        </el-select>-->
      </div>

      <!--            操作列表-->
      <div class="input_box">
        <el-select v-model="pageCount" placeholder="请选择" @change="reset">
          <el-option
              v-for="item in pageNumList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>

      <!--            操作列表-->
      <div class="input_box">
        <el-input placeholder="请输入图片名称" v-model="searchName" class="input-with-select"></el-input>
      </div>
      <div class="input_box">
        <el-date-picker
            v-model="startTime"
            type="date"
            placeholder="开始日期">
        </el-date-picker>
      </div>
      <div class="input_box">
        <el-date-picker
            v-model="endTime"
            type="date"
            placeholder="结束日期">
        </el-date-picker>
      </div>
    </div>

    <!--    <el-dialog :title="dialogTitle"-->
    <!--               @close="closeDialog"-->
    <!--               top="5vh"-->
    <!--               :visible.sync="showDialogtoo"-->
    <!--               :close-on-click-modal="false"-->
    <!--               width="1200px"-->
    <!--               @opened="dialogOpen"-->
    <!--               :destroy-on-close="true"-->
    <!--               :fullscreen="false">-->
    <!--      <ImageBox @postFrom="postFrom"/>-->
    <!--    </el-dialog>-->
    <div class="box_operation">
      <!--      <div class="btn_box">-->
      <!--        <el-button  type="primary" @click="open">点击查询</el-button>-->
      <!--      </div>-->
      <div class="btn_box">
        <el-button type="warning" @click="search">搜索</el-button>
      </div>
      <div class="btn_box">
        <el-button type="primary" @click="addImage">添加</el-button>
      </div>
      <div class="btn_box">
        <el-button type="success" @click="reset">重置</el-button>
      </div>
      <div class="btn_box">
        <el-button type="danger" @click="getImages">刷新</el-button>
      </div>
      <div class="btn_box">
        <el-button type="info" @click="delImages">移动到回收站{{ delImageIds.length }}</el-button>
      </div>
    </div>
    <div class="data_box">
      <el-table
          ref="multipleTable"
          class="table"
          :data="images"
          style="width: 100%"
          @selection-change="selectAllChange"
          @row-click="toggleSelection"
          :row-class-name="tableRowClassName"
          :default-sort="{ prop: 'time', order: 'descending' }"
          @sort-change="sortChange">
        <el-table-column
            type="selection"
            width="55"
        >
        </el-table-column>
        <el-table-column
            align="center"
            prop="id"
            label="id"
            width="60">
        </el-table-column>
        <el-table-column
            align="center"
            label="预览"
            width="180">
          <template slot-scope="scope">
            <a :href="scope.row.url" target="_blank">
              <img :src="scope.row.url" class="preview_image">
            </a>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="130"
            label="分类">
          <template slot-scope="scope">
            <p>{{
                scope.row.firstCategory && scope.row.secondCategory && scope.row.thirdCategory &&
                scope.row.firstCategory.categoryName + "-" + scope.row.secondCategory.categoryName +
                "-" + scope.row.thirdCategory.categoryName
              }}</p>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="图片标签"
            width="300">
          <template slot-scope="scope">
            <el-input
                v-model="scope.row.tag"
                placeholder="图片标签"
                @keyup.enter.native="updateImageTag(scope.row)"
            ></el-input>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            sortable="custom"
            prop="heat"
            label="热度"
            width="80">
        </el-table-column>
        <el-table-column
            align="center"
            sortable="custom"
            prop="hotness"
            label="新热度"
            width="80">
        </el-table-column>
        <el-table-column
            align="center"
            sortable="custom"
            prop="recentHotness"
            label="最近7天热度"
            width="140">
        </el-table-column>
        <el-table-column
            align="center"
            prop="time"
            sortable="custom"
            width="180"
            label="上传时间">
        </el-table-column>
        <el-table-column
            align="center"
            prop="updateTime"
            sortable="custom"
            width="180"
            label="更新时间">
        </el-table-column>
        <el-table-column
            align="center"
            label="大小(kb)"
            width="80">
          <template slot-scope="scope">
            <p>{{ (scope.row.size / 1024).toFixed(2) }}</p>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="hasTemplateData"
            sortable="custom"
            label="是否有操作数据"
            width="180">
          <template slot-scope="scope">
            <p>{{ scope.row.hasTemplateData > 1 ? "有(自动模版)" : scope.row.hasTemplateData === 1 ? "有" : "否" }}</p>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="是否加锁"
            width="80">
          <template slot-scope="scope">
            <el-tag
                effect="plain">{{ scope.row.isLock === 13 ? "未审核" : scope.row.isLock === 1 ? "是" : "否" }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
            fixed="right"
            width="250"
            align="center"
            label="操作">
          <template slot-scope="scope">
<!--            <el-button type="success" size="medium" icon="el-icon-view" @click="showImageDetail(scope.row)"></el-button>-->
            <el-button type="primary" size="medium" icon="el-icon-edit" @click="updateImage(scope.row)"></el-button>
            <el-button type="danger" size="medium" icon="el-icon-delete" @click="delImage(scope.row.id)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
            background
            :page-size="5"
            :current-page="pageNo"
            layout="prev, pager, next"
            :page-count="pageSum"
            @current-change="changePage">
        </el-pagination>
      </div>
    </div>

    <el-dialog :title="dialogTitle"
               @close="closeDialog"
               top="5vh"
               :visible.sync="showDialog"
               :close-on-click-modal="false"
               width="1200px"
               @opened="dialogOpen"
               :destroy-on-close="true"
               :fullscreen="false">
      <!--普通类图片编辑和上传-->
      <ImageEdit v-on:saveStatus="saveStatus" :images="selectImages"
                 :categoryList="categoryList" :is-update="isUpdate" :loadData="loadDialogData"
                 v-show="imageType===''&&!showImageDetailDialog"/>

      <!--搜索、模板类图片编辑和上传-->
      <SearchImageUpload v-on:saveStatus="saveStatus" :images="selectImages"
                         :categoryList="categoryList" :is-update="isUpdate" :loadData="loadDialogData"
                         :imageType="imageType"
                         v-show="imageType!==''&&!showImageDetailDialog"/>

      <!--图片详情预览-->
      <ImageDetail v-on:saveStatus="saveStatus" :images="selectImages"
                   :categoryList="categoryList" :is-update="isUpdate" :loadData="loadDialogData"
                   v-show="showImageDetailDialog"/>
    </el-dialog>
  </div>
</template>

<script>
import ImageEdit from "./ImageEdit";
import ImageBox from "@/components/resources/ImageBox";
import SearchImageUpload from "@/components/resources/SearchImageUpload.vue";
import ImageDetail from "@/components/resources/ImageDetail.vue";

export default {
  name: "ImageManage",
  components: {
    SearchImageUpload,
    ImageEdit,
    ImageDetail,
    ImageBox
  },
  data: function () {
    return {
      dialogTitle: "",//弹框标题
      isUpdate: false,//是否时修改图片
      selectImages: {},//当前是修改图片信息时可用
      requestNum: 0,//当前请求数量
      showLoading: false,//是否显示刷新界面
      showDialog: false,//是否显示弹框
      showDialogtoo: false,
      hasLoadImageBox: false,//是否开始加载数据
      images: [],//图片列表
      categoryList: [],//图片分类列表
      categoryId: null,
      sortStatus: {}, // 用于跟踪每列的排序状态
      hotOrderType: 0,//热度排序规则 0:不排序 1:升序 2:降序
      timeOrderType: 2,//上传时间排序规则 0:不排序 1:升序 2:降序
      updateTimeOrderType: 0,//更新时间排序规则 0:不排序 1:升序 2:降序
      hasTemplateDataOrderType: 0,//是否有操作数据排序 0:不排序 1:升序 2:降序
      hotnessOrder: 0,//新热度排序规则 0 不排序，1新热度升序，2新热度倒序，3最近7天热度升序，4最近7天热度倒序
      searchName: "",//图片名称搜索关键字
      pageNo: 1,//当前页面
      pageSum: 1,//总页码
      pageCount: 100,//每页显示数据量
      imageType: "",//图片类别，有普通、搜索、自动模板类三种
      showImageDetailDialog: false,
      typeList: [{
        id: 0,
        label: '普通',
        value: "",
      }, {
        id: 1,
        label: '搜索类',
        value: "search"
      }, {
        id: 2,
        label: '自动模板使用',
        value: "tpUse"
      }],
      pageNumList: [{
        id: 0,
        label: '每页20条',
        value: 20,
      }, {
        id: 1,
        label: '每页50条',
        value: 50
      }, {
        id: 2,
        label: '每页100条',
        value: 100
      }, {
        id: 3,
        label: '每页200条',
        value: 200
      }],
      startTime: '',
      endTime: "",
      delImageIds: [],
      loadDialogData: false,
      selectcategory: []
    }
  },
  methods: {
    /**
     * 获取当前图片列表
     * */
    getImages: function () {
      this.showLoading = true;
      this.requestNum++;
      const that = this;
      const params = new URLSearchParams()
      params.append("userId", "baozouptu")

      params.append("isPage", 1)
      params.append("pageNo", this.pageNo)
      params.append("pageCount", this.pageCount)
      params.append("isShowCategory", "1")
      if (this.categoryId == null) {
        params.append("categoryId", 0)
      } else {
        params.append("categoryId", this.categoryId)
      }

      //图片种类
      params.append("category", this.imageType)

      params.append("heatOrder", this.hotOrderType)
      params.append("timeOrder", this.timeOrderType)
      params.append("updateTimeOrder", this.updateTimeOrderType)
      params.append("hasTemplateDataOrder", this.hasTemplateDataOrderType)
      params.append("hotnessOrder", this.hotnessOrder)
      params.append("searchName", this.searchName)
      if (this.startTime !== undefined && this.startTime !== "") {
        params.append("startTime", this.startTime.getFullYear() + "-" + (this.startTime.getMonth() + 1) + "-" + this.startTime.getDate())
      }
      if (this.endTime !== undefined && this.endTime !== "") {
        params.append("endTime", this.endTime.getFullYear() + "-" + (this.endTime.getMonth() + 1) + "-" + this.endTime.getDate())
      }

      console.log("params = " + params)

      this.$request.post("/images/getImageList", params)
          .then(function (data) {
            that.images = data.data.records
            that.pageSum = data.data.pages
            that.requestNum--;
            that.hideLoading()
          }).catch(function (err) {
        that.requestNum--;
        that.hideLoading()
        that.$confirm("网络错误", "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },
    /**
     * 调接判断隐藏加载界面
     * */
    hideLoading: function () {
      if (this.requestNum <= 0) {
        this.showLoading = false
      }
    },
    /**
     * 分行设置类名
     * */
    tableRowClassName: function ({row, rowIndex}) {
      // if (rowIndex % 2 === 0) {
      //     return 'warning-row';
      // } else if (rowIndex % 2 !== 0) {
      //     return 'success-row';
      // }
      return '';
    },
    /**
     * 排序规则改变
     */
    sortChange: function ({column, prop, order}) {
      console.log(prop + " - " + order + " - " + this.sortStatus[prop])
      if (!this.sortStatus[prop]) {
        // 如果是第一次点击，设置为降序
        this.sortStatus[prop] = 'descending';
        column.order = 'descending';
      } else if (this.sortStatus[prop] === 'descending') {
        // 否则，根据当前排序状态更新
        this.sortStatus[prop] = 'ascending';
        column.order = 'ascending';
      } else {
        // 否则，根据当前排序状态更新
        this.sortStatus[prop] = 'descending';
        column.order = 'descending';
      }

      // 更新其他列的排序状态
      for (const key in this.sortStatus) {
        if (key !== prop) {
          this.sortStatus[key] = null;
        }
      }

      this.sortData(prop, this.sortStatus[prop]);
    },
    sortData(prop, order) {
      console.log("sortData排序状态：" + prop + " - " + order)
      //初始化排序
      this.hotOrderType = 0;
      this.timeOrderType = 0;
      this.updateTimeOrderType = 0;
      this.hasTemplateDataOrderType = 0;
      this.hotnessOrder = 0;
      switch (prop) {
        case "heat":
          this.hotOrderType = order === "ascending" ? 1 : order === "descending" ? 2 : 0;
          break
        case "time":
          this.timeOrderType = order === "ascending" ? 1 : order === "descending" ? 2 : 0;
          break
        case "updateTime":
          this.updateTimeOrderType = order === "ascending" ? 1 : order === "descending" ? 2 : 0;
          break
        case "hasTemplateData":
          this.hasTemplateDataOrderType = order === "ascending" ? 1 : order === "descending" ? 2 : 0;
          break
        case "hotness":
          this.hotnessOrder = order === "ascending" ? 1 : order === "descending" ? 2 : 0;
          break
        case "recentHotness":
          this.hotnessOrder = order === "ascending" ? 3 : order === "descending" ? 4 : 0;
          break
      }
      this.pageNo = 1;//重置页码
      this.getImages()
    },
    /**
     * 重置界面，情况所有搜索数据
     */
    reset: function () {
      // this.orderType = 0;
      this.searchName = ""
      this.pageNo = 1;
      this.categoryId = 0;
      this.getImages()
    },
    /**
     * 搜索功能
     * 清空当前分页情况
     */
    search: function () {
      this.getImages()
    },
    /**
     * 页码改变时
     * @param pageNo 改变的页码
     */
    changePage: function (pageNo) {
      this.pageNo = pageNo;
      this.getImages();
    },
    /**
     * 获取分类列表
     */
    getCategoryList: function () {
      this.requestNum++;
      this.showLoading = true;
      const that = this;
      const params = new URLSearchParams()
      params.append("isPage", 0)
      params.append("level", 1)
      this.$request.post("/imageCategory/getListByPage", params)
          .then(function (data) {
            // that.getCategory("",data.data)
            that.categoryList = data.data
            console.log(that.categoryList)
            that.requestNum--;
            that.hideLoading()
          }).catch(function (err) {
        that.requestNum--;
        that.hideLoading()
        that.$confirm("网络错误", "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },

    /**
     * 选项选择时
     * @param data
     */
    selectChange: function (data) {
      if (data.length > 0) {
        this.categoryId = data[data.length - 1]
      } else {
        this.categoryId = 0;
      }
    },

    /**
     * 添加一张图片
     */
    addImage: function () {
      this.dialogTitle = "添加图片信息"
      this.isUpdate = false
      this.showDialog = true
    },
    /**
     * 更新图片标签
     * */
    updateImageTag: function (selectImage) {
      console.log("updateImageTag :" + JSON.stringify(selectImage))
      this.showLoading = true;
      const that = this;
      const params = new FormData()
      params.append("id", selectImage.id)
      params.append("file", selectImage.file)
      params.append("tag", selectImage.tag)
      params.append("isLock", selectImage.isLock)
      if (selectImage.heat === undefined || selectImage.heat === "") {
        this.showErrorMessage("热度不能为空")
        return
      }
      params.append("hot", selectImage.heat)
      params.append("categoryId", selectImage.categoryId)
      params.append("materialImage", selectImage.materialFile)
      if (selectImage.templateType !== undefined && selectImage.templateType !== "") {
        params.append("templateType", selectImage.templateType)
      }
      this.$request.post("/images/updateImage", params)
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$message({
                message: '修改成功',
                type: 'success'
              });
            } else {
              that.showErrorMessage(data.message)
            }
          }).catch(function (err) {
        that.showLoading = false
        that.$message.error('修改失败');
      })
    },
    /**
     * 查看图片详情
     * */
    showImageDetail: function (selectImages) {
      this.dialogTitle = "查看图片详情"
      this.isUpdate = true
      this.showDialog = true
      this.showImageDetailDialog = true

      selectImages.categoryIds = [selectImages.firstCategory.id, selectImages.secondCategory.id,
        selectImages.thirdCategory.id]

      selectImages.fileList = [{name: selectImages.tag, url: selectImages.url}]
      if (selectImages.materialImage !== null) {
        selectImages.materialList = [{name: selectImages.tag + "预览", url: selectImages.materialImage}]
      } else {
        selectImages.materialList = []
      }

      this.selectImages = selectImages
    },
    /**
     * 更新图片信息
     * */
    updateImage: function (selectImages) {
      this.dialogTitle = "修改图片信息"
      this.isUpdate = true
      this.showDialog = true

      selectImages.categoryIds = [selectImages.firstCategory.id, selectImages.secondCategory.id,
        selectImages.thirdCategory.id]

      selectImages.fileList = [{name: selectImages.tag, url: selectImages.url}]
      if (selectImages.materialImage !== null) {
        selectImages.materialList = [{name: selectImages.tag + "预览", url: selectImages.materialImage}]
      } else {
        selectImages.materialList = []
      }

      this.selectImages = selectImages
    },
    /**
     * 图片保存状态
     */
    saveStatus: function (flag) {
      if (flag) {
        this.getImages()
      }
      this.showDialog = false
    },

    /**
     * 弹框关闭时
     */

    closeDialog: function () {
      this.selectImages = {}
      this.loadDialogData = false;
    },
    /**
     * 删除某个图片
     * @param id 图片id
     */
    delImage: function (id) {
      this.$confirm('是否将图片移动到回收站?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        cancelButtonClass: "messagebox-off-button-color",
        confirmButtonClass: "messagebox-ok-button-color"
      }).then(() => {
        this.del(id)
      }).catch(() => {
      });
    },
    /**
     * 单行点击，选中或者不选中
     */
    toggleSelection(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    /**
     * 多选回调
     * @param data 选中的对象数据
     */
    selectAllChange: function (data) {
      //清空数组
      this.delImageIds = new Array();
      //遍历选中的重新赋值
      data.forEach((item) => {
        this.delImageIds.push(item.id)
      })
    },
    /**
     * 发出删除一个图片请求
     * @param id
     */
    del: function (id) {
      this.requestNum++;
      this.showLoading = true;
      const that = this;
      const params = {
        "id": id,
        "state": 1 //1代表删除
      }
      this.$request.post("/images/updateState", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      })
          .then(function (data) {
            that.requestNum--;
            that.hideLoading()
            if (data.status === 200) {
              that.$confirm("删除成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.getImages()
              })
            } else {
              that.showError(data.message)
            }
          }).catch(function (err) {
        that.requestNum--;
        that.hideLoading()
        that.showError("网络错误")
      })
    },
    /**
     * 删除多个图片
     */
    delImages: function () {
      this.$confirm('此操作将选中的文件批量移动到回收站, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        cancelButtonClass: "messagebox-off-button-color",
        confirmButtonClass: "messagebox-ok-button-color"
      }).then(() => {
        this.delAll()
      }).catch(() => {
      });
    },
    /**
     * 删除选中的数据
     */
    delAll: function () {
      if (this.delImageIds === undefined || this.delImageIds.length === 0) {
        return
      }
      this.showLoading = true;
      const that = this;
      const params = {
        "ids": this.delImageIds,
        "state": 1 //1代表删除
      }
      this.$request.post("/images/updateState", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      }).then(function (data) {
        that.showLoading = false;
        if (data.status === 200) {
          that.$confirm("批量删除成功", "提示", {
            confirmButtonText: '确定',
            showClose: false,
            showCancelButton: false,
            center: true,
            type: 'success'
          }).then(function () {
            that.getImages()
          })
        } else {
          that.showError(data.data)
        }
      }).catch(function (err) {
        that.showLoading = false;
        that.hideLoading()
        that.showError("网络错误")
      })
    },
    showError: function (msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
    dialogOpen: function () {
      this.loadDialogData = true;
    },
    open: function () {
      this.showDialogtoo = true
    },
    postFrom: function (data) {
      console.log(data)
    },
    getCategory: function (name, categorys) {
      categorys.forEach(item => {
        let category = {}
        category.id = item.id
        category.categoryName = name + item.categoryName
        this.categoryList.push(category)
        if (item.childList !== null && item.childList !== undefined && item.childList.length > 0) {
          this.getCategory(category.categoryName + "/", item.childList)
        }
      })
    },
    // selectcategoryId:function (categoryList){
    //
    //   for (let i =0 ; i<categoryList.length;i++){
    //     if(categoryList[i].childList ===null)
    //       this.selectcategory.add(this.categoryList[i].id())
    //     return this.categoryList[i].id();
    //     return;
    //   }
    //   return
    // }
  },
  mounted: function () {
    this.$bus.$emit("setMenuActiveIndex", "1-1-1");
    this.getImages()

    this.getCategoryList()
    console.log(this.getCategoryList.categoryId)
  },

}
</script>

<style scoped>
.image_box {
  width: 100%;
  min-height: 100%;
  background: white;
  border-radius: 5px;
  padding: 10px 20px;
}

.box_operation {
  width: 100%;
  padding: 10px 0;
}

.input_box {
  display: inline-block;
  width: 200px;
  margin: 0 10px;
  margin-top: 10px;
}

.input_box /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 90%;
  margin: 0 auto;
}

.btn_box {
  height: 40px;
  margin-top: 10px;
  margin: 10px;
  vertical-align: top;
  display: inline-block;
}

.input_box_select {
  width: 150px;
}

.data_box {
  width: 100%;

}

.preview_image {
  width: 80px;
  height: 80px;
}

.data_box /deep/ .el-table .warning-row {
  background: oldlace;
}

.data_box /deep/ .el-table .success-row {
  background: #f0f9eb;
}

.page {
  width: 100%;
  margin: 20px 0;
  text-align: center;
}

.table {
  /*position: fixed;*/
  height: 100%;
}

</style>
