<template>
    <!--    图片添加修改-->
    <div class="imageBox" v-loading="showLoading">
        <el-form ref="form" :model="model" label-width="120px">
            <el-form-item label="模型名" >
                <el-input v-model="model.name" class="input"></el-input>
            </el-form-item>
            <el-form-item label="模型类型" >
                <el-input v-model="model.type" class="input"></el-input>
            </el-form-item>
            <el-form-item label="模型简介" >
                <el-input v-model="model.describe" class="input"></el-input>
            </el-form-item>
            <el-form-item label="上传文件">
                <el-upload
                        class="upload-demo"
                        action="none"
                        :file-list="model.fileList"
                        :on-remove="handleRemove"
                        :on-change="fileChange"
                        :auto-upload="false">
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">上传文件不能超过50MB</div>
                </el-upload>
            </el-form-item>
            <el-form-item class="btn">
                <el-button type="primary" @click="save">保存</el-button>
                <el-button @click="cancel">取消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "ModelEdit",
        props: ['model',"isUpdate"],
        data:function () {
            return{
                showLoading:false,
            }
        },
        methods:{
            /**
             * 显示错误信息弹框
             * @param msg 错误信息弹框
             */
            showErrorMessage: function (msg) {
                this.$confirm(msg, "提示", {
                    confirmButtonText: '确定',
                    showClose: false,
                    showCancelButton: false,
                    center: true,
                    type: 'error'
                })
            },
            /**
             * 图片移除回调
             * @param file
             * @param fileList
             */
            handleRemove: function (file, fileList) {
                const that = this;
                if (this.model.files === undefined){
                    this.model.files = []
                }
                if (that.isUpdate){
                    that.delFile(file.url)
                }
                this.model.files =  this.model.files.filter(function(item,index,arr){
                    return (file.name !== item.name);
                })
            },
            /**
             * 图片选择回调
             * @param file
             * @param fileList
             */
            fileChange: function (file, fileList) {
                if (this.model.files === undefined){
                    this.model.files = []
                }
                this.model.files.push(file.raw)
            },
            cancel: function () {
                this.$emit('saveStatus', false)
            },
            /**
             * 保存上传
             * */
            save() {
                if (!this.isUpdate){
                    this.addModel()
                }else {
                    this.updateModel()
                }
            },
            addModel:function () {
               if (this.model.name === undefined || this.model.name === ""){
                   this.showErrorMessage("请输入模型名称")
                   return
               }
                if (this.model.files === undefined || this.model.files.length <= 0){
                    this.showErrorMessage("请选择至少一个模型文件")
                    return
                }
                this.showLoading = true;
                const that = this;
                const params = new FormData()
                for(let i = 0; i < this.model.files.length; i++){
                    params.append("files", this.model.files[i])
                }
                params.append("name", this.model.name)
                if (this.model.type !== undefined && this.model.type !== ""){
                    params.append("type", this.model.type)
                }
                if (this.model.describe !== undefined && this.model.describe !== ""){
                    params.append("describe", this.model.describe)
                }
                this.$request.post("/model/addModelFile", params)
                    .then(function (data) {
                        that.showLoading = false
                        if (data.status === 200) {
                            that.$confirm("添加成功", "提示", {
                                confirmButtonText: '确定',
                                showClose: false,
                                showCancelButton: false,
                                center: true,
                                type: 'success'
                            }).then(function () {
                                that.$emit('saveStatus', true)
                            })
                        } else {
                            that.showErrorMessage(data.data)
                        }
                    }).catch(function (err) {
                        that.showLoading = false
                        that.showErrorMessage("网络错误")
                })
            },
            updateModel:function () {
                const that = this;
                const params = new FormData()
                params.append("id", this.model.id)
                if (this.model.name !== undefined && this.model.name !== ""){
                    params.append("name", this.model.name)
                }
                if (this.model.files !== undefined){
                    for(let i = 0; i < this.model.files.length; i++){
                        params.append("files", this.model.files[i])
                    }
                }
                if (this.model.type !== undefined && this.model.type !== ""){
                    params.append("type", this.model.type)
                }
                if (this.model.describe !== undefined && this.model.describe !== ""){
                    params.append("describe", this.model.describe)
                }
                if (this.model.delUrl !== undefined && this.model.delUrl.length > 0){
                    for(let i = 0; i < this.model.delUrl.length; i++){
                        params.append("delUrls", this.model.delUrl[i])
                    }
                }
                this.showLoading = true;
                this.$request.post("/model/updateModelFile", params)
                    .then(function (data) {
                        that.showLoading = false
                        if (data.status === 200) {
                            that.$confirm("修改成功", "提示", {
                                confirmButtonText: '确定',
                                showClose: false,
                                showCancelButton: false,
                                center: true,
                                type: 'success'
                            }).then(function () {
                                that.$emit('saveStatus', true)
                            })
                        } else {
                            that.showErrorMessage(data.data)
                        }
                    }).catch(function (err) {
                    that.showLoading = false
                    that.showErrorMessage("网络错误")
                })
            },
            delFile:function (url) {
                if (this.model.delUrl === undefined){
                    this.model.delUrl = []
                }
                let list = this.model.urlList;
                for(let i = 0; i < list.length; i++){
                   if (list[i] === url){
                       this.model.delUrl.push(url);
                   }
                }
            }
        }
    }
</script>

<style scoped>
    .imageBox {
        width: 100%;
        min-height: 300px;
    }
    .btn /deep/ .el-form-item__content {
        margin-left: 0 !important;
        text-align: center;
    }
    .input{
        width: 300px;
    }
</style>