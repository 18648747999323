<template>
  <div class="font_box" v-loading.fullscreen.lock="showLoading">
    <div class="box_operation">
      <div class="input_box">
        <el-input placeholder="请输入语音名称" v-model="searchName" class="input-with-select"></el-input>
      </div>

      <div class="input_box">
        <el-select v-model="pageCount" placeholder="请选择" @change="reset">
          <el-option
              v-for="item in pageNumList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <!--操作列表-->
      <div class="btn_box">
        <el-button type="primary" @click="search">搜索</el-button>
      </div>
      <div class="btn_box">
        <el-button type="primary" @click="addFont">添加</el-button>
      </div>
      <div class="btn_box">
        <el-button type="danger" @click="getAudioPackList">刷新</el-button>
      </div>
    </div>
    <div class="data_box">
      <el-table
          class="table"
          @sort-change="sortChange"
          border
          :data="audioPackList"
          style="width: 100%">
        <el-table-column
            align="center"
            prop="id"
            label="id"
            width="80">
        </el-table-column>
        <el-table-column
            align="center"
            label="语音"
            width="320">
          <template slot-scope="scope">
            <audio ref="audio" controls="" :src="scope.row.url"/>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            prop="name"
            label="语音名称"
            width="200">
        </el-table-column>
        <el-table-column
            align="center"
            prop="tag"
            label="标签"
            width="100">
        </el-table-column>
        <el-table-column
            align="center"
            sortable="custom"
            prop="heat"
            label="热度"
            width="80">
        </el-table-column>
        <el-table-column
            align="center"
            label="大小(kb)"
            width="100">
          <template slot-scope="scope">
            <p>{{ (scope.row.size / 1024).toFixed(2) }}</p>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            sortable="custom"
            prop="createTime"
            label="创建时间"
            width="180">
        </el-table-column>
        <el-table-column
            min-width="240"
            align="center"
            label="操作">
          <template slot-scope="scope">
            <el-button type="success" size="medium" icon="el-icon-download" @click="downFont(scope.row)"></el-button>
            <el-button type="primary" size="medium" icon="el-icon-edit" @click="updateFont(scope.row)"></el-button>
            <el-button type="danger" size="medium" icon="el-icon-delete" @click="delFont(scope.row)"></el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
            background
            :page-size="5"
            :current-page="pageNo"
            layout="prev, pager, next"
            :page-count="pageSum"
            @current-change="changePage">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="dialogTitle"
               @close="closeDialog"
               top="5vh"
               :visible.sync="showDialog"
               :close-on-click-modal="false"
               width="800px"
               :destroy-on-close="true"
               :fullscreen="false">
      <AudioPackEdit v-on:saveStatus="saveStatus" :audioPack="selectAudioPack" :is-update="isUpdate"/>
    </el-dialog>
  </div>
</template>

<script>
import AudioPackEdit from "./AudioPackEdit";

export default {
  name: "audioPackManager",
  components: {
    AudioPackEdit
  },
  data: function () {
    return {
      dialogTitle: "",
      showLoading: false,
      audioPackList: [],
      pageNo: 1,
      pageSum: 1,
      pageCount: 100,//每页显示数据量
      pageNumList: [{
        id: 0,
        label: '每页20条',
        value: 20,
      }, {
        id: 1,
        label: '每页50条',
        value: 50
      }, {
        id: 2,
        label: '每页100条',
        value: 100
      }, {
        id: 3,
        label: '每页200条',
        value: 200
      }],
      searchName: "",
      orderType: 0,//排序规则
      showDialog: false,//弹框是否显示
      isUpdate: false,
      selectAudioPack: {}
    }
  },
  methods: {
    /**
     * 获取字体列表
     */
    getAudioPackList: function () {
      this.showLoading = true;
      const that = this;
      const params = {
        "isPage": 1,
        "pageNo": this.pageNo,
        "pageCount": this.pageCount,
        "orderType": this.orderType,
        "name": this.searchName,
      }
      this.$request.post("/audio/getAudioPackList", params)
          .then(function (data) {
            if (data.status === 200) {
              that.audioPackList = data.data.records
              that.pageSum = data.data.pages
              that.showLoading = false
            } else {
              that.showMsg(data.data)
            }
          }).catch(function (err) {
        that.showLoading = false
        that.showMsg("网络错误," + err)
      })
    },
    /**
     * 显示错误消息
     * @param title
     */
    showMsg: function (title) {
      this.$confirm(title, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
    /**
     * 页面改变触发
     * @param pageNo 改变的页码
     */
    changePage: function (pageNo) {
      this.pageNo = pageNo;
      this.getAudioPackList()
    },
    search: function () {
      this.pageNo = 1;
      this.getAudioPackList()
    },
    addFont: function () {
      this.dialogTitle = "添加语音文件"
      this.isUpdate = false
      this.showDialog = true
    },
    /**
     * 弹框关闭
     */
    closeDialog: function () {
      this.selectAudioPack = {}
    },
    saveStatus: function (flag) {
      if (flag) {
        this.selectSupport = -1;
        this.pageNo = 1;
        this.selectVip = -1;
        this.searchName = "";
        this.orderType = 0;
        this.getAudioPackList()
      }
      this.showDialog = false
    },
    /**
     * 排序规则改变
     */
    sortChange: function ({column, prop, order}) {
      console.log(column + "- " + prop + " " + order)
      switch (order) {
        case "ascending"://升序
          if (prop === 'heat') {
            this.orderType = 3;
          } else if (prop === 'createTime') {
            this.orderType = 1;
          }
          break;
        case "descending":
        case null://降序
          if (prop === 'heat') {
            this.orderType = 2;
          } else if (prop === 'createTime') {
            this.orderType = 0;
          }
          break;
      }
      this.pageNo = 1;//重置页码
      this.getAudioPackList()
    },
    updateFont: function (audioPack) {
      audioPack.fileList = [{name: audioPack.name, url: audioPack.url}]
      this.dialogTitle = "修改语音包"
      this.isUpdate = true
      this.showDialog = true
      this.selectAudioPack = audioPack
    },
    delFont: function (audioPack) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        cancelButtonClass: "messagebox-off-button-color",
        confirmButtonClass: "messagebox-ok-button-color"
      }).then(() => {
        this.del(audioPack)
      }).catch(() => {
      });
    },
    del: function (audioPack) {
      this.showLoading = true;
      const that = this;
      const params = {
        "id": audioPack.id,
        "originUrl": audioPack.url
      }
      this.$request.post("/audio/deleteAudioPack", params)
          .then(function (data) {
            that.showLoading = false;
            if (data.status === 200) {
              that.$confirm("删除成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.getAudioPackList()
              }).catch(function () {

              })
            } else {
              that.showMsg(data.data)
            }
          }).catch(function (err) {
        that.showLoading = false;
        that.showMsg("网络错误")
      })
    },
    downFont: function (font) {
      window.location.href = font.url
    }
  },
  mounted() {
    this.$bus.$emit("setMenuActiveIndex", "1-2-1");
    this.getAudioPackList()
  }
}
</script>

<style scoped>
.font_box {
  width: 100%;
  min-height: 100%;
  background: white;
  border-radius: 5px;
  padding: 10px 20px;
}

.box_operation {
  width: 100%;
  padding: 20px 0;
}

.input_box {
  display: inline-block;
  width: 200px;
  margin: 0 10px;
  margin-top: 10px;
}

.btn_box {
  margin-top: 10px;
  height: 40px;
  margin-left: 20px;
  vertical-align: top;
  display: inline-block;
}

.input_box_select {
  width: 150px;
}

.data_box {
  width: 100%;
}

.preview_image {
  width: 80px;
  height: 80px;
}

.data_box /deep/ .el-table .warning-row {
  background: oldlace;
}

.data_box /deep/ .el-table .success-row {
  background: #f0f9eb;
}

.page {
  width: 100%;
  margin: 20px 0;
  text-align: center;
}

.table {
  /*position: fixed;*/
  height: 100%;
}
</style>
