<template>
  <div class="user_share_box" v-loading.fullscreen.lock="showLoading">
    <div class="data_box">
      <div class="box_operation">
        <!--            操作列表-->
        <div class="input_box">
          <el-select v-model="searchStatus" placeholder="请选择" @change="search">
            <el-option
                v-for="item in searchStatusOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="input_box">
          <el-date-picker
              v-model="startTime"
              type="date"
              placeholder="开始日期">
          </el-date-picker>
        </div>
        <div class="input_box">
          <el-date-picker
              v-model="endTime"
              type="date"
              placeholder="结束日期">
          </el-date-picker>
        </div>
      </div>
      <div class="box_operation">
        <div class="btn_box">
          <el-button type="warning" @click="search">搜索</el-button>
        </div>
        <div class="btn_box">
          <el-button type="danger" @click="getExceptionList">刷新</el-button>
        </div>
        <div class="btn_box">
          <el-button type="info" @click="delIds">删除{{ delDataIds.length }}</el-button>
        </div>
      </div>
      <div class="data_box">
        <el-table
            class="table"
            :data="exceptionInfoList"
            @selection-change="selectAllChange"
            style="width: 100%">
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column
              align="center"
              prop="id"
              label="id"
              width="60">
          </el-table-column>
          <el-table-column
              align="center"
              label="异常名"
              prop="name"
              width="200">
          </el-table-column>
          <el-table-column
              align="center"
              prop="time"
              sortable="custom"
              width="180"
              label="产生时间">
          </el-table-column>
          <el-table-column
              align="center"
              prop="count"
              sortable="custom"
              width="80"
              label="次数">
          </el-table-column>
          <el-table-column
              align="center"
              prop="api"
              width="180"
              label="接口地址">
          </el-table-column>
          <el-table-column
              align="center"
              prop="prams"
              width="180"
              label="请求参数">
          </el-table-column>
          <el-table-column
              align="center"
              prop="prams"
              width="180"
              label="状态">
            <template slot-scope="scope">
              <el-select v-model="scope.row.status" placeholder="请选择" @change="changeStatus(scope.row)">
              <el-option
                  v-for="item in statusOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
              fixed="right"
              width="250"
              align="center"
              label="操作">
            <template slot-scope="scope">
              <el-button type="primary" size="medium" icon="el-icon-view" @click="show(scope.row)"></el-button>
              <el-button type="danger" size="medium" icon="el-icon-delete" @click="delData(scope.row.id)"></el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
              background
              :page-size="5"
              :current-page="pageNo"
              layout="prev, pager, next"
              :page-count="pageSum"
              @current-change="changePage">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog title="异常详情"
               top="5vh"
               :visible.sync="showDialog"
               :close-on-click-modal="false"
               width="1200px"
               :destroy-on-close="true"
               :fullscreen="false">
      <ExceptionInfoDetail :exception="selectException"/>
    </el-dialog>
  </div>
</template>

<script>
import ExceptionInfoDetail from "./ExceptionInfoDetail";
export default {
  name: "ExceptionInfo",
  components:{
    ExceptionInfoDetail
  },
  data:function (){
    return{
      showDialog:false,//是否显示弹框
      showLoading:false,
      exceptionInfoList:[],
      pageNo:1,
      pageCount:20,
      pageSum:1,
      searchKey:"",
      startTime:"",
      endTime:"",
      delDataIds:[],
      searchStatus:0,
      searchStatusOptions:[
        {
          value: 0,
          label: "全部"
        },
          {
            value: 1,
                label: "未解决"
          },
          {
            value: 2,
                label: "已解决"
          },
          {
            value: 3,
                label: "忽略"
          }
      ],
      statusOptions: [
          {
        value: 1,
        label: "未解决"
      },
        {
          value: 2,
          label: "已解决"
        },
        {
          value: 3,
          label: "忽略"
        },
      ],
      selectException:{}
    }
  },
  methods:{
    /**
     * 输入用户分享列表
     */
    getExceptionList:function (){
      this.showLoading = true;
      const that = this;
      const params = new URLSearchParams()
      params.append("isPage", 1)
      params.append("pageNo", this.pageNo)
      params.append("pageCount", this.pageCount)
      if (this.startTime !== undefined && this.startTime !== "") {
        params.append("startTime", this.startTime.getFullYear() + "-" + (this.startTime.getMonth() + 1) + "-" + this.startTime.getDate())
      }
      if (this.endTime !== undefined && this.endTime !== "") {
        params.append("endTime", this.endTime.getFullYear() + "-" + (this.endTime.getMonth() + 1) + "-" + this.endTime.getDate())
      }
      params.append("status", this.searchStatus)
      this.$request.post("/server/exception/getExceptionInfo", params)
          .then(function (data) {
            that.showLoading = false
            that.pageSum = data.data.pages
            that.exceptionInfoList = data.data.records
          }).catch(function (err) {
            that.showErrorMessage("网络错误")
            that.showLoading = false
       })
      },
     showErrorMessage:function (msg){
        this.$confirm(msg, "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      },
    /**
     * 搜索列表
     */
    search:function (){
       this.pageNo = 1
       this.getExceptionList()
    },
    /**
     * 删除数据
     * @param id 数据id
     */
    delData:function (id){
      this.$confirm('此操作将永久删除选中的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        cancelButtonClass: "messagebox-off-button-color",
        confirmButtonClass: "messagebox-ok-button-color"
      }).then(() => {
        this.del(id)
      }).catch(() => {
      });
    },
    del:function (id){
      this.showLoading = true;
      const that = this;
      const params = new URLSearchParams()
      params.append("ids", id)
      //此处应该调用后台接口
      this.$request.post("/server/exception/delExceptionInfo", params)
          .then(function (data) {
            that.showLoading = false;
            if (data.status === 200) {
              that.$confirm("删除成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.getExceptionList()
              })
            } else {
              that.showError(data.data)
            }
          }).catch(function (err) {
        that.showLoading = false;
        that.showError("网络错误")
      })
    },
    /**
     * 删除全选的数据列表
     */
      delIds:function (){
        this.$confirm('此操作将永久删除选中的数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          cancelButtonClass: "messagebox-off-button-color",
          confirmButtonClass: "messagebox-ok-button-color"
        }).then(() => {
          this.delAll()
        }).catch(() => {
        });
        },
    /**
     * 删除多选数据列表
     */
    delAll:function (){
      if (this.delDataIds === undefined || this.delDataIds.length === 0) {
        return
      }
      this.showLoading = true;
      const that = this;
      const params = new URLSearchParams()
      this.delDataIds.forEach((item) => {
        params.append("ids", item)
      })
      //此处应该调用后台接口
      this.$request.post("/server/exception/delExceptionInfo", params)
          .then(function (data) {
            that.showLoading = false;
            if (data.status === 200) {
              that.$confirm("删除成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.getExceptionList()
              })
            } else {
              that.showError(data.data)
            }
          }).catch(function (err) {
            that.showLoading = false;
            that.showError("网络错误")
      })
    },
    /**
     * 多选回调
     */
    selectAllChange:function (data){
      //清空数组
      this.delDataIds = new Array();
      //遍历选中的重新赋值
      data.forEach((item) => {
        this.delDataIds.push(item.id)
      })
    },
    /**
     * 页码改变时
     * @param pageNo 改变的页码
     */
    changePage: function (pageNo) {
      this.pageNo = pageNo;
      this.getExceptionList();
    },
    /**
     * 修改异常状态
     * @param id
     * @param status
     */
    changeStatus:function (exception){
      this.showLoading = true;
      const that = this;
      const params = new URLSearchParams()
      params.append("id",exception.id);
      params.append("status",exception.status)
      //此处应该调用后台接口
      this.$request.post("/server/exception/updateExceptionInfoStatus", params)
          .then(function (data) {
            that.showLoading = false;
            if (data.status !== 200) {
              that.showError(data.data)
            }
          }).catch(function (err) {
          that.showLoading = false;
          that.showError("网络错误")
      })
    },
    /**
     * 显示弹框
     * @param exception 当前选择的异常
     */
    show:function (exception){
      this.selectException =exception
      this.showDialog = true
    }
  },
  mounted() {
    this.$bus.$emit("setMenuActiveIndex", "3-2-1");
    this.getExceptionList()
  }
}
</script>

<style scoped>
  .user_share_box{
    width: 100%;
    min-height: 100%;
    background: white;
    border-radius: 5px;
    padding: 10px 20px;
  }
  .box_operation {
    width: 100%;
    padding: 10px 0;
  }

  .input_box {
    display: inline-block;
    width: 200px;
    margin: 0 10px;
    margin-top: 10px;
  }

  .input_box /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 90%;
    margin: 0 auto;
  }

  .btn_box {
    height: 40px;
    margin-top: 10px;
    margin: 10px;
    vertical-align: top;
    display: inline-block;
  }

  .input_box_select {
    width: 150px;
  }

  .data_box {
    width: 100%;

  }

  .preview_image {
    width: 30px;
    height: 30px;
  }

  .data_box /deep/ .el-table .warning-row {
    background: oldlace;
  }

  .data_box /deep/ .el-table .success-row {
    background: #f0f9eb;
  }

  .page {
    width: 100%;
    margin: 20px 0;
    text-align: center;
  }

  .table {
    /*position: fixed;*/
    height: 100%;
  }
</style>