<template>
  <div data-uid="0"
       data-request-app="Web"
       data-request-hash="6cfb0f4aqxbss9"
       style="margin: 0;height: 100%;">
    <div id="main">
      <div id="header" style="padding: 20px 0px;" googl="true">
        <div class="main" style="width: 950px;">
          <a href="/">
            <span id="header-logo"
                  style="display: flex;justify-content: left;align-items: center;"
            >
              <img style="height: 48px;margin-right: 10px;"
                   src="../assets/icon.png"
                   alt=""
              />
              <span>暴走P图</span>
            </span>
          </a>
          <div>
            <a id="header-developer" class="share" @click="copy">
              <span style="font-size: 16px;">分享此应用</span>
            </a>
            <a class="header-developer" target="_blank" href="agreement/privacyPolicy.html">
              <span style="font-size: 16px;">隐私政策</span>
            </a>
          </div>
        </div>
      </div>
      <div id="content">
        <div id="first_screen">
          <div id="main-content" googl="true">
            <div class="text-group">
              <div class="weui-flex logo-box">
                <div class="weui-flex__item">
                  <img
                      class="logo"
                      src="../assets/icon.png"
                      alt="暴走P图"
                  />
                </div>
              </div>
              <div class="weui-flex">
                <div class="weui-flex__item">
                  <p class="title">暴走P图</p>
                  <p class="sub-title mobile-display">
                    让你一言不合就斗图的应用，赶快去应用商店搜索下载体验吧
                  </p>
                  <p class="sub-title pc-display">
                    让你一言不合就斗图的应用，赶快去应用商店搜索下载体验吧
                  </p>
                </div>
              </div>
            </div>
            <div class="weui-flex image-group">
              <div class="weui-flex__item large-image-box">
                <div class="swiper-container swiper-container-initialized swiper-container-horizontal"
                >
                  <div
                      class="swiper-wrapper"
                      style="transition-duration: 0ms; transform: translate3d(-2250px, 0px, 0px);"
                      id="swiper-wrapper-28813a616f10a43ca"
                      aria-live="on"
                  >
                    <div class="swiper-slide swiper-slide-prev" role="group">
                      <img
                          class="large-image"
                          src="../../public/statics/image_1.jpg"
                          alt=""
                      />
                    </div>
                    <div class="swiper-slide swiper-slide-active" role="group">
                      <img
                          class="large-image"
                          src="../../public/statics/image_2.jpg"
                          alt=""
                      />
                    </div>
                    <div class="swiper-slide swiper-slide-next" role="group">
                      <img
                          class="large-image"
                          src="../../public/statics/image_3.jpg"
                          alt=""
                      />
                    </div>
                    <div class="swiper-slide swiper-slide-next" role="group">
                      <img
                          class="large-image"
                          src="../../public/statics/image_4.jpg"
                          alt=""
                      />
                    </div>
                    <div class="swiper-slide swiper-slide-next" role="group">
                      <img
                          class="large-image"
                          src="../../public/statics/image_5.jpg"
                          alt=""
                      />
                    </div>
                  </div>
                  <!-- 如果需要分页器 -->
                  <div class="swiper-pagination swiper-pagination-bullets">
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet"></span>
                    <span class="swiper-pagination-bullet swiper-pagination-bullet-active"
                    ></span>
                  </div>
                  <span
                      class="swiper-notification"
                      aria-live="assertive"
                      aria-atomic="true"
                  >
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footer">
        ©2019-2024 重庆桂岑科技有限公司 All Rights Reserved.
        <div>
          <el-link style="color: black;margin-left: 2px;" href="https://beian.miit.gov.cn/#/Integrated/index"
                   target="_blank">
            渝ICP备2021008236号
          </el-link>
          <el-link style="color: black"
                   href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=50019002503760"
                   target="_blank">
            <img style="height: 14px;margin-right: 4px;margin-left: 8px;"
                 src="../assets/beian.png"
                 alt=""
            />
            渝公网安备 50019002503760号
          </el-link>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Swiper from 'swiper';
import 'swiper/css/swiper.min.css';

export default {
  name: "Index",
  components: {
    // eslint-disable-next-line vue/no-unused-components
    "remote-css": {
      render(createElement) {
        return createElement("link", {
          attrs: {rel: "stylesheet", href: this.href},
        });
      },
      props: {
        href: {type: String, required: true},
      },
    },
    // eslint-disable-next-line vue/no-unused-components
    "remote-js": {
      render(createElement) {
        return createElement("script", {
          attrs: {type: "text/javascript", src: this.src},
        });
      },
      props: {
        src: {type: String, required: true},
      }
    },
  },
  methods: {
    copy: function () {
      var clipboard = document.createElement("input")
      clipboard.value = "我正在使用暴走P图，感觉非常不错，快去应用商店搜索下载吧！"
      document.body.appendChild(clipboard)
      clipboard.select()
      document.execCommand("copy")
      weui.toast("链接已复制，快分享给小伙伴吧！", 3000);
      document.body.removeChild(clipboard)
    },
  },
  mounted() {
    new Swiper(".swiper-container", {
      direction: "horizontal", // 垂直切换选项
      loop: true, // 循环模式选项
      autoplay: {
        delay: 4000, //1秒切换一次
      },
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
      },
    });
  }
}


</script>

<style scoped>
@import "../../public/statics/index.css";

#footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  text-align: center; /* 可以根据需要调整对齐方式 */
  padding: 10px 0; /* 可以根据需要调整内边距 */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* 可选：添加阴影效果 */
  font-size: 14px; /* 统一字体大小 */
  color: black;
}
</style>
