<template>
  <div class="template_data_box" v-loading="showLoading">
    <el-form :inline="true" :model="templateData" class="demo-form-inline" label-width="100px">
      <div class="inputBox">
        <el-form-item label="标题">
          <el-input v-model="templateData.title" placeholder="标题"></el-input>
        </el-form-item>
        <el-form-item label="示例">
          <el-input v-model="templateData.tips" placeholder="示例"></el-input>
        </el-form-item>
        <div v-if="templateData.type === 2 || (!isUpdate && !isAddImageData)">
          <el-form-item label="起始X坐标">
            <el-input v-model="templateData.left" placeholder="起始X坐标" type="number"></el-input>
          </el-form-item>
          <el-form-item label="起始Y坐标">
            <el-input v-model="templateData.top" placeholder="起始Y坐标" type="number"></el-input>
          </el-form-item>
          <el-form-item label="旋转角度">
            <el-input v-model="templateData.rotateAngle" placeholder="旋转角度" type="number"></el-input>
          </el-form-item>
          <el-form-item label="文字颜色" style="width: 50%">
            <!--                    <el-input v-model="templateData.fontColor" placeholder="文字颜色"></el-input>-->
            <el-color-picker
                v-model="templateData.fontColor">
            </el-color-picker>
          </el-form-item>
          <el-form-item label="文字方向">
            <el-select v-model="templateData.fontDirection" placeholder="请选择" size="small">
              <el-option
                  v-for="item in textDirection"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
            <!--                    <el-input v-model="templateData.fontDirection" placeholder="文字方向"></el-input>-->
          </el-form-item>
          <el-form-item label="文字大小">
            <el-input v-model="templateData.fontSize" placeholder="文字大小" type="number"></el-input>
          </el-form-item>
          <el-form-item label="文字数量">
            <el-input v-model="templateData.fontSum" placeholder="文字数量" type="number"></el-input>
          </el-form-item>
          <el-form-item label="字体">
            <el-select v-model="templateData.fontId" placeholder="请选择" size="small">
              <el-option
                  v-for="item in fonts"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
              </el-option>
            </el-select>
            <a href="javascript:void(0)" @click="addFont" class="a_class">添加字体</a>
          </el-form-item>
        </div>

        <div v-else>
          <el-form-item label="左边距">
            <el-input v-model="templateData.left" placeholder="左边距" type="number"></el-input>
          </el-form-item>
          <el-form-item label="上边距">
            <el-input v-model="templateData.top" placeholder="上边距" type="number"></el-input>
          </el-form-item>
          <el-form-item label="宽度">
            <el-input v-model="templateData.width" placeholder="宽度" type="number"></el-input>
          </el-form-item>
          <el-form-item label="高度">
            <el-input v-model="templateData.height" placeholder="高度" type="number"></el-input>
          </el-form-item>
          <el-form-item label="旋转角度">
            <el-input v-model="templateData.rotateAngle" placeholder="旋转角度" type="number"></el-input>
          </el-form-item>
          <el-form-item label="中间操作">
            <el-select v-model="templateData.middleOperation" placeholder="请选择" size="small">
              <el-option
                  v-for="item in operations"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
        </div>

        <div v-if="type === 1">
          <el-form-item label="开始时间(毫秒)" label-width="20">
            <el-input v-model="templateData.startTime" placeholder="开始时间" type="number"></el-input>
          </el-form-item>
          <el-form-item label="持续时间(毫秒)">
            <el-input v-model="templateData.duration" placeholder="持续时间"></el-input>
          </el-form-item>
        </div>
      </div>

      <el-form-item class="template_data_box_btn">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
    <el-dialog title="添加字体"
               top="5vh"
               :visible.sync="showDialog"
               :close-on-click-modal="false"
               width="800px"
               append-to-body
               :destroy-on-close="true"
               :fullscreen="false">
      <FontEdit v-on:saveStatus="saveStatus" :is-update="false" :is-template-into="true" :font="font"/>
    </el-dialog>
  </div>
</template>

<script>
import FontEdit from "./FontEdit";

export default {
  name: "TemplateDataAdd",
  props: ['id', "isTitle", "isUpdate", "templateData", "isAddImageData", "isLoadData", "type"],
  components: {
    FontEdit
  },
  data: function () {
    return {
      showLoading: false,
      showDialog: false,
      fonts: [],
      font: {},
      textDirection: [
        {
          label: "左边",
          value: "left"
        },
        {
          label: "右边",
          value: "right"
        },
        {
          label: "中间",
          value: "center"
        }
      ],
      operations: [
        {
          label: "无",
          value: ""
        },
        {
          label: "换头",
          value: "head"
        },
        {
          label: "抠脸",
          value: "face"
        }
      ]
    }
  },
  watch: {
    isLoadData: function (flag) {
      if (flag) {
        if (this.templateData.type === 2 || (!this.isUpdate && !this.isAddImageData)) {
          this.getFonts()
        }
      }
    }
  },
  methods: {
    /**
     * 获取字体列表数据
     */
    getFonts: function () {
      let that = this;
      let param = new FormData();
      param.append("isPage", "0")
      // param.append("isShowTemplateFont","1")
      that.showLoading = true
      this.$request.post("/font/getFontList", param)
          .then(function (data) {
            that.showLoading = false
            that.fonts = data.data
          }).catch(function (err) {
        that.showLoading = false
        that.showMessage("网络错误")
      })
    },
    save: function () {
      if (this.isUpdate) {
        this.update()
      } else {
        this.add()
      }
    },
    add: function () {
      if (this.isAddImageData) {
        this.addImageData()
      } else {
        this.addTextData()
      }
    },
    addImageData: function () {
      if (this.templateData.title === "" || this.templateData.title === undefined) {
        this.showMessage("标题不能为空")
        return
      }
      if (this.templateData.tips === "" || this.templateData.tips === undefined) {
        this.showMessage("提示不能为空")
        return
      }
      if (this.templateData.tips === "" || this.templateData.tips === undefined) {
        this.showMessage("提示不能为空")
        return
      }
      if (this.templateData.tips === "" || this.templateData.tips === undefined) {
        this.showMessage("提示不能为空")
        return
      }
      if (this.templateData.left === "" || this.templateData.left === undefined) {
        this.showMessage("请输入左边距")
        return
      }
      if (this.templateData.top === "" || this.templateData.top === undefined) {
        this.showMessage("请输入上边距")
        return
      }
      if (this.templateData.width === "" || this.templateData.width === undefined) {
        this.showMessage("请输入图片宽度")
        return
      }
      if (this.templateData.height === "" || this.templateData.height === undefined) {
        this.showMessage("请输入图片高度")
        return
      }
      if (this.templateData.rotateAngle === "" || this.templateData.rotateAngle === undefined) {
        this.showMessage("请输入图片旋转角度")
        return
      }
      if (this.type === 1 && (this.templateData.startTime === "" || this.templateData.startTime === undefined || this.templateData.startTime < 0)) {
        this.showMessage("请输入正确的开始时间")
        return
      }
      if (this.type === 1 && (this.templateData.duration === "" || this.templateData.duration === undefined || this.templateData.duration < 0)) {
        this.showMessage("请输入正确的持续时间")
        return
      }
      this.showLoading = true
      const params = new URLSearchParams()
      const that = this;
      params.append("imageId", this.id)
      params.append("type", "1")
      params.append("left", this.templateData.left)
      params.append("top", this.templateData.top)
      params.append("width", this.templateData.width)
      params.append("height", this.templateData.height)
      params.append("title", this.templateData.title)
      params.append("tips", this.templateData.tips)
      params.append("rotateAngle", this.templateData.rotateAngle)
      if (this.templateData.middleOperation !== undefined) {
        params.append("middleOperation", this.templateData.middleOperation)
      }
      if (this.type === 1) {
        params.append("startTime", this.templateData.startTime)
        params.append("duration", this.templateData.duration)
      }
      this.$request.post("/autoTemplate/addAutoTemplate", params)
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("修改成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.$emit('saveStatus', true)
              })
            } else {
              that.showMessage(data.message)
            }
          }).catch(function (err) {
        that.showLoading = false
      })
    },
    addTextData: function () {
      if (this.templateData.title === "" || this.templateData.title === undefined) {
        this.showMessage("标题不能为空")
        return
      }
      if (this.templateData.tips === "" || this.templateData.tips === undefined) {
        this.showMessage("提示不能为空")
        return
      }
      if (this.templateData.left === "" || this.templateData.left === undefined) {
        this.showMessage("请输入起始X坐标")
        return
      }
      if (this.templateData.top === "" || this.templateData.top === undefined) {
        this.showMessage("请输入起始Y坐标")
        return
      }
      if (this.templateData.rotateAngle === "" || this.templateData.rotateAngle === undefined) {
        this.showMessage("请输入旋转角度")
        return
      }
      if (this.templateData.fontColor === "" || this.templateData.fontColor === undefined) {
        this.showMessage("请输入文字颜色")
        return
      }
      if (this.templateData.fontDirection === "" || this.templateData.fontDirection === undefined) {
        this.showMessage("请输入文字方向")
        return
      }
      if (this.templateData.fontSize === "" || this.templateData.fontSize === undefined) {
        this.showMessage("请输入文字大小")
        return
      }
      if (this.templateData.fontSum === "" || this.templateData.fontSum === undefined) {
        this.showMessage("请输入文字数量")
        return
      }
      if (this.templateData.fontId === "" || this.templateData.fontId === undefined) {
        this.showMessage("请选择文字字体")
        return
      }
      if (this.type === 1 && (this.templateData.startTime === "" || this.templateData.startTime === undefined || this.templateData.startTime < 0)) {
        this.showMessage("请输入正确的开始时间")
        return
      }
      if (this.type === 1 && (this.templateData.duration === "" || this.templateData.duration === undefined || this.templateData.duration < 0)) {
        this.showMessage("请输入正确的持续时间")
        return
      }
      this.showLoading = true
      const params = new URLSearchParams()
      const that = this;
      params.append("imageId", this.id)
      params.append("type", "2")
      params.append("left", this.templateData.left)
      params.append("top", this.templateData.top)
      params.append("title", this.templateData.title)
      params.append("tips", this.templateData.tips)
      params.append("rotateAngle", this.templateData.rotateAngle)
      params.append("fontColor", this.templateData.fontColor)
      params.append("fontDirection", this.templateData.fontDirection)
      params.append("fontSize", this.templateData.fontSize)
      params.append("fontSum", this.templateData.fontSum)
      params.append("fontId", this.templateData.fontId)
      if (this.type === 1) {
        params.append("startTime", this.templateData.startTime)
        params.append("duration", this.templateData.duration)
      }
      this.$request.post("/autoTemplate/addAutoTemplate", params)
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("添加成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.$emit('saveStatus', true)
              })
            } else {
              that.showMessage(data.message)
            }
          }).catch(function (err) {
        that.showLoading = false
        that.showMessage("网络错误")
      })
    },
    cancel: function () {
      this.$emit('saveStatus', false)
    },
    update: function () {
      if (this.templateData.type === 1) {
        this.updateImageData()
      } else {
        this.updateTextData()
      }
    },
    updateImageData: function () {
      if (this.templateData.title === "" || this.templateData.title === undefined) {
        this.showMessage("标题不能为空")
        return
      }
      if (this.templateData.tips === "" || this.templateData.tips === undefined) {
        this.showMessage("提示不能为空")
        return
      }
      if (this.templateData.left === "" || this.templateData.left === undefined) {
        this.showMessage("请输入左边距")
        return
      }
      if (this.templateData.top === "" || this.templateData.top === undefined) {
        this.showMessage("请输入上边距")
        return
      }
      if (this.templateData.width === "" || this.templateData.width === undefined) {
        this.showMessage("请输入图片宽度")
        return
      }
      if (this.templateData.height === "" || this.templateData.height === undefined) {
        this.showMessage("请输入图片高度")
        return
      }
      if (this.templateData.rotateAngle === "" || this.templateData.rotateAngle === undefined) {
        this.showMessage("请输入图片旋转角度")
        return
      }
      if (this.type === 1 && (this.templateData.startTime === "" || this.templateData.startTime === undefined || this.templateData.startTime < 0)) {
        this.showMessage("请输入正确的开始时间")
        return
      }
      if (this.type === 1 && (this.templateData.duration === "" || this.templateData.duration === undefined || this.templateData.duration < 0)) {
        this.showMessage("请输入正确的持续时间")
        return
      }
      this.showLoading = true
      const params = new URLSearchParams()
      const that = this;
      params.append("id", this.templateData.id)
      params.append("left", this.templateData.left)
      params.append("top", this.templateData.top)
      params.append("width", this.templateData.width)
      params.append("height", this.templateData.height)
      params.append("title", this.templateData.title)
      params.append("tips", this.templateData.tips)
      params.append("rotateAngle", this.templateData.rotateAngle)
      if (this.templateData.middleOperation !== undefined) {
        params.append("middleOperation", this.templateData.middleOperation)
      }
      if (this.type === 1) {
        params.append("startTime", this.templateData.startTime)
        params.append("duration", this.templateData.duration)
      }
      this.$request.post("/autoTemplate/updateAutoTemplate", params)
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("添加成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.$emit('saveStatus', true)
              })
            } else {
              that.showMessage(data.message)
            }
          }).catch(function (err) {
        that.showLoading = false
        that.showMessage("网络错误")
      })
    },
    updateTextData: function () {
      if (this.templateData.title === "" || this.templateData.title === undefined) {
        this.showMessage("标题不能为空")
        return
      }
      if (this.templateData.tips === "" || this.templateData.tips === undefined) {
        this.showMessage("提示不能为空")
        return
      }
      if (this.templateData.left === "" || this.templateData.left === undefined) {
        this.showMessage("请输入起始X坐标")
        return
      }
      if (this.templateData.top === "" || this.templateData.top === undefined) {
        this.showMessage("请输入起始Y坐标")
        return
      }
      if (this.templateData.rotateAngle === "" || this.templateData.rotateAngle === undefined) {
        this.showMessage("请输入旋转角度")
        return
      }
      if (this.templateData.fontColor === "" || this.templateData.fontColor === undefined) {
        this.showMessage("请输入文字颜色")
        return
      }
      if (this.templateData.fontDirection === "" || this.templateData.fontDirection === undefined) {
        this.showMessage("请输入文字方向")
        return
      }
      if (this.templateData.fontSize === "" || this.templateData.fontSize === undefined) {
        this.showMessage("请输入文字大小")
        return
      }
      if (this.templateData.fontSum === "" || this.templateData.fontSum === undefined) {
        this.showMessage("请输入文字数量")
        return
      }
      if (this.templateData.fontId === "" || this.templateData.fontId === undefined) {
        this.showMessage("请选择文字字体")
        return
      }
      if (this.type === 1 && (this.templateData.startTime === "" || this.templateData.startTime === undefined || this.templateData.startTime < 0)) {
        this.showMessage("请输入正确的开始时间")
        return
      }
      if (this.type === 1 && (this.templateData.duration === "" || this.templateData.duration === undefined || this.templateData.duration < 0)) {
        this.showMessage("请输入正确的持续时间")
        return
      }
      this.showLoading = true
      const params = new URLSearchParams()
      const that = this;
      params.append("id", this.templateData.id)
      params.append("left", this.templateData.left)
      params.append("top", this.templateData.top)
      params.append("title", this.templateData.title)
      params.append("tips", this.templateData.tips)
      params.append("rotateAngle", this.templateData.rotateAngle)
      params.append("fontColor", this.templateData.fontColor)
      params.append("fontDirection", this.templateData.fontDirection)
      params.append("fontSize", this.templateData.fontSize)
      params.append("fontSum", this.templateData.fontSum)
      params.append("fontId", this.templateData.fontId)
      if (this.type === 1) {
        params.append("startTime", this.templateData.startTime)
        params.append("duration", this.templateData.duration)
      }
      this.$request.post("/autoTemplate/updateAutoTemplate", params)
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("修改成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.$emit('saveStatus', true)
              })
            } else {
              that.showMessage(data.message)
            }
          }).catch(function (err) {
        that.showLoading = false
        that.showMessage("网络错误")
      })
    },
    showMessage: function (msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
    saveStatus: function (flag) {
      if (flag) {
        this.getFonts()
      }
      this.showDialog = false
    },
    addFont: function () {
      this.showDialog = true
    }

  },


  mounted() {
    if (this.templateData.tips === undefined) {
      this.$set(this.templateData, "tips", '')
      this.$set(this.templateData, "rotateAngle", 0)
      this.$set(this.templateData, "fontColor", '#000000')
      this.$set(this.templateData, "fontDirection", 'right')
      this.$set(this.templateData, "fontSum", '20')
      this.$set(this.templateData, "fontId", 7)
      this.$set(this.templateData, "middleOperation", '')
    }


  }
}
</script>

<style scoped>
.template_data_box {
  width: 100%;
  min-height: 300px;
}

.inputBox {
  max-height: 400px;
  overflow-y: scroll;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.inputBox::-webkit-scrollbar {
  display: none;
}

.template_data_box_btn {
  width: 100% !important;
  margin-left: 0 !important;
  text-align: center;
}

.input {
  width: 300px;
}

.operationBox {
  width: 100%;
}

.a_class {
  margin-left: 20px;
  font-size: 13px;
  color: #409EFF;
}
</style>
