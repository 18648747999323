import { render, staticRenderFns } from "./TemplateDataAdd.vue?vue&type=template&id=437bcfd8&scoped=true"
import script from "./TemplateDataAdd.vue?vue&type=script&lang=js"
export * from "./TemplateDataAdd.vue?vue&type=script&lang=js"
import style0 from "./TemplateDataAdd.vue?vue&type=style&index=0&id=437bcfd8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "437bcfd8",
  null
  
)

export default component.exports