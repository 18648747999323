<template>
  <!--    图片添加修改-->
  <div class="imageBox" v-loading="showLoading">
    <el-form ref="form" :model="messageInfo" label-width="120px">
      <el-form-item label="类型">
        <div class="input_box">
          <el-select v-model="messageInfo.type" placeholder="请选择">
            <el-option
                v-for="item in showTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </el-form-item>
      <el-form-item label="标题">
        <el-input v-model="messageInfo.title" class="input"></el-input>
      </el-form-item>
      <el-form-item label="描述" v-if="[0, 2].includes(messageInfo.type)">
        <el-input v-model="messageInfo.description" class="input"
        ></el-input>
      </el-form-item>
      <el-form-item label="到期时间">
        <el-date-picker
            v-model="selectedDate"
            type="date"
            placeholder="选择日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item label="跳转链接">
        <el-input v-model="messageInfo.jumpUrl" class="input"
        ></el-input>
      </el-form-item>
      <el-form-item label="上传图片"
                    v-if="[0, 1, 3].includes(messageInfo.type)">
        <el-upload
            class="upload-demo"
            action="none"
            :file-list="messageInfo.fileList"
            :on-exceed="imageCountOut"
            :on-remove="handleRemove"
            :on-change="fileChange"
            :auto-upload="false"
            :limit="1">
          <el-button size="small" type="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传一个文件，且不超过50MB</div>
        </el-upload>
      </el-form-item>
      <el-form-item class="btn">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "MessageEdit",
  props: ['messageInfo', "isUpdate"],
  data: function () {
    return {
      selectedDate: null,
      showLoading: false,
      showTypes: [{
        label: "图文消息",
        value: 0
      }, {
        label: "图片消息",
        value: 1
      }, {
        label: "文本消息",
        value: 2
      }, {
        label: "Banner",
        value: 3
      }
      ],
    }
  },
  mounted() {
    this.setDefaultExpiryDate();
  },
  methods: {
    setDefaultExpiryDate() {
      let currentDate = new Date();
      currentDate.setMonth(currentDate.getMonth() + 1); // 加一个月
      this.selectedDate = this.formatDate(currentDate);
    },
    formatDate(date) {
      let year = date.getFullYear();
      let month = ('0' + (date.getMonth() + 1)).slice(-2);
      let day = ('0' + date.getDate()).slice(-2);
      return `${year}-${month}-${day}`; // 返回 yyyy-MM-dd 格式的字符串
    },
    imageCountOut: function () {
      this.showErrorMessage("只能选择一个文件")
    },
    handleRemove: function (file, fileList) {
      this.messageInfo.file = null
      if (this.isUpdate) {
        this.messageInfo.isDelImage = 1;//设置删除之前的图片
      }
    },
    fileChange: function (file, fileList) {
      this.messageInfo.file = file.raw
    },
    save: function () {
      if (!this.isUpdate) {
        this.addMessage()
      } else {
        this.updateTutorial()
      }
    },
    cancel: function () {
      this.$emit('saveStatus', false)
    },
    addMessage: function () {
      if (this.messageInfo.title === undefined || this.messageInfo.title === "") {
        this.showErrorMessage("请输入标题")
        return
      }
      const that = this;
      const params = new FormData()
      params.append("title", this.messageInfo.title)

      if (this.messageInfo.description !== undefined) {
        params.append("description", this.messageInfo.description)
      }

      params.append("type", this.messageInfo.type)
      params.append("expireTime", this.selectedDate)
      params.append("jumpUrl", this.messageInfo.jumpUrl)

      if (this.messageInfo.file !== undefined && this.messageInfo.file != null) {
        params.append("file", this.messageInfo.file)
      }
      this.showLoading = true;
      this.$request.post("/messages/addMessage", params)
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("添加成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.$emit('saveStatus', true)
              })
            } else {
              that.showErrorMessage(data.data)
            }
          }).catch(function (err) {
        that.showLoading = false
        that.showErrorMessage("网络错误")
      })
    },
    updateTutorial: function () {
      const that = this;
      const params = new FormData()
      params.append("id", this.messageInfo.id)
      if (this.messageInfo.title !== undefined && this.messageInfo.title !== "") {
        params.append("title", this.messageInfo.title)
      }
      if (this.messageInfo.content !== undefined && this.messageInfo.content !== "") {
        params.append("content", this.messageInfo.content)
      }
      if (this.messageInfo.keyWord !== undefined && this.messageInfo.keyWord !== "") {
        params.append("keyWord", this.messageInfo.keyWord)
      }
      if (this.messageInfo.level !== undefined) {
        params.append("level", this.messageInfo.level)
      }
      if (this.messageInfo.isShowMore !== undefined) {
        params.append("isShowMore", this.messageInfo.isShowMore)
      }
      if (this.messageInfo.file !== undefined && this.messageInfo.file != null) {
        params.append("file", this.messageInfo.file)
      }
      if (this.messageInfo.videoFile !== undefined && this.messageInfo.videoFile != null) {
        params.append("videoFile", this.messageInfo.videoFile)
      }
      if (this.messageInfo.isDelImage !== undefined && this.messageInfo.isDelImage == 1) {
        params.append("isDelImage", 1)
      }
      if (this.messageInfo.isDelVideo !== undefined && this.messageInfo.isDelVideo == 1) {
        params.append("isDelVideo", 1)
      }
      this.showLoading = true;
      this.$request.post("/messages/update", params)
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("修改成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.$emit('saveStatus', true)
              })
            } else {
              that.showErrorMessage(data.data)
            }
          }).catch(function (err) {
        that.showLoading = false
        that.showErrorMessage("网络错误")
      })
    },

    /**
     * 显示错误信息弹框
     * @param msg 错误信息弹框
     */
    showErrorMessage: function (msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
  }
}
</script>

<style scoped>
.imageBox {
  width: 100%;
  min-height: 300px;
}

.btn /deep/ .el-form-item__content {
  margin-left: 0 !important;
  text-align: center;
}

.input {
  width: 300px;
}
</style>