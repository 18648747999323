<template>
    <div class="box">
       <div class="icon">
           <img src="../assets/icon.png" alt="图片">
       </div>
        <div class="title">
            <p>暴走P图后台管理</p>
        </div>
        <div class="user_info">
             <div class="option">
                 <p>{{userInfo.username}}</p>
             </div>
            <div class="option">
                <el-button type="danger" size="mini" @click="quit">退出</el-button>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions,mapState} from 'vuex'
    export default {
        name: "IndexTop",
        computed:{
            ...mapState("userOptions",["userInfo"])
        },
        methods:{
            ...mapActions("userOptions",["saveUserInfo"]),
            quit:function () {
                const params = new URLSearchParams()
                params.append('token', this.userInfo.token)
                this.$request.post("/admin/logout",params)

                this.saveUserInfo(null)
                this.$router.push({name:'login'})
            }
        }
    }
</script>
<style scoped>
    .box{
        width: 100%;
        height: 100%;
        padding: 10px 40px;
    }
     .icon{
         width: 50px;
         height: 50px;
         line-height: 35px;
         display: inline-block;
     }
    .icon img{
        width: 100%;
        height: 100%;
    }
    .title{
        margin-left: 20px;
        vertical-align: top;
        display: inline-block;
        font-size: 30px;
        height: 60px;
    }
    .user_info{
        height: 60px;
        float: right;
    }
    .option{
        display: inline-block;
        vertical-align: top;
        line-height: 60px;
        margin: 0 10px;
    }
    .option p{
        font-size: 14px;
    }
    .title p{
        line-height: 60px;
    }
</style>
