<template>
    <div class="imageBox">
        <el-form ref="form" :model="feedback" label-width="120px">
            <el-form-item label="用户标识" >
                <el-input v-model="feedback.userIdentify" class="input"></el-input>
            </el-form-item>
            <el-form-item label="用户联系方式" >
                <el-input v-model="feedback.contact" class="input"></el-input>
            </el-form-item>
            <el-form-item label="反馈时间" >
                <el-input v-model="feedback.time" class="input"></el-input>
            </el-form-item>
            <el-form-item label="反馈内容" >
                <el-input v-model="feedback.comment" class="input" :autosize="boxSize" type="textarea"></el-input>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "ShowFeedback",
        props: ['feedback'],
        data:function () {
            return{
                boxSize:{
                    minRows: 4
                }
            }
        }
    }
</script>

<style scoped>
    .imageBox {
        width: 100%;
        min-height: 300px;
    }
    .btn /deep/ .el-form-item__content {
        margin-left: 0 !important;
        text-align: center;
    }
    .input{
        width: 80%;
    }
</style>