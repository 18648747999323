<template>
  <div class="tutorial_box" v-loading.fullscreen.lock="showLoading">
    <div class="box_operation">
      <div class="input_box">
        <el-select v-model="appName" placeholder="请选择" @change="updateAppName">
          <el-option
              v-for="item in appList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <!--      <div class="input_box">-->
      <!--        <el-select v-model="appPlacement" placeholder="请选择" @change="getAdStatisticsList">-->
      <!--          <el-option-->
      <!--              v-for="item in adSlotTypeList"-->
      <!--              :key="item.value"-->
      <!--              :label="item.label"-->
      <!--              :value="item.value">-->
      <!--          </el-option>-->
      <!--        </el-select>-->
      <!--      </div>-->
      <div class="input_box">
        <el-date-picker
            v-model="selectDate"
            type="daterange"
            align="right"
            unlink-panels
            format="yyyy-MM-dd"
            value-format='yyyy-MM-dd'
            default-value="2024"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :picker-options="pickerOptions"
            @change="changeDate">
        </el-date-picker>
      </div>
    </div>
    <div class="horizontal-container">
      <div class="horizontal-item">
        <h3 class="title_box">折线图</h3>
      </div>
      <div class="horizontal-item">
        <el-select v-model="chartShowType" placeholder="请选择" @change="updateChartShowType">
          <el-option
              v-for="item in chartShowTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="horizontal-item">
      </div>
    </div>
    <div class="echart" id="mychart" :style="myChartStyle"></div>
    <div class="data_box" v-if="showKj()">
      <el-tooltip class="item" effect="dark"
                  content="铠甲计算公式收入：(铠甲的cpm * 0.9 - 非铠甲的cpm) * 展示量 /1000 , 再 30/7 算到1个月的"
                  placement="bottom-start">
        <div><h3>铠甲广告总收入: {{ this.kjAllRevenue }}</h3></div>
      </el-tooltip>
      <div></div>
      <el-table
          class="table"
          border
          :cell-style="tableRowClassName"
          :data="kjResult"
          style="width: 100%">
        <el-table-column
            prop="date"
            width="120"
            sortable
            align="center"
            label="日期">
        </el-table-column>
        <el-table-column
            prop="huawei"
            width="120"
            align="center"
            label="华为渠道">
        </el-table-column>
        <el-table-column
            prop="other"
            width="120"
            align="center"
            label="其他渠道">
        </el-table-column>
        <el-table-column
            prop="oppo"
            width="120"
            align="center"
            label="oppo渠道">
        </el-table-column>
        <el-table-column
            prop="all"
            width="120"
            align="center"
            label="总计">

        </el-table-column>
      </el-table>
    </div>
    <div><h3>详细数据</h3></div>
    <div></div>
    <div class="data_box">
      <el-table
          class="table"
          @sort-change="sortChange"
          border
          ref="multiple"
          :cell-style="tableRowClassName"
          :data="adReportList">
        <!--        <el-table-column-->
        <!--            align="center"-->
        <!--            width="200"-->
        <!--            label="应用名">-->
        <!--          <template slot-scope="scope">-->
        <!--            <p>{{ getAppInfos(scope.row).appName }}</p>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column
            prop="date"
            width="120"
            sortable
            align="center"
            label="日期">
        </el-table-column>
        <el-table-column
            align="center"
            label="总请求量">
          <template slot-scope="scope">
            <p>{{ (getAppInfos(scope.row).ttRequestCount + getAppInfos(scope.row).txRequestCount) }}</p>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="80"
            label="穿山甲">
          <template slot-scope="scope">
            <p>{{ getAppInfos(scope.row).ttRequestCount }}</p>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="80"
            label="优量汇">
          <template slot-scope="scope">
            <p>{{ getAppInfos(scope.row).txRequestCount }}</p>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="总展示量">
          <template slot-scope="scope">
            <p>{{ (getAppInfos(scope.row).ttShowCount + getAppInfos(scope.row).txShowCount) }}</p>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="80"
            label="穿山甲">
          <template slot-scope="scope">
            <p>{{ getAppInfos(scope.row).ttShowCount }}</p>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="80"
            label="优量汇">
          <template slot-scope="scope">
            <p>{{ getAppInfos(scope.row).txShowCount }}</p>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="日活">
          <template slot-scope="scope">
            <p>{{ getAppInfos(scope.row).activeCount }}</p>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="总请求量/日活">
          <template slot-scope="scope">
            <p>{{ getRequestAndActiveRatio(scope.row) }}</p>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="总展示量/日活">
          <template slot-scope="scope">
            <p>{{ getShowAndActiveRatio(scope.row) }}</p>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="收入/日活">
          <template slot-scope="scope">
            <el-popover trigger="hover" placement="top">
              <p>穿山甲: {{ getAppInfos(scope.row).ttRevenue.toFixed(2) }}</p>
              <p>优量汇: {{ getAppInfos(scope.row).txRevenue.toFixed(2) }}</p>
              <div slot="reference" class="name-wrapper">
                <p>{{ getRevenueAndActiveRatio(scope.row) }}</p>
              </div>
            </el-popover>
          </template>
        </el-table-column>
        <!--        <el-table-column-->
        <!--            align="center"-->
        <!--            label="广告数据">-->
        <!--          <template slot-scope="scope">-->
        <!--            <el-button type="primary" size="medium"-->
        <!--                       @click="showAdInfos(getAppInfos(scope.row).ttInfos)">穿山甲-->
        <!--            </el-button>-->
        <!--            <el-button type="primary" size="medium"-->
        <!--                       @click="showAdInfos(getAppInfos(scope.row).txInfos)">优量汇-->
        <!--            </el-button>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column
            width="120"
            align="center"
            label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="medium"
                       @click="updateAdReport(scope.row.date)">刷新
            </el-button>
          </template>
        </el-table-column>

      </el-table>
      <div class="page">
        <el-pagination
            background
            :page-size="5"
            :current-page="pageNo"
            layout="prev, pager, next"
            :page-count="pageSum"
            @current-change="changePage">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "adReportManager",
  components: {},
  data: function () {
    return {
      activeNames: ['1'],
      myChart: {},
      xData: [], //日期
      yData: [], //请求量日活数据
      myChartSeries: [], //广告数据
      myChartStyle: {float: "left", width: "100%", height: "400px"}, //图表样式
      showLoading: false,//是否显示弹框
      adReportList: [],//所有数据列表
      appAdReportList: [],//单个APP数据列表
      appName: "暴走P图",
      appPlacement: "开屏",
      selectDate: [],
      pickerOptions: {
        // 时间不能大于当前时间
        disabledDate: (time) => {
          return time.getTime() > Date.now() - 8.64e7;
        },
        shortcuts: [{
          text: '最近三天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      chartShowType: 0,
      //应用分类列表
      chartShowTypeList: [{
        id: 0,
        label: '总广告请求和展示比例',
        value: 0
      },
        //   {
        //   id: 1,
        //   label: '广告位展示量是否正常',
        //   value: 1
        // }, {
        //   id: 2,
        //   label: '代码位展示量是否正常',
        //   value: 2
        // }, {
        //   id: 3,
        //   label: '第三方优化商检查-铠甲广告等',
        //   value: 3
        // },
        {
          id: 4,
          label: '第三方优化商检查-铠甲广告等',
          value: 4
        }],
      //应用分类列表
      appList: [{
        id: 0,
        label: '暴走P图',
        value: '暴走P图'
      }, {
        id: 1,
        label: '暴走P图IOS',
        value: '暴走P图IOS'
      }, {
        id: 2,
        label: '暴走P图PRO',
        value: '暴走P图PRO',
      }, {
        id: 3,
        label: '万能小转盘',
        value: '万能小转盘',
      }, {
        id: 4,
        label: '万能小转盘IOS',
        value: '万能小转盘IOS',
      }],//应用分类列表
      //应用分类列表
      adSlotTypeList: [{
        id: 0,
        label: '开屏',
        value: '暴走P图'
      }, {
        id: 1,
        label: '插屏-半屏',
        value: '暴走P图IOS'
      }, {
        id: 2,
        label: '插屏-半屏',
        value: '暴走P图PRO',
      }, {
        id: 3,
        label: 'Banner',
        value: '万能小转盘',
      }, {
        id: 4,
        label: '激励视频',
        value: '万能小转盘IOS',
      }],//应用分类列表
      pageNo: 1,
      pageCount: 20,
      pageSum: 1,
      searchName: "",
      searchKey: "",
      orderType: 2,
      showMore: -1,
      showMores: [
        {
          label: "全部",
          value: -1
        }, {
          label: "不显示更多",
          value: 0
        }, {
          label: "显示更多",
          value: 1
        }
      ],
      dialogTitle: "",
      selectMessage: {
        type: 1,
        title: '',
        description: '',
        fileList: [],
        jumpUrl: '',
        channel: ''
      },
      //铠甲广告统计
      kjResult: [],
      kjAllRevenue: 0,
      isUpdate: false,
      showDialog: false,
    }
  },
  methods: {
    //获取铠甲收入
    showKj() {
      console.log("showKj", this.kjResult.length > 0, this.kjResult)
      if (this.appName === "暴走P图" && this.kjResult.length > 0) {
        return true;
      }
      return false
    },
    //获取铠甲收入
    getKjRevenueData() {
      console.log("getKjRevenueData")
      this.kjAllRevenue = 0;
      this.kjResult.forEach(item => {
        this.kjAllRevenue = this.kjAllRevenue + item["all"];
      });
    },
    initActiveEcharts() {
      //遍历adReportList集合
      this.xData = []
      this.yData = []
      this.myChartSeries = []
      this.kjResult = []

      const arrays = [];
      if (this.chartShowType === 0) {
        arrays.push({
          name: "请求量/日活",
          data: []
        });
        arrays.push({
          name: "展示量/日活",
          data: []
        });
        arrays.push({
          name: "展示量/日活",
          data: []
        });
      } else {
        arrays.push({
          name: "",
          data: []
        });
        arrays.push({
          name: "",
          data: []
        });
        arrays.push({
          name: "",
          data: []
        });
        arrays.push({
          name: "",
          data: []
        });
        arrays.push({
          name: "",
          data: []
        });
        arrays.push({
          name: "",
          data: []
        });
      }
      var sortedObjects = this.adReportList.slice(0);

      // 根据日期属性排序的比较函数
      function compareDates(a, b) {
        var dateA = new Date(a.date);
        var dateB = new Date(b.date);
        return dateA - dateB;
      }

      // 对副本数组进行排序
      sortedObjects.sort(compareDates);
      sortedObjects.forEach(item => {
        this.xData.push(item.date); // 假设xValue是x轴上的数据
        //数据
        if (this.chartShowType === 0) {
          arrays[0].name = "请求量/日活"
          arrays[0].data.push(this.getRequestAndActiveRatio(item))
          arrays[1].name = "展示量/日活"
          arrays[1].data.push(this.getShowAndActiveRatio(item))
          arrays[2].name = "收入/日活"
          arrays[2].data.push(this.getRevenueAndActiveRatio(item))
        } else {
          // arrays[0].data.push(this.getKjAdCompared(item))
          var insertAd = this.getKjAdCompared(item);
          // 遍历对象的键
          Object.keys(insertAd).forEach((key, index) => {
            arrays[index].name = insertAd[key].code_name
            arrays[index].data.push(insertAd[key].ecpm)
          });
          //插屏半屏-kj-华为:947419563
          //插屏半屏-华为:952881926
          //插屏半屏-kj:954256441
          //插屏半屏:946992815

          //pro-kj:954661209
          //pro:949564426
          const huawei = (insertAd["947419563"].ecpm * 0.9 - insertAd["952881926"].ecpm) * insertAd["947419563"].show / 1000;
          const other = (insertAd["954256441"].ecpm * 0.9 - insertAd["946992815"].ecpm) * insertAd["954256441"].show / 1000
          const oppo = (insertAd["954661209"].ecpm * 0.9 - insertAd["949564426"].ecpm) * insertAd["954661209"].show / 1000
          this.kjResult.push({
            date: item.date,
            huawei: huawei.toFixed(2),
            other: other.toFixed(2),
            oppo: oppo.toFixed(2),
            all: Math.round((huawei + other + oppo) * 100) / 100,
          })
        }
      });
      //获取铠甲广告总收入
      this.getKjRevenueData();
      for (let i = 0; i < arrays.length; i++) {
        this.myChartSeries.push(
            {
              name: arrays[i].name,
              data: arrays[i].data,
              type: "line", // 类型设置为折线图
              smooth: true
            }
        )
      }

      const option = {
        xAxis: {
          data: this.xData
        },
        yAxis: {},
        tooltip: {
          trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
          axisPointer: {// 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        series: this.myChartSeries
      };

      // 获取要绘制图表的 DOM 元素
      var chartDom = document.getElementById('mychart');
      // 获取已存在的图表实例
      var existingChart = echarts.getInstanceByDom(chartDom);
      // 如果已存在图表实例，则销毁它
      if (existingChart) {
        existingChart.dispose();
      }
      this.myChart = echarts.init(chartDom);
      this.myChart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
    showAdInfos(data) {
      this.$confirm(data, "广告数据", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'success'
      })
    },
    updateAdReport(date) {
      this.showLoading = true;
      const that = this;
      const params = {
        "date": date,
      }
      this.$request.post("/monitoring/updateAdReport", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      })
          .then(function (data) {
            that.showLoading = false
            console.log("返回结果", data)
            this.$confirm("更新成功", "提示", {
              confirmButtonText: '确定',
              showClose: false,
              showCancelButton: false,
              center: true,
              type: 'success'
            })
            that.getAdStatisticsList();
          }).catch(function (err) {
        console.log(err)
        that.showLoading = false
        that.showMsg("网络错误")
      })
    },
    tableRowClassName({row, column, rowIndex, columnIndex}) {
      if (columnIndex == 1 || columnIndex == 4) {
        return 'background:	#FFF8DC;'
      } else if (columnIndex == 10 || columnIndex == 9 || columnIndex == 8) {
        return 'background:	#f0f9eb;'
      }
      return '';
    },
    //获取展示量/日活比例
    getAppInfos: function (row) {
      var appInfo = null;
      row.apps.forEach(info => {
        if (info.appName === this.appName) {
          appInfo = info
        }
      })
      return appInfo
    },
    //获取展示量/日活比例
    getRequestAndActiveRatio: function (row) {
      var info = this.getAppInfos(row)
      return ((info.ttRequestCount + info.txRequestCount) / info.activeCount).toFixed(2).toString()
    },
    //获取展示量/日活比例
    getKjAdCompared: function (row) {
      var result = {};
      //插屏半屏-kj-华为:947419563
      //插屏半屏-华为:952881926
      //插屏半屏-kj:954256441
      //插屏半屏:946992815

      //pro-kj:954661209
      //pro:949564426
      //获取当前APP的数据，对于铠甲广告数据，需要获取暴走P图和暴走P图PRO
      row.apps.forEach(info => {
        if (info.appName === '暴走P图' || info.appName === '暴走P图PRO') {
          info.ttInfos.forEach(function (item) {
            var adSlotId = item.ad_slot_id;
            if (adSlotId === 947419563 || adSlotId === 952881926 || adSlotId === 954256441 ||
                adSlotId === 946992815 || adSlotId === 954661209 || adSlotId === 949564426) {
              if (!result[adSlotId]) {
                // 如果当前类型的累加结果不存在，则创建一个新的对象
                result[adSlotId] = {
                  show: item.show,
                  request: item.request,
                  code_name: item.code_name,
                  revenue: item.revenue,
                  ecpm: item.ecpm,
                  // 可以添加其他需要累加的属性...
                };
              }
            }
          });
        }
      })

      console.log("结果", result)

      return result
    },
    //获取收入/日活比例
    getRevenueAndActiveRatio: function (row) {
      var info = this.getAppInfos(row)
      return ((info.ttRevenue + info.txRevenue) / info.activeCount).toFixed(4).toString()
    },
    //获取请求量/日活比例
    getShowAndActiveRatio: function (row) {
      var info = this.getAppInfos(row)
      return ((info.ttShowCount + info.txShowCount) / info.activeCount).toFixed(4).toString()
    },
    //获取真实地址信息
    getRealUrl: function (url) {
      return 'http://file.baozouptu.intelimeditor.com/' + url;
    },
    updateAppName: function () {
      console.log("chartShowType", this.appName);
      this.chartShowType = 0;
      this.initActiveEcharts();
    },
    updateChartShowType: function () {
      console.log("chartShowType", this.chartShowType);
      this.initActiveEcharts();
    },
    setDefaultDate() {
      const cachedDate = localStorage.getItem('selectDate');
      if (cachedDate) {
        this.selectDate = JSON.parse(cachedDate);
      }
      console.log("selectDate 初始默认值 ", this.selectDate, cachedDate)
      console.log("selectDate 初始设置默认值 ", this.selectDate.length)
      if (this.selectDate.length > 0) {
        return
      }
      const end = new Date();
      const start = new Date();
      end.setTime(end.getTime() - 3600 * 1000 * 24)//7就是七天 14就是两个周 可改
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 8)//7就是七天 14就是两个周 可改
      // 转换日期格式为 "yyyy-MM-dd"
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
      //将值设置给插件绑定的数据
      this.selectDate.push(formatDate(end));
      this.selectDate.unshift(formatDate(start));
    },
    changeDate() {
      localStorage.setItem('selectDate', JSON.stringify(this.selectDate));
      this.getAdStatisticsList();
    },
    getAdStatisticsList: function () {
      this.showLoading = true;
      // 先尝试从本地存储中获取缓存数据
      const cachedData = localStorage.getItem('getAdStatisticsList');
      if (cachedData) {
        console.log("读取缓存")
        this.adReportList = JSON.parse(cachedData);
        this.initActiveEcharts();
        this.showLoading = false; // 隐藏加载状态
        return; // 如果有缓存数据，直接返回，不再发起网络请求
      }
      console.log("直接请求")
      const that = this;
      const params = {
        "startDate": this.selectDate[0],
        "endDate": this.selectDate[1],
      }
      this.$request.post("/monitoring/testReport", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      })
          .then(function (data) {
            that.showLoading = false
            console.log("返回结果", data)

            if (data.status === 200) {
              that.adReportList = data.data
              // 将获取的数据保存到本地存储中
              localStorage.setItem('adReportList', JSON.stringify(data.data));
              that.initActiveEcharts();
            } else {
              that.showMsg(data.data)
            }
          }).catch(function (err) {
        console.log(err)
        that.showLoading = false
        that.showMsg("网络错误")
      })
    },
    /**
     * 显示错误消息
     * @param title
     */
    showMsg: function (title) {
      this.$confirm(title, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
    changePage: function (pageNo) {
      this.pageNo = pageNo;
      this.getAdStatisticsList()
    },
    search: function () {
      this.pageNo = 1;
      this.orderType = 2;
      this.getAdStatisticsList()
    },
    reset: function () {
      this.pageNo = 1;
      this.searchName = "";
      this.searchKey = "";
      this.showMore = -1;
      this.orderType = 2;
      this.getAdStatisticsList()
    },
    /**
     * 排序规则改变
     */
    sortChange: function ({column, prop, order}) {
      switch (order) {
        case "ascending":
          //升序
          this.orderType = 1;
          break;
        case "descending":
          //升序
          this.orderType = 2;
          break;
        case null:
          //不排序
          this.orderType = 0;
          break;
      }
      this.pageNo = 1;//重置页码
      console.log("table", this.$refs.multiple.tableData)
      this.initActiveEcharts()
    },
    closeDialog: function () {
      this.selectMessage = {}
    },
    saveStatus: function (flag) {
      if (flag) {
        this.reset();
        this.getAdStatisticsList()
      }
      this.showDialog = false
    },
    addMessage: function () {
    },
    updateMessage: function (message) {
      if (message.url != null) {
        message.fileList = [{
          name: this.getRealUrl(message.url),
          url: this.getRealUrl(message.url)
        }]
      } else {
        message.fileList = []
      }
      if (message.videoUrl != null) {
        message.videoFile = [{
          name: this.getRealUrl(message.videoUrl),
          url: this.getRealUrl(message.videoUrl)
        }]
      } else {
        message.videoFile = []
      }

      this.dialogTitle = "修改消息"
      this.isUpdate = true
      this.showDialog = true
      this.selectMessage = message
    },
    delMessage: function (id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        cancelButtonClass: "messagebox-off-button-color",
        confirmButtonClass: "messagebox-ok-button-color"
      }).then(() => {
        this.del(id)
      }).catch(() => {
      });
    },
    /**
     * 删除一个图片
     * @param id
     */
    del: function (id) {
      this.requestNum++;
      this.showLoading = true;
      const that = this;
      const params = {
        "delete": [id]
      }
      this.$request.post("/comfyAI/deleteStyle", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      })
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("删除成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.getAdStatisticsList()
              })
            } else {
              that.showMsg(data.data);
            }
          }).catch(function (err) {
        that.showLoading = false
        that.showMsg("网络错误");
      })
    }
  },
  mounted() {
    this.$bus.$emit("setMenuActiveIndex", "8-1");
    this.setDefaultDate();
    this.getAdStatisticsList()
  }
}
</script>

<style scoped>
.tutorial_box {
  width: 100%;
  min-height: 100%;
  background: white;
  border-radius: 5px;
  padding: 10px 20px;
}

.box_operation {
  width: 100%;
  padding: 20px 0;
}

.title_box {
  margin: 0 10px;
  margin-top: 10px;
}

.input_box {
  display: inline-block;
  margin: 0 10px;
  margin-top: 10px;
}

.btn_box {
  margin-top: 10px;
  height: 40px;
  margin-left: 20px;
  vertical-align: top;
  display: inline-block;
}

.input_box_select {
  width: 150px;
}

.data_box {
  width: 100%;
}

.preview_image {
  width: 80px;
  height: 80px;
}

.data_box /deep/ .el-table .warning-row {
  background: oldlace;
}

.data_box /deep/ .el-table .success-row {
  background: #f0f9eb;
}

.page {
  width: 100%;
  margin: 20px 0;
  text-align: center;
}

.horizontal-container {
  display: flex;
  flex-direction: row;
}

.horizontal-item {
  margin-right: 20px; /* 控制元素之间的间距 */
}

.table {
  /*position: fixed;*/
  height: 100%;
}
</style>
