<template>
  <div class="user_share_box" v-loading.fullscreen.lock="showLoading">
    <div class="data_box">
      <div class="box_operation">
        <div class="btn_box">
          <el-button type="primary" @click="add">添加</el-button>
        </div>
        <div class="btn_box">
          <el-button type="danger" @click="getMatchList">刷新</el-button>
        </div>
        <div class="btn_box">
          <el-button type="info" @click="delIds">删除{{ delDataIds.length }}</el-button>
        </div>
      </div>
      <div class="data_box">
        <el-table
            class="table"
            :data="matchList"
            @selection-change="selectAllChange"
            style="width: 100%">
          <el-table-column
              type="selection"
              width="55">
          </el-table-column>
          <el-table-column
              align="center"
              prop="id"
              label="id"
              width="60">
          </el-table-column>
          <el-table-column
              align="center"
              label="关键字"
              prop="searchName"
              width="200">
          </el-table-column>
          <el-table-column
              align="center"
              prop="matchName"
              label="匹配列表">
          </el-table-column>
          <el-table-column
              align="center"
              prop="updateTime"
              width="160"
              label="更新时间">
          </el-table-column>
          <el-table-column
              fixed="right"
              width="250"
              align="center"
              label="操作">
            <template slot-scope="scope">
              <el-button type="primary" size="medium" icon="el-icon-edit" @click="updateMatch(scope.row)"></el-button>
              <el-button type="danger" size="medium" icon="el-icon-delete" @click="delData(scope.row.id)"></el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination
              background
              :page-size="5"
              :current-page="pageNo"
              layout="prev, pager, next"
              :page-count="pageSum"
              @current-change="changePage">
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog :title="showDialogTitle"
               top="5vh"
               :visible.sync="showDialog"
               :close-on-click-modal="false"
               width="1200px"
               :destroy-on-close="true"
               :fullscreen="false">
      <SearchMatchRulesEdit :matchRules="selectMatch" @saveStatus="saveStatus" :is-update="isUpdate"/>
    </el-dialog>
  </div>
</template>

<script>
import SearchMatchRulesEdit from "./SearchMatchRulesEdit";
export default {
  name: "SearchMatchRules",
  components:{
    SearchMatchRulesEdit
  },
  data:function (){
    return{
      showDialog:false,//是否显示弹框
      showDialogTitle:"添加规则",
      showLoading:false,
      matchList:[],
      pageNo:1,
      pageCount:20,
      pageSum:1,
      searchKey:"",
      startTime:"",
      endTime:"",
      delDataIds:[],
      selectMatch:{},
      isUpdate:false
    }
  },
  methods:{
    /**
     * 获取规则列表
     */
    getMatchList:function (){
      this.showLoading = true;
      const that = this;
      const params = new URLSearchParams()
      params.append("isPage", 1)
      params.append("pageNo", this.pageNo)
      params.append("pageCount", this.pageCount)
      this.$request.post("/matchName/getMatchList", params)
          .then(function (data) {
            that.showLoading = false
            that.pageSum = data.data.pages
            that.matchList = data.data.records
          }).catch(function (err) {
            that.showErrorMessage("网络错误")
            that.showLoading = false
       })
      },
     showErrorMessage:function (msg){
        this.$confirm(msg, "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      },
    /**
     * 搜索列表
     */
    search:function (){
       this.pageNo = 1
       this.getMatchList()
    },
    /**
     * 删除数据
     * @param id 数据id
     */
    delData:function (id){
      this.$confirm('此操作将永久删除选中的数据, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        cancelButtonClass: "messagebox-off-button-color",
        confirmButtonClass: "messagebox-ok-button-color"
      }).then(() => {
        this.del(id)
      }).catch(() => {
      });
    },
    del:function (id){
      this.showLoading = true;
      const that = this;
      const params = new URLSearchParams()
      params.append("ids", id)
      //此处应该调用后台接口
      this.$request.post("/matchName/delMatchList", params)
          .then(function (data) {
            that.showLoading = false;
            if (data.status === 200) {
              that.$confirm("删除成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.getMatchList()
              })
            } else {
              that.showError(data.data)
            }
          }).catch(function (err) {
        that.showLoading = false;
        that.showError("网络错误")
      })
    },
    /**
     * 删除全选的数据列表
     */
      delIds:function (){
        this.$confirm('此操作将永久删除选中的数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          cancelButtonClass: "messagebox-off-button-color",
          confirmButtonClass: "messagebox-ok-button-color"
        }).then(() => {
          this.delAll()
        }).catch(() => {
        });
        },
    /**
     * 删除多选数据列表
     */
    delAll:function (){
      if (this.delDataIds === undefined || this.delDataIds.length === 0) {
        return
      }
      this.showLoading = true;
      const that = this;
      const params = new URLSearchParams()
      this.delDataIds.forEach((item) => {
        params.append("ids", item)
      })
      //此处应该调用后台接口
      this.$request.post("/matchName/delMatchList", params)
          .then(function (data) {
            that.showLoading = false;
            if (data.status === 200) {
              that.$confirm("删除成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.getMatchList()
              })
            } else {
              that.showError(data.data)
            }
          }).catch(function (err) {
            that.showLoading = false;
            that.showError("网络错误")
      })
    },
    /**
     * 多选回调
     */
    selectAllChange:function (data){
      //清空数组
      this.delDataIds = new Array();
      //遍历选中的重新赋值
      data.forEach((item) => {
        this.delDataIds.push(item.id)
      })
    },
    /**
     * 页码改变时
     * @param pageNo 改变的页码
     */
    changePage: function (pageNo) {
      this.pageNo = pageNo;
      this.getMatchList();
    },
    add:function (){
      this.selectMatch ={}
      this.showDialog = true
      this.isUpdate = false
    },
    /**
     * 显示弹框
     * @param exception 当前选择的异常
     */
    updateMatch:function (match){
      this.selectMatch = match
      this.showDialog = true
      this.isUpdate = true
    },
    saveStatus:function (flag){
      if (flag){
        this.getMatchList()
      }
      this.showDialog = false
    },
    /**
     * 显示错误信息弹框
     * @param msg 错误信息弹框
     */
    showError: function (msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
  },
  mounted() {
    this.$bus.$emit("setMenuActiveIndex", "3-1-2");
    this.getMatchList()
  }
}
</script>

<style scoped>
  .user_share_box{
    width: 100%;
    min-height: 100%;
    background: white;
    border-radius: 5px;
    padding: 10px 20px;
  }
  .box_operation {
    width: 100%;
    padding: 10px 0;
  }

  .input_box {
    display: inline-block;
    width: 200px;
    margin: 0 10px;
    margin-top: 10px;
  }

  .input_box /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
    width: 90%;
    margin: 0 auto;
  }

  .btn_box {
    height: 40px;
    margin-top: 10px;
    margin: 10px;
    vertical-align: top;
    display: inline-block;
  }

  .input_box_select {
    width: 150px;
  }

  .data_box {
    width: 100%;

  }

  .preview_image {
    width: 30px;
    height: 30px;
  }

  .data_box /deep/ .el-table .warning-row {
    background: oldlace;
  }

  .data_box /deep/ .el-table .success-row {
    background: #f0f9eb;
  }

  .page {
    width: 100%;
    margin: 20px 0;
    text-align: center;
  }

  .table {
    /*position: fixed;*/
    height: 100%;
  }
</style>