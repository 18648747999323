<template>
  <!--    图片添加修改-->
  <div class="imageBox" v-loading="showLoading">
    <el-form ref="form" :model="messageInfo" label-width="120px">
      <el-form-item label="类型">
        <div class="input_box">
          <el-select v-model="messageInfo.type" placeholder="请选择">
            <el-option
                v-for="item in showTypes"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </div>
      </el-form-item>

      <el-form-item label="编辑部位名称">
        <el-input v-model="messageInfo.editPartName" class="input"></el-input>
      </el-form-item>

      <el-form-item label="提示词">
        <el-input v-model="messageInfo.prompt" class="input"></el-input>
      </el-form-item>

      <el-form-item label="上传图片">
        <el-upload
            class="upload-demo"
            action="none"
            :file-list="messageInfo.fileList"
            :on-exceed="imageCountOut"
            :on-remove="handleRemove"
            :on-change="fileChange"
            :auto-upload="false"
            :limit="1">
          <el-button size="small" typ e="primary">点击上传</el-button>
          <div slot="tip" class="el-upload__tip">只能上传一个文件，且不超过1MB</div>
        </el-upload>
      </el-form-item>
      <el-form-item class="btn">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "MessageEdit",
  props: ['messageInfo', "isUpdate"],
  data: function () {
    return {
      showLoading: false,
      showTypes: [{
        label: "发型",
        value: 1
      }, {
        label: "服装",
        value: 2
      }, {
        label: "人脸",
        value: 3
      }, {
        label: "写真",
        value: 4
      }
      ],
    }
  },
  methods: {
    imageCountOut: function () {
      this.showErrorMessage("只能选择一个文件")
    },
    handleRemove: function (file, fileList) {
      this.messageInfo.file = null
      if (this.isUpdate) {
        this.messageInfo.isDelImage = 1;//设置删除之前的图片
      }
    },
    fileChange: function (file, fileList) {
      this.messageInfo.file = file.raw
    },
    save: function () {
      if (!this.isUpdate) {
        this.addImageStyle()
      } else {
        this.update()
      }
    },
    cancel: function () {
      this.$emit('saveStatus', false)
    },
    update: function () {
      const that = this;
      const params = new FormData()
      params.append("id", this.messageInfo.id)

      if (this.messageInfo.editPartName !== undefined && this.messageInfo.editPartName != null) {
        params.append("editPartName", this.messageInfo.editPartName)
      }

      if (this.messageInfo.prompt !== undefined && this.messageInfo.prompt != null) {
        params.append("prompt", this.messageInfo.prompt)
      }

      params.append("type", this.messageInfo.type)
      if (this.messageInfo.file !== undefined && this.messageInfo.file != null) {
        params.append("styleImageFile", this.messageInfo.file)
      }
      this.showLoading = true;
      this.$request.post("/comfyAI/updateStyle", params)
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("更新成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.$emit('saveStatus', true)
              })
            } else {
              that.showErrorMessage(data.data)
            }
          }).catch(function (err) {
        that.showLoading = false
        that.showErrorMessage("网络错误")
      })
    },
    addImageStyle: function () {
      if (this.messageInfo.file === undefined || this.messageInfo.file === null) {
        this.showErrorMessage("请选择样式图片")
        return
      }
      const that = this;
      const params = new FormData()
      params.append("editPartName", this.messageInfo.edit_part_name)
      params.append("prompt", this.messageInfo.prompt)
      params.append("type", this.messageInfo.type)
      if (this.messageInfo.file !== undefined && this.messageInfo.file != null) {
        params.append("styleImageFile", this.messageInfo.file)
      }
      this.showLoading = true;
      this.$request.post("/comfyAI/addStyle", params)
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("添加成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.$emit('saveStatus', true)
              })
            } else {
              that.showErrorMessage(data.data)
            }
          }).catch(function (err) {
        that.showLoading = false
        that.showErrorMessage("网络错误")
      })
    },
    /**
     * 显示错误信息弹框
     * @param msg 错误信息弹框
     */
    showErrorMessage: function (msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
  }
}
</script>

<style scoped>
.imageBox {
  width: 100%;
  min-height: 300px;
}

.btn /deep/ .el-form-item__content {
  margin-left: 0 !important;
  text-align: center;
}

.input {
  width: 300px;
}
</style>