<template>
  <div>
    <el-row :gutter="15">
      <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="100px">
        <el-col :span="8">
          <el-form-item label="选择列" prop="field101">
            <el-select v-model="formData.field101" placeholder="请选择选择列" clearable :style="{width: '100%'}">
              <el-option v-for="(item, index) in field101Options" :key="index" :label="item.label"
                :value="item.value" :disabled="item.disabled"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="选择条件" prop="field102">
            <el-input v-model="formData.field102" placeholder="请输入选择条件" clearable
              prefix-icon='el-icon-platform-eleme' suffix-icon='el-icon-phone-outline'
              :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="选择列" prop="field106">
            <el-select v-model="formData.field106" placeholder="请选择选择列" clearable :style="{width: '100%'}">
              <el-option v-for="(item, index) in field106Options" :key="index" :label="item.label"
                :value="item.value" :disabled="item.disabled"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="选择条件" prop="field110">
            <el-input v-model="formData.field110" placeholder="请输入选择条件" clearable
              prefix-icon='el-icon-platform-eleme' suffix-icon='el-icon-phone-outline'
              :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="选择列" prop="field112">
            <el-select v-model="formData.field112" placeholder="请选择选择列" clearable :style="{width: '100%'}">
              <el-option v-for="(item, index) in field112Options" :key="index" :label="item.label"
                :value="item.value" :disabled="item.disabled"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="选择条件" prop="field113">
            <el-input v-model="formData.field113" placeholder="请输入选择条件" clearable
              prefix-icon='el-icon-platform-eleme' suffix-icon='el-icon-phone-outline'
              :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="选择列" prop="field114">
            <el-select v-model="formData.field114" placeholder="请选择选择列" clearable :style="{width: '100%'}">
              <el-option v-for="(item, index) in field114Options" :key="index" :label="item.label"
                :value="item.value" :disabled="item.disabled"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="选择条件" prop="field115">
            <el-input v-model="formData.field115" placeholder="请输入选择条件" clearable
              prefix-icon='el-icon-platform-eleme' suffix-icon='el-icon-phone-outline'
              :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="选择列" prop="field116">
            <el-select v-model="formData.field116" placeholder="请选择选择列" clearable :style="{width: '100%'}">
              <el-option v-for="(item, index) in field116Options" :key="index" :label="item.label"
                :value="item.value" :disabled="item.disabled"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="选择条件" prop="field117">
            <el-input v-model="formData.field117" placeholder="请输入选择条件" clearable
              prefix-icon='el-icon-platform-eleme' suffix-icon='el-icon-phone-outline'
              :style="{width: '100%'}"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24">
          <el-form-item size="large">
            <el-button type="primary" @click="submitForm">提交</el-button>
            <el-button @click="resetForm">重置</el-button>
          </el-form-item>
        </el-col>
      </el-form>
    </el-row>
  </div>
</template>
<script>
export default {
  components: {},
  props: [],
  data() {
    return {
      formData: {
        field101: 'tag',
        field102: undefined,
        field106: undefined,
        field110: undefined,
        field112: undefined,
        field113: undefined,
        field114: undefined,
        field115: undefined,
        field116: undefined,
        field117: undefined,
      },
      rules: {
        field101: [],
        field102: [],
        field106: [],
        field110: [],
        field112: [],
        field113: [],
        field114: [],
        field115: [],
        field116: [],
        field117: [],
      },
      field101Options: [{
        "label": "图片标签",
        "value": "tag"
      }, {
        "label": "热度",
        "value": "heat"
      }, {
        "label": "分类",
        "value": "category"
      }, {
        "label": "大小",
        "value": "size"
      }, {
        "label": "开始日期",
        "value": "startTime"
      }, {
        "label": "结束日期",
        "value": "endTime"
      }],
      field106Options: [{
        "label": "图片标签",
        "value": "tag"
      }, {
        "label": "热度",
        "value": "heat"
      }, {
        "label": "分类",
        "value": "category"
      }, {
        "label": "大小",
        "value": "size"
      }, {
        "label": "开始日期",
        "value": "startTime"
      }, {
        "label": "结束日期",
        "value": "endTime"
      }],
      field112Options: [{
        "label": "图片标签",
        "value": "tag"
      }, {
        "label": "热度",
        "value": "heat"
      }, {
        "label": "分类",
        "value": "category"
      }, {
        "label": "大小",
        "value": "size"
      }, {
        "label": "开始日期",
        "value": "startTime"
      }, {
        "label": "结束日期",
        "value": "endTime"
      }],
      field114Options: [{
        "label": "图片标签",
        "value": "tag"
      }, {
        "label": "热度",
        "value": "heat"
      }, {
        "label": "分类",
        "value": "category"
      }, {
        "label": "大小",
        "value": "size"
      }, {
        "label": "开始日期",
        "value": "startTime"
      }, {
        "label": "结束日期",
        "value": "endTime"
      }],
      field116Options: [{
        "label": "图片标签",
        "value": "tag"
      }, {
        "label": "热度",
        "value": "heat"
      }, {
        "label": "分类",
        "value": "category"
      }, {
        "label": "大小",
        "value": "size"
      }, {
        "label": "开始日期",
        "value": "startTime"
      }, {
        "label": "结束日期",
        "value": "endTime"
      }],
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    submitForm() {
      // this.$refs['elForm'].validate(valid => {
      //   if (!valid) return
      //   // TODO 提交表单
      //   console.log(this.formData)
      // })
      this.$emit("postFrom",this.formData)
    },
    resetForm() {
      this.$refs['elForm'].resetFields()
    },
  }
}

</script>
<style>
</style>
