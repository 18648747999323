<template>
  <div class="tutorial_box" v-loading.fullscreen.lock="showLoading">
    <div class="box_operation">
      <!--            操作列表-->

      <div class="input_box">
        <el-select v-model="appName" placeholder="请选择" @change="getAdStatisticsList">
          <el-option
              v-for="item in appList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="input_box">
        <el-select v-model="appPlacement" placeholder="请选择" @change="getAdStatisticsList">
          <el-option
              v-for="item in adSlotTypeList"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="btn_box">
        <el-button type="danger" @click="getAdStatisticsList">刷新</el-button>
      </div>
    </div>
    <div class="echart" id="mychart" :style="myChartStyle"></div>
    <div class="data_box">
      <el-table
          class="table"
          @sort-change="sortChange"
          border
          :cell-style="tableRowClassName"
          :data="adStatisticsList"
          style="width: 100%">
        <el-table-column
            align="center"
            prop="id"
            label="id"
            width="60">
        </el-table-column>
        <el-table-column
            prop="appName"
            align="center"
            width="200"
            label="应用名">
        </el-table-column>
        <el-table-column
            prop="date"
            align="center"
            label="日期">
        </el-table-column>
        <el-table-column
            align="center"
            label="请求量=穿山甲+优量汇">
          <template slot-scope="scope">
            <p>{{ (scope.row.ttRequest + scope.row.txRequest) }}</p>
          </template>
        </el-table-column>
        <el-table-column
            prop="ttRequest"
            align="center"
            width="80"
            label="穿山甲">
        </el-table-column>
        <el-table-column
            prop="txRequest"
            align="center"
            width="80"
            label="优量汇">
        </el-table-column>
        <el-table-column
            align="center"
            label="展示量=穿山甲+优量汇">
          <template slot-scope="scope">
            <p>{{ ((scope.row.ttShow + scope.row.txShow)) }}</p>
          </template>
        </el-table-column>
        <el-table-column
            prop="ttShow"
            align="center"
            width="80"
            label="穿山甲">
        </el-table-column>
        <el-table-column
            prop="txShow"
            align="center"
            width="80"
            label="优量汇">
        </el-table-column>
        <el-table-column
            prop="activeCount"
            align="center"
            label="日活">
        </el-table-column>
        <el-table-column
            align="center"
            label="请求量/日活">
          <template slot-scope="scope">
            <p>{{ getRequestAndActiveRatio(scope.row) }}</p>
          </template>
        </el-table-column>

        <el-table-column
            align="center"
            label="展示量/日活">
          <template slot-scope="scope">
            <p>{{ getShowAndActiveRatio(scope.row) }}</p>
          </template>
        </el-table-column>

        <el-table-column
            align="center"
            label="收入/日活">
          <template slot-scope="scope">
            <p>{{ getShowAndActiveRatio(scope.row) }}</p>
          </template>
        </el-table-column>

        <el-table-column
            width="120"
            align="center"
            label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="medium"
                       @click="updateAdReport(scope.row.date)">刷新
            </el-button>
          </template>
        </el-table-column>

      </el-table>
      <div class="page">
        <el-pagination
            background
            :page-size="5"
            :current-page="pageNo"
            layout="prev, pager, next"
            :page-count="pageSum"
            @current-change="changePage">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "appStatistics",
  components: {},
  data: function () {
    return {
      myChart: {},
      xData: [], //日期
      yData: [], //请求量日活数据
      myChartStyle: {float: "left", width: "100%", height: "400px"}, //图表样式
      showLoading: false,//是否显示弹框
      adStatisticsList: [],//数据列表
      appName: "暴走P图",
      appPlacement: "开屏",
      //应用分类列表
      appList: [{
        id: 0,
        label: '暴走P图',
        value: '暴走P图'
      }, {
        id: 1,
        label: '暴走P图IOS',
        value: '暴走P图IOS'
      }, {
        id: 2,
        label: '暴走P图PRO',
        value: '暴走P图PRO',
      }, {
        id: 3,
        label: '万能小转盘',
        value: '万能小转盘',
      }, {
        id: 4,
        label: '万能小转盘IOS',
        value: '万能小转盘IOS',
      }],//应用分类列表
      //应用分类列表
      adSlotTypeList: [{
        id: 0,
        label: '开屏',
        value: '暴走P图'
      }, {
        id: 1,
        label: '插屏-半屏',
        value: '暴走P图IOS'
      }, {
        id: 2,
        label: '插屏-半屏',
        value: '暴走P图PRO',
      }, {
        id: 3,
        label: 'Banner',
        value: '万能小转盘',
      }, {
        id: 4,
        label: '激励视频',
        value: '万能小转盘IOS',
      }],//应用分类列表
      pageNo: 1,
      pageCount: 20,
      pageSum: 1,
      searchName: "",
      searchKey: "",
      orderType: 2,
      showMore: -1,
      showMores: [
        {
          label: "全部",
          value: -1
        }, {
          label: "不显示更多",
          value: 0
        }, {
          label: "显示更多",
          value: 1
        }
      ],
      dialogTitle: "",
      selectMessage: {
        type: 1,
        title: '',
        description: '',
        fileList: [],
        jumpUrl: '',
        channel: ''
      },
      isUpdate: false,
      showDialog: false,
    }
  },
  methods: {
    initEcharts() {
      //遍历adStatisticsList集合
      this.xData = []
      this.yData = []
      this.y1Data = []

      this.adStatisticsList.forEach(item => {
        this.xData.unshift(item.date); // 假设xValue是x轴上的数据
        this.yData.unshift(this.getRequestAndActiveRatio(item)); // 假设yValue是y轴上的数据
        this.y1Data.unshift(this.getShowAndActiveRatio(item)); // 假设yValue是y轴上的数据
      });

      const option = {
        xAxis: {
          data: this.xData
        },
        yAxis: {},
        tooltip: {
          trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
          axisPointer: {// 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        series: [
          {
            data: this.yData,
            type: "line", // 类型设置为折线图
            smooth: true
          },
          {
            data: this.y1Data,
            type: "line", // 类型设置为折线图
            smooth: true
          }
        ]
      };
      this.myChart = echarts.init(document.getElementById("mychart"));
      this.myChart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
    updateAdReport(date) {
      this.showLoading = true;
      const that = this;
      const params = {
        "date": date,
      }
      this.$request.post("/monitoring/updateAdReport", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      })
          .then(function (data) {
            that.showLoading = false
            console.log("返回结果", data)
            this.$confirm("更新成功", "提示", {
              confirmButtonText: '确定',
              showClose: false,
              showCancelButton: false,
              center: true,
              type: 'success'
            })
            that.getAdStatisticsList();
          }).catch(function (err) {
        console.log(err)
        that.showLoading = false
        that.showMsg("网络错误")
      })
    },
    tableRowClassName({row, column, rowIndex, columnIndex}) {
      if (columnIndex == 3 || columnIndex == 6) {
        return 'background:	#FFF8DC;'
      } else if (columnIndex == 10 || columnIndex == 9) {
        return 'background:	#f0f9eb;'
      }
      return '';
    },
    //获取展示量/日活比例
    getRequestAndActiveRatio: function (row) {
      return ((row.ttRequest + row.txRequest) / row.activeCount).toFixed(2).toString()
    },
    //获取请求量/日活比例
    getShowAndActiveRatio: function (row) {
      return ((row.ttShow + row.txShow) / row.activeCount).toFixed(2).toString()
    },
    //获取真实地址信息
    getRealUrl: function (url) {
      return 'http://file.baozouptu.intelimeditor.com/' + url;
    },
    getAdStatisticsList: function () {
      this.showLoading = true;
      const that = this;
      const params = {
        "appName": this.appName,
      }
      this.$request.post("/adReport/getStatistics", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      })
          .then(function (data) {
            that.showLoading = false
            console.log("返回结果", data)
            if (data.status === 200) {
              that.adStatisticsList = data.data
              that.initEcharts();
            } else {
              that.showMsg(data.data)
            }
          }).catch(function (err) {
        console.log(err)
        that.showLoading = false
        that.showMsg("网络错误")
      })
    },
    /**
     * 显示错误消息
     * @param title
     */
    showMsg: function (title) {
      this.$confirm(title, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
    changePage: function (pageNo) {
      this.pageNo = pageNo;
      this.getAdStatisticsList()
    },
    search: function () {
      this.pageNo = 1;
      this.orderType = 2;
      this.getAdStatisticsList()
    },
    reset: function () {
      this.pageNo = 1;
      this.searchName = "";
      this.searchKey = "";
      this.showMore = -1;
      this.orderType = 2;
      this.getAdStatisticsList()
    },
    /**
     * 排序规则改变
     */
    sortChange: function ({column, prop, order}) {
      switch (order) {
        case "ascending":
          //升序
          this.orderType = 1;
          break;
        case "descending":
          //升序
          this.orderType = 2;
          break;
        case null:
          //不排序
          this.orderType = 0;
          break;
      }
      this.pageNo = 1;//重置页码
      this.getAdStatisticsList()
    },
    closeDialog: function () {
      this.selectMessage = {}
    },
    saveStatus: function (flag) {
      if (flag) {
        this.reset();
        this.getAdStatisticsList()
      }
      this.showDialog = false
    },
    addMessage: function () {
    },
    updateMessage: function (message) {
      if (message.url != null) {
        message.fileList = [{
          name: this.getRealUrl(message.url),
          url: this.getRealUrl(message.url)
        }]
      } else {
        message.fileList = []
      }
      if (message.videoUrl != null) {
        message.videoFile = [{
          name: this.getRealUrl(message.videoUrl),
          url: this.getRealUrl(message.videoUrl)
        }]
      } else {
        message.videoFile = []
      }

      this.dialogTitle = "修改消息"
      this.isUpdate = true
      this.showDialog = true
      this.selectMessage = message
    },
    delMessage: function (id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        cancelButtonClass: "messagebox-off-button-color",
        confirmButtonClass: "messagebox-ok-button-color"
      }).then(() => {
        this.del(id)
      }).catch(() => {
      });
    },
    /**
     * 删除一个图片
     * @param id
     */
    del: function (id) {
      this.requestNum++;
      this.showLoading = true;
      const that = this;
      const params = {
        "delete": [id]
      }
      this.$request.post("/comfyAI/deleteStyle", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      })
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("删除成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.getAdStatisticsList()
              })
            } else {
              that.showMsg(data.data);
            }
          }).catch(function (err) {
        that.showLoading = false
        that.showMsg("网络错误");
      })
    }
  },
  mounted() {
    this.$bus.$emit("setMenuActiveIndex", "8-2");
    this.getAdStatisticsList()
  }
}
</script>

<style scoped>
.tutorial_box {
  width: 100%;
  min-height: 100%;
  background: white;
  border-radius: 5px;
  padding: 10px 20px;
}

.box_operation {
  width: 100%;
  padding: 20px 0;
}

.input_box {
  display: inline-block;
  width: 200px;
  margin: 0 10px;
  margin-top: 10px;
}

.btn_box {
  margin-top: 10px;
  height: 40px;
  margin-left: 20px;
  vertical-align: top;
  display: inline-block;
}

.input_box_select {
  width: 150px;
}

.data_box {
  width: 100%;
}

.preview_image {
  width: 80px;
  height: 80px;
}

.data_box /deep/ .el-table .warning-row {
  background: oldlace;
}

.data_box /deep/ .el-table .success-row {
  background: #f0f9eb;
}

.page {
  width: 100%;
  margin: 20px 0;
  text-align: center;
}

.table {
  /*position: fixed;*/
  height: 100%;
}
</style>
