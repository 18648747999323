<template>
    <div class="login" v-loading.fullscreen.lock="showLoading">
        <div class="login_bk"></div>
        <div class="login_box">
            <div class="login_box_title">管理员登录</div>
            <div class="input_list">
                <el-input v-model="account" placeholder="请输入账号"></el-input>
                <el-input v-model="password" placeholder="请输入密码" clearable show-password></el-input>
            </div>
            <div class="btn">
                <a href="javascript:;" @click="login">
                    <div class="login_btn">登录</div>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapActions} from 'vuex'

    export default {
        name: "Login",
        data: function () {
            return {
                account: "",
                password: "",
                showLoading: false
            }
        },
        methods: {
            ...mapActions("userOptions", ["saveUserInfo"]),
            login: function () {
                if (this.account === undefined || this.account === "") {
                    this.$confirm("用户名不能为空", "提示", {
                        confirmButtonText: '确定',
                        showClose: false,
                        showCancelButton: false,
                        center: true,
                        type: 'error'
                    })
                    return
                }
                if (this.password === undefined || this.account === "") {
                    this.$confirm("密码不能为空", "提示", {
                        confirmButtonText: '确定',
                        showClose: false,
                        showCancelButton: false,
                        center: true,
                        type: 'error'
                    })
                    return
                }
                const that = this;
                that.showLoading = true;
                const params = {
                    "userName": this.account,
                    "password": this.password,
                }
                this.$request.post("/admin/login",
                    params)
                    .then(function (data) {
                        if (data.status === 200) {
                            //登录成功，保存用户数据
                            that.saveUserInfo(data.data)
                            that.$router.push({
                                name: "imageManage"
                            })
                        } else {
                            that.$confirm(data.message, "提示", {
                                confirmButtonText: '确定',
                                showClose: false,
                                showCancelButton: false,
                                center: true,
                                type: 'error'
                            })
                        }
                        that.showLoading = false
                    }).catch(function (err) {
                    that.showLoading = false
                    that.$confirm("网络错误", "提示", {
                        confirmButtonText: '确定',
                        showClose: false,
                        showCancelButton: false,
                        center: true,
                        type: 'error'
                    })
                })
            }
        }
    }


</script>

<style scoped>
    .login {
        width: 100%;
        height: 100%;
    }

    .login_bk {
        position: fixed;
        z-index: 1;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: url(http://file.baozouptu.intelimeditor.com/manage/image/login_bk.jpeg);
        background-size: cover;
    }

    .login_box {
        position: fixed;
        top: calc(50% - 175px);
        left: calc(50% - 175px);
        z-index: 2;
        width: 350px;
        height: 350px;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 5px;
        padding: 30px;
    }

    .login_box_title {
        font-size: 20px;
        text-align: center;
        color: black;
        margin-bottom: 20px;
    }

    .input_list {
        width: 100%;
    }

    .input_list .el-input {
        margin-top: 30px;
    }

    .login_btn {
        width: 80%;
        height: 40px;
        background-color: #409EFF;
        text-align: center;
        color: white;
        font-size: 16px;
        line-height: 40px;
        border-radius: 5px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40px;
    }
</style>