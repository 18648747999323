<template>
    <div class="categoryBox" v-loading="showLoading">
        <el-form :model="category" ref="form" label-width="80px">
            <el-form-item label="分类名称" prop="categoryName">
                <el-input v-model="category.categoryName" ></el-input>
            </el-form-item>
            <el-form-item label="分类简介" prop="introduce">
                <el-input v-model="category.introduce" ></el-input>
            </el-form-item>
            <el-form-item class="btn">
                <el-button type="primary" @click="save">保存</el-button>
                <el-button @click="cancel">取消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "ImageCategoryEdit",
        props: ['category',"parentId","isUpdate"],
        data:function () {
            return{
                showLoading:false
            }
        },
        methods:{
            save:function(){
               if (this.isUpdate){
                  this.updateCategory()
               }  else {
                   this.addCategory()
               }
            },
            cancel:function(){
                this.$emit('saveStatus', false)
            },
            /**
             * 添加一个分类
             */
            addCategory:function () {
                const that = this;
                const params = new FormData()
                if(this.category.categoryName === undefined || this.category.categoryName === ""){
                    that.$confirm("分类名不能为空", "提示", {
                        confirmButtonText: '确定',
                        showClose: false,
                        showCancelButton: false,
                        center: true,
                        type: 'error'
                    })
                    return;
                }
                if(this.category.introduce === undefined){
                    this.category.introduce = ""
                }
                params.append("parentId", this.parentId)
                params.append("categoryName", this.category.categoryName)
                params.append("introduce", this.category.introduce)
                this.$request.post("/imageCategory/addCategory", params)
                    .then(function (data) {
                        that.showLoading = false
                        if (data.status === 200) {
                            that.$confirm("添加成功", "提示", {
                                confirmButtonText: '确定',
                                showClose: false,
                                showCancelButton: false,
                                center: true,
                                type: 'success'
                            }).then(function () {
                                that.$emit('saveStatus', true)
                            })
                        } else {
                            that.$confirm(data.data, "提示", {
                                confirmButtonText: '确定',
                                showClose: false,
                                showCancelButton: false,
                                center: true,
                                type: 'error'
                            })
                        }
                    }).catch(function (err) {
                        that.showLoading = false
                        that.$confirm("网络错误", "提示", {
                            confirmButtonText: '确定',
                            showClose: false,
                            showCancelButton: false,
                            center: true,
                            type: 'error'
                        })
                })
            },
            updateCategory:function () {
                const that = this;
                const params = new FormData()
                if(this.category.categoryName === undefined || this.category.categoryName === ""){
                    that.$confirm("分类名不能为空", "提示", {
                        confirmButtonText: '确定',
                        showClose: false,
                        showCancelButton: false,
                        center: true,
                        type: 'error'
                    })
                    return;
                }
                if(this.category.introduce === undefined){
                    this.category.introduce = ""
                }
                params.append("id", this.category.id)
                params.append("categoryName", this.category.categoryName)
                params.append("introduce", this.category.introduce)
                this.$request.post("/imageCategory/updateCategory", params)
                    .then(function (data) {
                        that.showLoading = false
                        if (data.status === 200) {
                            that.$confirm("修改成功", "提示", {
                                confirmButtonText: '确定',
                                showClose: false,
                                showCancelButton: false,
                                center: true,
                                type: 'success'
                            }).then(function () {
                                that.$emit('saveStatus', true)
                            })
                        } else {
                            that.$confirm(data.data, "提示", {
                                confirmButtonText: '确定',
                                showClose: false,
                                showCancelButton: false,
                                center: true,
                                type: 'error'
                            })
                        }
                    }).catch(function (err) {
                        console.log(err)
                        that.showLoading = false
                        that.$confirm("网络错误", "提示", {
                            confirmButtonText: '确定',
                            showClose: false,
                            showCancelButton: false,
                            center: true,
                            type: 'error'
                        })
                })
            }
        }
    }
</script>

<style scoped>
    .categoryBox {
        width: 100%;
        height: 200px;
        text-align: center;
    }
    .btn /deep/ .el-form-item__content {
        margin-left: 0 !important;
    }
</style>