<template>
    <!--帖子审核修改-->
    <div class="imageBox" v-loading="showLoading">
        <el-form ref="form" :model="postDetail" label-width="80px">
            <div class="inputBox">
                <el-form-item label="内容">
                    <div>{{postDetail.text}}</div>
                </el-form-item>
                <el-form-item label="图片">
                    <div class="previewImageBox">
                        <a v-for="item in postDetail.resList"
                           :href="item.indexOf('http')==0?item:'http://file.baozouptu.intelimeditor.com/release/postImageUrl/'+item"
                           target="_blank">
                            <div>
                                <el-image
                                        style="width: 80px; height: 80px"
                                        :src="item.indexOf('http')==0?item:'http://file.baozouptu.intelimeditor.com/release/postImageUrl/'+item"></el-image>
                            </div>
                        </a>
                    </div>
                </el-form-item>

                <el-form-item label="审核状态">
                    <div>
                        <el-radio v-model="reviewStatus" label="0" border>未审核</el-radio>
                        <el-radio v-model="reviewStatus" label="1" border>审核通过</el-radio>
                        <el-radio v-model="reviewStatus" label="-1" border>审核不通过</el-radio>
                    </div>
                </el-form-item>
            </div>
            <el-form-item class="btn">
                <el-button type="primary" @click="save">保存</el-button>
                <el-button @click="cancel">取消</el-button>
            </el-form-item>
        </el-form>
        <el-dialog :title="dialogTitle"
                   @close="closeDialog"
                   top="5vh"
                   @opened="dialogOpen"
                   :visible.sync="showDialog"
                   :close-on-click-modal="false"
                   width="800px"
                   append-to-body
                   :destroy-on-close="true"
                   :fullscreen="false">
        </el-dialog>
    </div>
</template>

<script>

    export default {
        name: "PostAudit",
        props: ['postDetail', "categoryList", "isUpdate", "loadData"],
        components: {},
        watch: {
            loadData: function (flag) {
                if (flag) {
                    this.getDataInfo()
                }
            }
        },
        data() {
            return {
                dialogTitle: "",
                showDialog: false,
                showLoading: false,
                postCount: 0,
                templateData: {},
                templateDataList: [],
                secondCategoryId: 2,
                isTitle: false,
                isUpdateTemplateData: false,
                isAddImageData: false,
                isLoadDialog: false,
                templateType: [{
                    label: "图片",
                    value: 0
                }, {
                    label: "视频",
                    value: 1
                }],
                reviewStatus: "-2",
            }
        },
        methods: {
            cancel: function () {
                this.$emit('saveStatus', false)
            },
            selectChange: function (data) {
                //匹配三级id
                if (data !== undefined) {
                    if (data.length === 3) {
                        this.postDetail.categoryId = data[2]
                    }
                    if (data.length >= 2) {
                        this.secondCategoryId = data[1];
                    }
                }
            },
            /**
             * 显示错误信息弹框
             * @param msg 错误信息弹框
             */
            showErrorMessage: function (msg) {
                this.$confirm(msg, "提示", {
                    confirmButtonText: '确定',
                    showClose: false,
                    showCancelButton: false,
                    center: true,
                    type: 'error'
                })
            },
            update: function () {
                if (this.reviewStatus == this.postDetail.isChecked) {
                    this.$message('请修改审核状态后保存');
                    return
                }

                this.showLoading = true;
                const that = this;
                const params = {
                    "reviewStatus": this.reviewStatus,
                    "postId": this.postDetail.id
                }
                this.$request.put("/post/updatePostAuditStatus", params)
                    .then(function (data) {
                        that.showLoading = false
                        if (data.status === 200) {
                            that.$confirm("修改成功", "提示", {
                                confirmButtonText: '确定',
                                showClose: false,
                                showCancelButton: false,
                                center: true,
                                type: 'success'
                            }).then(function () {
                                that.$emit('saveStatus', true)
                            })
                        } else {
                            that.showErrorMessage(data.message)
                        }
                    }).catch(function (err) {
                    that.showLoading = false
                    that.$confirm("网络错误", "提示", {
                        confirmButtonText: '确定',
                        showClose: false,
                        showCancelButton: false,
                        center: true,
                        type: 'error'
                    })
                })
            },
            closeDialog: function () {
                this.templateData = {}
                this.isUpdateTemplateData = false
                this.isAddImageData = false
                this.isLoadDialog = false
            },
            /**
             * 保存上传
             * */
            save() {
                if (!this.isUpdate) {
                    this.addImage()
                } else {
                    this.update()
                }
            },
            /**
             * 数据保存状态
             * @param info
             */
            saveStatus: function (info) {
                if (info !== false) {
                    this.getDataInfo()
                }
                this.showDialog = false
            },
            getDataInfo: function () {
                console.log("loadData" + this.reviewStatus)
                this.reviewStatus = this.postDetail.isChecked + ""
                console.log("loadData2=" + this.reviewStatus)
            },
            dialogOpen: function () {
                this.isLoadDialog = true
            }
        },
        mounted() {
        }
    }
</script>

<style scoped>
    .imageBox {
        width: 100%;
        min-height: 300px;
    }

    .inputBox {
        max-height: 400px;
        overflow-y: scroll;
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

    .inputBox::-webkit-scrollbar {
        display: none;
    }

    .btn /deep/ .el-form-item__content {
        margin-left: 0 !important;
        text-align: center;
    }

    .input {
        width: 300px;
    }

    .previewImageBox {
        height: 200px;
        overflow-y: auto;
    }

    .operationBox {
        width: 100%;
    }
</style>
