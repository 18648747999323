<template>
  <div v-loading.fullscreen.lock="showLoading">
    <el-card class="box-card">
      当前会员情况
      <div class="box_operation">
        <el-row :gutter="20">
          <el-col :span="4">
            <el-card shadow="always" body-style="background-color: #3eb93b;color: #ffffff;">
              所有会员：<span style="color: #ffffff; font-weight: bold;">{{
                vipOpenRating.vipData && vipOpenRating.vipData.total_count }}</span>
            </el-card>
          </el-col>

          <el-col :span="4">
            <el-card shadow="always" body-style="background-color: #f37b1d;color: #ffffff;">
              终身会员：<span style="color: #ffffff; font-weight: bold;">{{ vipOpenRating.vipData.lifetime_count }}</span>
            </el-card>
          </el-col>

          <el-col :span="4">
            <el-card shadow="always" body-style="background-color: #f6c23e;color: #ffffff;">
              昨日新增会员：<span style="color: #ffffff; font-weight: bold;">{{ vipOpenRating.yesterday.count }}</span>
            </el-card>
          </el-col>

          <el-col :span="5">
            <el-card shadow="always" body-style="background-color: #1cc88a;color: #ffffff;">
              昨日新增终身会员：<span style="color: #ffffff; font-weight: bold;">{{ vipOpenRating.yesterday.lifetime_count
                }}</span>
            </el-card>
          </el-col>
        </el-row>
      </div>
      关键指标
      <div class="box_operation">
        <el-row :gutter="30">
          <el-col :span="6">
            <el-card shadow="always" body-style="background-color: #f6c23e;color: #ffffff;text-align: center;">
              日开通率（昨天）<div style="color: #ffffff; font-weight: bold;">
                {{ getOpenRating(vipOpenRating.yesterday.count, vipOpenRating.yesterday.active) }}</div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always" body-style="background-color: #1cc88a;color: #ffffff;text-align: center;">
              周开通率（最近一周）<div style="color: #ffffff; font-weight: bold;">
                {{ getOpenRating(vipOpenRating.last_week.count, vipOpenRating.last_week.active) }}</div>
            </el-card>
          </el-col>
          <el-col :span="6">
            <el-card shadow="always" body-style="background-color: #36b9cc;color: #ffffff;text-align: center;">
              月开通率（最近一个月）<div style="color: #ffffff; font-weight: bold;">
                {{ getOpenRating(vipOpenRating.last_month.count, vipOpenRating.last_month.active) }}</div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </el-card>
    <div class="tutorial_box">
      <div class="box_operation">
        <div class="input_box">
          <el-select v-model="vipOpenType" placeholder="请选择" @change="updateVipOpenType">
            <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

        <div class="input_box">
          <el-select v-model="vipGroupType" placeholder="请选择" @change="updateVipGroupType">
            <el-option v-for="item in vipGroupTypeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>

        <div class="input_box">
          <el-date-picker v-model="selectDate" type="daterange" align="right" unlink-panels format="yyyy-MM-dd"
            value-format='yyyy-MM-dd' default-value="2024" range-separator="至" start-placeholder="开始日期"
            end-placeholder="结束日期" :picker-options="pickerOptions" @change="changeDate">
          </el-date-picker>
        </div>

      <div class="input_box">
        <el-input placeholder="请输入尾号筛选名称" v-model="searchName" class="input-with-select"></el-input>
      </div>

      <div class="btn_box">
        <el-button type="success" @click="reset">尾号筛选</el-button>
      </div>
      </div>
      
      <div class="horizontal-container">
        <div class="horizontal-item">
          <h3 class="title_box">折线图</h3>
        </div>
        <div class="horizontal-item">
          <el-select v-model="chartShowType" placeholder="请选择" @change="updateChartShowType">
            <el-option v-for="item in chartShowTypeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="echart" id="mychart" :style="myChartStyle"></div>
      <div>
        <h3>详细数据</h3>
      </div>
      <div></div>
      <div class="data_box">
        <el-table class="table" @sort-change="sortChange" border ref="multiple" :cell-style="tableRowClassName"
          :data="vipStatisticList" height="600">
          <el-table-column prop="date" width="240" sortable align="center" label="日期">
          </el-table-column>
          <el-table-column prop="channel" align="center" width="120" label="渠道">
            <template slot-scope="scope">
              <p>{{ getAppChannel(scope.row) }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" width="80" label="支付方式">
            <template slot-scope="scope">
              <p>{{ getAppPayWay(scope.row) }}</p>
            </template>
          </el-table-column>
          <el-table-column align="center" width="80" label="版本">
            <template slot-scope="scope">
              <p>{{ getAppVersion(scope.row) }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="time" align="center" label="开通会员时间（天）">
          </el-table-column>
          <el-table-column prop="totalFee" align="center" label="会员价格">
          </el-table-column>
          <el-table-column prop="count" align="center" label="开通数量">
          </el-table-column>
          <el-table-column prop="activeUser" align="center" label="活跃用户数">
            <template v-slot:header='scope'>
              <span>
                活跃用户数
                <el-tooltip :aa="scope" class="item" effect="dark" placement="top-start">
                  <i class="el-icon-question"> </i>
                  <div style="width: 200px" slot="content">
                    友盟日活接口限制 <br />
                    每天：返回结果数量限制为60条，即最近60天 <br />
                    每周：返回结果数量限制为8条，即最近8周<br />
                    每月：返回结果数量限制为3条，即最近3个月
                  </div>
                </el-tooltip>
              </span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="开通率">
            <template slot-scope="scope">
              <p>{{ getVipOpenRating(scope.row) }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="totalCost" align="center" label="总会员收益">
          </el-table-column>
          <el-table-column align="center" label="总会员收益率">
            <template slot-scope="scope">
              <p>{{ getVipRevenueRating(scope.row) }}</p>
            </template>
          </el-table-column>
        </el-table>
        <div class="page">
          <el-pagination background :page-size="5" :current-page="pageNo" layout="prev, pager, next"
            :page-count="pageSum" @current-change="changePage">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";

export default {
  name: "vipReportManager",
  components: {},
  data: function () {
    return {
      vipInfos: {
        totalMembers: 1000,
        lifetimeMembers: 200,
        dailyActivationRate: '20%',
        weeklyActivationRate: '30%',
        monthlyActivationRate: '40%'
      },
      activeNames: ['1'],
      myChart: {},
      xData: [], //日期
      yData: [], //请求量日活数据
      myChartSeries: [], //广告数据
      myChartStyle: { float: "left", width: "100%", height: "400px" }, //图表样式
      showLoading: false,//是否显示弹框
      vipStatisticList: [],//所有数据列表
      //指标
      vipOpenRating: {
        "yesterday": {
          "count": 0,
          "lifetime_count": 0,
          "active_user": 1
        },
        "last_week": {
          "count": 0,
          "active_user": 1
        },
        "last_month": {
          "count": 0,
          "active_user": 1
        },
        "vipData": {
          "total_count": 0,
          "lifetime_count": 0
        }
      },
      appAdReportList: [],//单个APP数据列表
      vipOpenType: 0,
      vipGroupType: 0,
      appPlacement: "开屏",
      selectDate: [],
      pickerOptions: {
        // 时间不能大于当前时间
        disabledDate: (time) => {
          return time.getTime() > Date.now() - 8.64e7;
        },
        shortcuts: [{
          text: '最近三天',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近三个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            end.setTime(end.getTime() - 3600 * 1000 * 24)
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      chartShowType: 0,
      //折线图分类列表
      chartShowTypeList: [{
        id: 0,
        label: '会员开通数',
        value: 0
      }, {
        id: 1,
        label: '会员开通率（%）',
        value: 1
      }, {
        id: 2,
        label: '会员收益',
        value: 2
      }, {
        id: 3,
        label: '会员收益率（%）',
        value: 3
      },],
      //应用分类列表
      typeList: [{
        id: 0,
        label: '每天',
        value: 0
      }, {
        id: 1,
        label: '每周',
        value: 1
      }, {
        id: 2,
        label: '每月',
        value: 2,
      }],//应用分类列表
      vipGroupTypeList: [{
        id: 0,
        label: '默认',
        value: 0
      }, {
        id: 1,
        label: '会员时间',
        value: 1
      }, {
        id: 2,
        label: '会员时间+价格',
        value: 2,
      }, {
        id: 3,
        label: '渠道',
        value: 3,
      }, {
        id: 4,
        label: '版本',
        value: 4,
      }, {
        id: 5,
        label: '支付方式',
        value: 5,
      }],//会员分组类型列表
      pageNo: 1,
      pageCount: 20,
      pageSum: 1,
      searchName: "",
      searchKey: "",
      orderType: 2,
      showMore: -1,
      showMores: [
        {
          label: "全部",
          value: -1
        }, {
          label: "不显示更多",
          value: 0
        }, {
          label: "显示更多",
          value: 1
        }
      ],
      dialogTitle: "",
      selectMessage: {
        type: 1,
        title: '',
        description: '',
        fileList: [],
        jumpUrl: '',
        channel: ''
      },
      //铠甲广告统计
      kjResult: [],
      kjAllRevenue: 0,
      isUpdate: false,
      showDialog: false,
      idEndWith:"",
    }
  },
  methods: {
    convertToFloat(stringValue) {
      // 删除逗号
      stringValue = stringValue.replace(/,/g, '');
      // 转换成浮点数
      return parseFloat(stringValue);
    },
    getOpenRating: function (count, active) {
      let rating = (count / active * 100).toFixed(4);
      return `${rating}%=(${count}/${active})`;
    },
    setDefaultDate() {
      const cachedDate = localStorage.getItem('selectDate');
      if (cachedDate) {
        this.selectDate = JSON.parse(cachedDate);
      }
      console.log("selectDate 初始默认值 ", this.selectDate, cachedDate)
      console.log("selectDate 初始设置默认值 ", this.selectDate.length)
      if (this.selectDate.length > 0) {
        return
      }
      const end = new Date();
      const start = new Date();
      end.setTime(end.getTime() - 3600 * 1000 * 24)//7就是七天 14就是两个周 可改
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 31)//7就是七天 14就是两个周 可改
      // 转换日期格式为 "yyyy-MM-dd"
      const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
      //将值设置给插件绑定的数据
      this.selectDate.push(formatDate(end));
      this.selectDate.unshift(formatDate(start));
    },
    initActiveEcharts() {
      //遍历vipStatisticList集合
      this.xData = []
      this.yData = []
      this.myChartSeries = []
      this.kjResult = []

      const arrays = [];
      arrays.push({
        name: "会员开通数量",
        data: []
      });

      // 创建一个 Set 用于存储已添加过的日期
      const addedDates = new Set();
      // 创建一个 Map 用于存储日期和对应的数据对象数组
      const dateDataMap = new Map();

      console.log("chartShowType" + this.chartShowType + " vipOpenType=" + this.vipOpenType)
      this.reversedVipStatisticList = this.vipStatisticList.slice().reverse();
      // 对副本数组进行排序
      this.reversedVipStatisticList.forEach(item => {
        if (this.chartShowType == 1 && this.vipOpenType == 0 && item.activeUser > 0) {
          this.xData.push(item.date); // 假设xValue是x轴上的数据
          const ratio = (item.count / item.activeUser * 100).toFixed(4);
          arrays[0].name = "会员开通数量/用户活跃量"
          arrays[0].data.push(parseFloat(ratio))

          //TODO 修改分类，展示相同日期，不同分组的数据，便于折线图显示
          // if (!addedDates.has(item.date)) {
          //   this.xData.push(item.date); // 假设xValue是x轴上的数据
          //   addedDates.add(item.date);
          // }
          // if (this.vipGroupType == 0) {
          //   dateDataMap
          // } else if (this.vipGroupType == 1) {
          //   if (!dateDataMap.has(item.time+"")) {
          //     dateDataMap.set(item.time+"", [parseFloat(ratio)]);
          //   } else {
          //     dateDataMap.get(item.time+"").push(parseFloat(ratio));
          //   }
          // } else if (this.vipGroupType == 2) {
          //   if (!dateDataMap.has(item.date)) {
          //     dateDataMap.set(item.date, [{ date: item.date, name: "会员开通数量/用户活跃量", data: parseFloat(ratio) }]);
          //   } else {
          //     dateDataMap.get(item.date).push({ date: item.date, name: "会员开通数量/用户活跃量", data: parseFloat(ratio) });
          //   }
          // } else {

          //   arrays[0].name = "会员开通数量/用户活跃量"
          //   arrays[0].data.push(parseFloat(ratio))
          // }


        } else if (this.chartShowType == 1 && this.vipOpenType == 0) {
          //没有日活数据不处理
        } else if (this.chartShowType == 0){
          this.xData.push(item.date); // 假设xValue是x轴上的数据
          arrays[0].name = "会员开通数量"
          arrays[0].data.push(item.count)
        }else if (this.chartShowType == 2){
          this.xData.push(item.date); // 假设xValue是x轴上的数据
          arrays[0].name = "会员总收益"
          arrays[0].data.push(this.convertToFloat(item.totalCost));
        }else if (this.chartShowType == 3 && this.vipOpenType == 0 && item.activeUser > 0) {
          this.xData.push(item.date); // 假设xValue是x轴上的数据
          const ratio = (this.convertToFloat(item.totalCost) / item.activeUser * 100).toFixed(4);
          arrays[0].name = "会员总收益率"
          arrays[0].data.push(parseFloat(ratio))
        }
      });

      for (let i = 0; i < arrays.length; i++) {
        this.myChartSeries.push(
          {
            name: arrays[i].name,
            data: arrays[i].data,
            type: "line", // 类型设置为折线图
            smooth: true
          }
        )
      }
      const option = {
        xAxis: {
          data: this.xData
        },
        yAxis: {},
        tooltip: {
          trigger: 'axis', //坐标轴触发，主要在柱状图，折线图等会使用类目轴的图表中使用
          axisPointer: {// 坐标轴指示器，坐标轴触发有效
            type: 'shadow' // 默认为直线，可选为：'line' | 'shadow'
          }
        },
        series: this.myChartSeries
      };

      // 获取要绘制图表的 DOM 元素
      var chartDom = document.getElementById('mychart');
      // 获取已存在的图表实例
      var existingChart = echarts.getInstanceByDom(chartDom);
      // 如果已存在图表实例，则销毁它
      if (existingChart) {
        existingChart.dispose();
      }
      this.myChart = echarts.init(chartDom);
      this.myChart.setOption(option);
      //随着屏幕大小调节图表
      window.addEventListener("resize", () => {
        this.myChart.resize();
      });
    },
    showAdInfos(data) {
      this.$confirm(data, "广告数据", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'success'
      })
    },
    updateAdReport(date) {
      this.showLoading = true;
      const that = this;
      const params = {
        "date": date,
      }
      this.$request.post("/monitoring/updateAdReport", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      })
        .then(function (data) {
          that.showLoading = false
          console.log("返回结果", data)
          this.$confirm("更新成功", "提示", {
            confirmButtonText: '确定',
            showClose: false,
            showCancelButton: false,
            center: true,
            type: 'success'
          })
          that.getVipStatisticsList();
        }).catch(function (err) {
          console.log(err)
          that.showLoading = false
          that.showMsg("网络错误")
        })
    },
    tableRowClassName({ row, column, rowIndex, columnIndex }) {
      if (columnIndex == 1 || columnIndex == 4) {
        return 'background:	#FFF8DC;'
      } else if (columnIndex == 10 || columnIndex == 9 || columnIndex == 8) {
        return 'background:	#f0f9eb;'
      }
      return '';
    },
    //获取渠道
    getAppChannel: function (row) {
      if (row.channel === null) {
        return "未知"
      }
      return row.channel
    },
    //获取支付方式
    getAppPayWay: function (row) {
      if (row.payWay === "1") {
        return "支付宝"
      }
      return "微信"
    },
    //会员开通率
    getVipOpenRating: function (row) {
      if (row.activeUser === null || row.activeUser == 0) {
        return "-"
      }
      if (row.count === null) {
        return "-"
      }
      let rating = (row.count / row.activeUser * 100).toFixed(4);
      return `${rating}`;
    },
    //会员收益率
    getVipRevenueRating: function (row) {
      if (row.activeUser === null || row.activeUser == 0) {
        return "-"
      }
      if (row.totalCost === null) {
        return "-"
      }
      let rating = (this.convertToFloat(row.totalCost) / row.activeUser * 100).toFixed(4);
      console.log(" "+row.totalCost+" - "+ row.activeUser * 100 +" = "+rating)
      return `${rating}`;
    },
    //获取版本号
    getAppVersion: function (row) {
      if (row.version === null) {
        return "未知"
      }
      return row.version
    },
    //获取真实地址信息
    getRealUrl: function (url) {
      return 'http://file.baozouptu.intelimeditor.com/' + url;
    },
    updateVipOpenType: function () {
      console.log("vipOpenType change", this.vipOpenType);
      this.getVipStatisticsList();
    },
    updateVipGroupType: function () {
      console.log("vipGroupType change", this.vipGroupType);
      this.getVipStatisticsList();
    },
    updateChartShowType: function () {
      console.log("chartShowType", this.chartShowType);
      this.initActiveEcharts();
    },
    changeDate() {
      localStorage.setItem('selectDate', JSON.stringify(this.selectDate));
      this.getVipStatisticsList();
    },
    handleVipOpenData: function () {
    },
    //获取vip相关指标
    getVipOpenRate: function () {
      this.showLoading = true;
      // 先尝试从本地存储中获取缓存数据
      const cachedData = localStorage.getItem('getVipOpenRate1');
      if (cachedData) {
        console.log("读取缓存")
        this.vipOpenRating = JSON.parse(cachedData);
        this.showLoading = false; // 隐藏加载状态
        return; // 如果有缓存数据，直接返回，不再发起网络请求
      }
      console.log("直接请求")
      const that = this;
      const params = {
        "type": 1,
      }
      this.$request.post("/pay/vip/openRating", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      })
        .then(function (data) {
          that.showLoading = false
          console.log("返回结果", data)

          if (data.status === 200) {
            that.vipOpenRating = data.data;
            // 将获取的数据保存到本地存储中
            localStorage.setItem('getVipOpenRate', JSON.stringify(data.data));
          } else {
            that.showMsg(data.data)
          }
        }).catch(function (err) {
          console.log(err)
          that.showLoading = false
          that.showMsg("网络错误")
        })
    },
    getVipStatisticsList: function () {
      this.showLoading = true;
      // 先尝试从本地存储中获取缓存数据
      const cachedData = localStorage.getItem('getVipStatisticsList_' + this.vipOpenType);
      if (cachedData) {
        console.log("读取缓存")
        this.vipStatisticList = JSON.parse(cachedData);
        this.initActiveEcharts();
        this.showLoading = false; // 隐藏加载状态
        return; // 如果有缓存数据，直接返回，不再发起网络请求
      }
      console.log("直接请求")
      const that = this;
      const params = {
        "type": this.vipOpenType,
        "groupType": this.vipGroupType,
        "startDate": this.selectDate[0],
        "endDate": this.selectDate[1],
      }
      this.$request.post("/pay/vip/statistic", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      })
        .then(function (data) {
          that.showLoading = false
          console.log("返回结果", data)

          if (data.status === 200) {
            that.vipStatisticList = data.data
            // 将获取的数据保存到本地存储中
            localStorage.setItem('vipStatisticList_' + that.vipOpenType, JSON.stringify(data.data));
            that.initActiveEcharts();
          } else {
            that.showMsg(data.data)
          }
        }).catch(function (err) {
          console.log(err)
          that.showLoading = false
          that.showMsg("网络错误")
        })
    },
    /**
     * 显示错误消息
     * @param title
     */
    showMsg: function (title) {
      this.$confirm(title, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
    changePage: function (pageNo) {
      this.pageNo = pageNo;
      this.getVipStatisticsList()
    },
    search: function () {
      this.pageNo = 1;
      this.orderType = 2;
      this.getVipStatisticsList()
    },
    reset: function () {
      this.pageNo = 1;
      this.searchName = "";
      this.searchKey = "";
      this.showMore = -1;
      this.orderType = 2;
      this.getVipStatisticsList()
    },
    /**
     * 排序规则改变
     */
    sortChange: function ({ column, prop, order }) {
      switch (order) {
        case "ascending":
          //升序
          this.orderType = 1;
          break;
        case "descending":
          //升序
          this.orderType = 2;
          break;
        case null:
          //不排序
          this.orderType = 0;
          break;
      }
      this.pageNo = 1;//重置页码
      console.log("table", this.$refs.multiple.tableData)
      this.initActiveEcharts()
    },
    closeDialog: function () {
      this.selectMessage = {}
    },
    saveStatus: function (flag) {
      if (flag) {
        this.reset();
        this.getVipStatisticsList()
      }
      this.showDialog = false
    },
    addMessage: function () {
    },
    updateMessage: function (message) {
      if (message.url != null) {
        message.fileList = [{
          name: this.getRealUrl(message.url),
          url: this.getRealUrl(message.url)
        }]
      } else {
        message.fileList = []
      }
      if (message.videoUrl != null) {
        message.videoFile = [{
          name: this.getRealUrl(message.videoUrl),
          url: this.getRealUrl(message.videoUrl)
        }]
      } else {
        message.videoFile = []
      }

      this.dialogTitle = "修改消息"
      this.isUpdate = true
      this.showDialog = true
      this.selectMessage = message
    },
    delMessage: function (id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        cancelButtonClass: "messagebox-off-button-color",
        confirmButtonClass: "messagebox-ok-button-color"
      }).then(() => {
        this.del(id)
      }).catch(() => {
      });
    },
    /**
     * 删除一个图片
     * @param id
     */
    del: function (id) {
      this.requestNum++;
      this.showLoading = true;
      const that = this;
      const params = {
        "delete": [id]
      }
      this.$request.post("/comfyAI/deleteStyle", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      })
        .then(function (data) {
          that.showLoading = false
          if (data.status === 200) {
            that.$confirm("删除成功", "提示", {
              confirmButtonText: '确定',
              showClose: false,
              showCancelButton: false,
              center: true,
              type: 'success'
            }).then(function () {
              that.getVipStatisticsList()
            })
          } else {
            that.showMsg(data.data);
          }
        }).catch(function (err) {
          that.showLoading = false
          that.showMsg("网络错误");
        })
    }
  },
  mounted() {
    this.$bus.$emit("setMenuActiveIndex", "9-1");
    this.setDefaultDate();
    this.getVipOpenRate();
    this.getVipStatisticsList();
  }
}
</script>

<style scoped>
.tutorial_box {
  width: 100%;
  margin-top: 10px;
  background: white;
  min-height: 100%;
  border-radius: 5px;
  padding: 10px 20px;
}

.box_operation {
  width: 100%;
  padding: 20px 0;
}

.title_box {
  margin: 0 10px;
  margin-top: 10px;
}

.input_box {
  display: inline-block;
  margin: 0 10px;
  margin-top: 10px;
}

.btn_box {
  margin-top: 10px;
  height: 40px;
  margin-left: 20px;
  vertical-align: top;
  display: inline-block;
}

.input_box_select {
  width: 150px;
}

.data_box {
  width: 100%;
}

.preview_image {
  width: 80px;
  height: 80px;
}

.data_box /deep/ .el-table .warning-row {
  background: oldlace;
}

.data_box /deep/ .el-table .success-row {
  background: #f0f9eb;
}

.page {
  width: 100%;
  margin: 20px 0;
  text-align: center;
}

.horizontal-container {
  display: flex;
  flex-direction: row;
}

.horizontal-item {
  margin-right: 20px;
  /* 控制元素之间的间距 */
}

.table {
  /*position: fixed;*/
  height: 100%;
}

.card-body div {
  margin-bottom: 0.25rem;
}


.box-card {
  width: 100%;
  height: auto;
  border-radius: 8px;
}
</style>
