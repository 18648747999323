<template>
   <div class="box" v-loading="showLoading">
      <div class="option">
        <div class="option_title">{{imageCacheTime.configNote}}</div>
        <div class="option_value">
           <el-input v-model="imageCacheTime.configValue" placeholder="请输入内容" @input="changeValue(imageCacheTime)"></el-input>
        </div>
      </div>
     <div class="option">
       <div class="option_title">{{imageServerUrl.configNote}}</div>
       <div class="option_value">
         <el-input v-model="imageServerUrl.configValue" placeholder="请输入内容" @input="changeValue(imageServerUrl)"></el-input>
       </div>
     </div>
     <div class="option">
       <div class="option_title">{{userShareUrl.configNote}}</div>
       <div class="option_value">
         <el-input v-model="userShareUrl.configValue" placeholder="请输入内容" @input="changeValue(userShareUrl)"></el-input>
       </div>
     </div>
<!--     <div class="option">-->

<!--     </div>-->
<!--     <div class="option">-->

<!--     </div>-->
     <div class="btnBox">
        <el-button type="primary" @click="save">保存</el-button>
     </div>
   </div>
</template>

<script>
export default {
  name: "AppSetting",
  data:function (){
     return{
         showLoading:false,
         configs:[],
         updateConfig:[]//修改了的配置列表
     }
  },
  computed:{
      //图片缓存配置
      imageCacheTime:function (){
        return this.getConfig("image_cache_time")
      },
      //图片地址配置
      imageServerUrl:function (){
        return this.getConfig("image_server_url")
      },
      //图片地址配置
     userShareUrl:function (){
        return this.getConfig("user_share_url")
      }
  },
  methods:{
    /**
     * 获取全部配置信息
     */
     getConfigs:function (){
       let param = new FormData()
       let that = this;
       that.showLoading = true
       this.$request.post("/config/getSystemConfig",param)
           .then(function (data) {
             if (data.status === 200){
               that.configs = data.data
             }else {
               that.showMessage(data.message,"error")
             }
             that.showLoading = false
           }).catch(function (err) {
             that.showLoading = false
             that.showMessage("网络错误","error")
           })
     },
      /**
       * 根据配置名称获取配置信息
       * @param configName
       */
     getConfig:function (configName){
        for (let i = 0; i < this.configs.length; i++){
          if (this.configs[i].configName === configName){
            return this.configs[i];
          }
        }
        return {}
     },
    /**
     * 修改了值回调
     * @param config
     */
     changeValue:function (config){
       let  hasAdd = false
      for (let i = 0; i < this.updateConfig.length; i++){
        if (this.updateConfig[i].configName === config.configName){
           hasAdd = true;
        }
      }
      if (!hasAdd){
        this.updateConfig.push(config)
      }
     },
    /**
     * 保存数据
     */
    save:function (){
      let that = this;
      let postSize = 0;
      that.showLoading = true
       this.updateConfig.forEach(it=>{
         let param = new FormData()
         param.append("configName",it.configName)
         param.append("configValue",it.configValue)
         this.$request.post("/config/updateSystemConfig",param)
             .then(function (data) {
               console.log(data)
               postSize++;
               if (postSize === that.updateConfig.length){
                 that.showLoading = false
                 that.showMessage("修改成功","success")
               }
             }).catch(function (err) {
                 postSize++;
                 if (postSize === that.updateConfig.length){
                   that.showLoading = false
                   that.showMessage("修改成功","success")
                 }
           })
       })
     },
     showMessage:function (msg,type){
       this.$confirm(msg, "提示", {
         confirmButtonText: '确定',
         showClose:false,
         showCancelButton:false,
         center:true,
         type: type
       })
     }
  },
  mounted() {
    this.$bus.$emit("setMenuActiveIndex","3-1-1");
    this.getConfigs()
  }
}
</script>

<style scoped>
  .box{
    width: 100%;
    height: 100%;
    padding: 30px;
    position: relative;
  }
  .option{
    display: inline-block;
    width: 100%;
    height: 40px;
    vertical-align: top;
    margin-top: 20px;
  }
  .option_title{
    vertical-align: top;
    display: inline-block;
    width: 15%;
    line-height: 40px;
    margin-right: 40px;
    margin-left: 40px;
    text-align: right;
  }
  .option_value{
    vertical-align: top;
    display: inline-block;
    width: 60%;
  }
  .box  .el-menu /deep/{
    min-height: 100%;
  }
  .btnBox{
    position: absolute;
    bottom: 30px;
    left: calc(50% - 100px);
    width: 200px;
    height: 30px;
    text-align: center;
  }
</style>