<template>
    <div class="box">
        <el-container class="container_box">
            <el-header>
                <IndexTop/>
            </el-header>
            <el-container style="margin-top: 10px;" class="container_box2">
                <el-aside width="200px">
                    <Menu/>
                </el-aside>
                <el-container>
                    <el-main>
                        <div class="main">
                            <router-view></router-view>
                        </div>
                    </el-main>

                    <!--                   <el-footer>Footer</el-footer>-->
                </el-container>
            </el-container>
        </el-container>
    </div>
</template>

<script>
    import IndexTop from "./IndexTop";
    import Menu from "./Menu";

    export default {
        name: "MainPage",
        components: {
            IndexTop,
            Menu
        }
    }
</script>

<style scoped>
    .box {
        width: 100%;
        height: 100%;
        max-width: 100%;
        background: rgba(255, 255, 255, 0.8);
    }

    .el-header /deep/ {
        padding: 0;
        height: 60px !important;
        border-bottom: 1px solid #c4cfd9;
    }

    .container_box /deep/ {
        height: calc(100%) !important;
        /*max-height: 100% !important;*/
        margin-top: 0 !important;
        /*height: calc(100% - 80px) !important;*/
    }

    .el-main /deep/ {
        padding: 0;
    }

    .el-aside /deep/ {
        overflow: scroll;
        height: calc(100% - 80px)
    }

    .el-aside::-webkit-scrollbar /deep/ {
        display: none;
    }

    .container_box2 {
        height: calc(100% - 90px)
    }

    .main {
        padding: 10px;
        width: 100%;
        height: 100%;
        background: rgb(240, 240, 240);
        overflow: scroll;

    }
</style>
