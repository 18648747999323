<template>
  <div>
    <!-- 上图部分 -->
    <div class="image_box" style="background: #FAFAFA;margin-bottom: 20px">
      <el-image class="no-select" v-if="resList.length > 0"
                :src="imageBaseUrl + 'postImageUrl/' + resList[0]"></el-image>
      <div v-else class="loading-skeleton">加载异常</div>
    </div>
    <!-- 下文部分 -->
    <div class="content-container" v-if="commentResList && commentResList.length > 0">
      <span>热门评论</span>
      <div class="comment-image">
        <div class="block no-select" v-for="res in commentResList" :key="res">
          <el-image :src="imageBaseUrl + 'postImageUrl/' + res"></el-image>
        </div>
      </div>
    </div>

    <div class="content-container">
      <div class="tag-group" v-if="tagList && tagList.length > 0">
        <div style="margin-bottom: 10px;">
          <span style="font-size: 15px;">标签：</span>
        </div>

        <el-tag
            v-for="item in tagList"
            :key="item"
            :type='success'
            effect="dark"
            style="margin-right: 10px;margin-bottom: 10px;background-color: #8AD594;
    border-color: #8AD594;"
        >
          {{ item }}
        </el-tag>
      </div>

      <el-button style="width: 100%;padding: 18px;font-size: 20px; background-color: #FFAD2E;border-color:#FFAD2E;
      box-shadow: 2px 2px 8px rgba(0, 0, 0, .4), -2px -2px 8px rgba(255, 255, 255, .9);" type="primary"
                 @click="openApp">
        一键做同款
      </el-button>
    </div>
  </div>
</template>

<script>

const baseUrl = process.env.VUE_APP_IMAGE_HOST;

export default {
  data() {
    return {
      pid: null,
      resList: [],
      tagList: [],
      commentResList: [],
      imageBaseUrl: baseUrl,//域名地址
    };
  },
  methods: {
    //防止右键点击
    preventRightClickOnImages() {
      document.addEventListener('contextmenu', event => {
        if (event.target.tagName === 'IMG') {
          event.preventDefault();
        }
      });
    },
    openApp: function () {
      console.log("openApp " + this.pid)
      if (this.pid == null) {
        return
      }
      try {
        window.location.href = 'https://a.app.qq.com/o/simple.jsp?pkgname=a.baozouptu&android_schema=baozouptu%3A%2F%2Fptu%2Ftemplate%3Fid%3D' + this.pid;
      } catch (e) {
        console.log(e)
      }
    },
    processImageUrl(url) {
      // 如果 URL 不以 .gif 结尾，则拼接处理字符串，GIF加水印后会变成静态图，所以GIF不加水印
      if (!url.endsWith('.gif')) {
        return url + '?x-oss-process=style/share_image';
      } else {
        return url + '?x-oss-process=style/gif_image';
      }
    }
  },
  mounted() {
    // 获取URL参数并将其保存到组件数据中
    this.pid = this.$route.query.pid;
    const list = this.$route.query.resList;
    const tag = this.$route.query.tag;
    this.tagList = tag.split("-")
    // 判断是否存在并分割 list 字符串
    if (list) {
      this.resList = list.split(",").map(item => this.processImageUrl(item));
    }
    // 从 resList 中提取 commentResList，同时处理 URL
    this.commentResList = this.resList.slice(1);
    //防止用户右键查看
    this.preventRightClickOnImages();
  }
}

</script>

<style scoped>
/* 可以在这里添加一些样式来美化评论图片列表 */
.no-select {
  -webkit-user-select: none; /* Chrome/Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE10+ */
  user-select: none; /* 标准语法 */
  pointer-events: none; /* 禁止鼠标事件 */
}

.image_box {
  width: 100%;
  min-height: 100%;
  background: white;
  border-radius: 5px;
  display: flex;
  justify-content: center; /* 水平居中 */
}

/* 添加样式以美化页面 */
.image-container {
  text-align: center;
  padding: 20px;
}

.content-container {
  padding: 16px;
}

.error-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.comment-image {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 三列 */
  grid-gap: 2px; /* 间隔 */
}

.block {
  text-align: center;
  padding: 2px;
}
</style>