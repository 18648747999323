//axios相关配置项
import axios from "axios";
import context from '../main.js'

const requests = axios.create({
    //配置网络接口访问地址
    baseURL: process.env.VUE_APP_HOST,
    method: "POST",
    timeout: 30000,//超时时间30秒
    maxRedirects: 3,//设置重定向次数
    headers: {
        'X-Custom-Header': 'foobar',
    },
})
// 错误处理函数
const err = (error) => {
    console.log(error)
    return Promise.reject(error.response.data.message)
}
requests.interceptors.request.use(function (config) {
    //添加token参数
    const userInfo = JSON.parse(sessionStorage.getItem(process.env.VUE_APP_USER_KEY))
    if (userInfo != null) {
        try {
            //请求头里面加token
            config.headers.token = userInfo.token;
        } catch (e) {
            console.log("err =" + e.message)
        }
    }
    return config
})


// response interceptor（接收拦截器）
requests.interceptors.response.use((response) => {
    const res = response.data
    if (res.status === 90002) {
        //登录已经过期
        // 跳转到 B 页面
        context.$router.push('/login')
    }
    return res
}, err)

export default requests