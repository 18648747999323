<template>
  <div class="imageBox" v-loading.fullscreen.lock="showLoading">
    <el-form ref="form" :model="exception" label-width="120px">
      <el-form-item label="异常名：" class="detailBox" >
<!--        <el-input v-model="exception.name" class="input"></el-input>-->
        <label>{{exception.name}}</label>
      </el-form-item>
      <el-form-item label="产生地址：" class="detailBox">
       <label>{{exception.api}}</label>
      </el-form-item>
      <el-form-item label="异常数量：" class="detailBox" >
        <label>{{exception.count}}</label>
      </el-form-item>
      <el-form-item label="异常时间：" class="detailBox" >
        <label>{{exception.time}}</label>
      </el-form-item>

      <el-form-item label="请求参数：" class="detailBox" >
        <label>{{exception.params}}</label>
      </el-form-item>
      <el-form-item label="处理状态：" class="detailBox" >
        <el-select v-model="exception.status" placeholder="请选择" @change="changeStatus(exception)">
          <el-option
              v-for="item in statusOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="异常详情：">
        <label style="font-size:14px;white-space: pre-line;">
          {{exception.message}}
        </label>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: "ExceptionInfoDetail",
  props: ['exception'],
  data:function (){
    return{
      showLoading:false,
      statusOptions: [
        {
          value: 1,
          label: "未解决"
        },
        {
          value: 2,
          label: "已解决"
        },
        {
          value: 3,
          label: "忽略"
        },
      ],
    }
  },
  methods:{
    /**
     * 修改异常状态
     * @param id
     * @param status
     */
    changeStatus:function (exception){
      this.showLoading = true;
      const that = this;
      const params = new URLSearchParams()
      params.append("id",exception.id);
      params.append("status",exception.status)
      //此处应该调用后台接口
      this.$request.post("/server/exception/updateExceptionInfoStatus", params)
          .then(function (data) {
            that.showLoading = false;
            if (data.status !== 200) {
              that.showError(data.data)
            }
          }).catch(function (err) {
        that.showLoading = false;
        that.showError("网络错误")
      })
    },
    showErrorMessage:function (msg){
      this.$confirm(msg, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
  }
}
</script>

<style scoped>
.imageBox {
  width: 100%;
  min-height: 300px;
}
.btn /deep/ .el-form-item__content {
  margin-left: 0 !important;
  text-align: center;
}
.detailBox{
  display: inline-block;
  width: 50%;
  vertical-align:top;
}
.input{
  width: 80%;
}
</style>