<template>
    <!--    图片添加修改-->
    <div class="imageBox" v-loading="showLoading">
        <el-form ref="form" :model="font" label-width="120px">
            <el-form-item label="文件名" >
                <el-input v-model="font.name" class="input" @input="drawFontPreview"></el-input>
            </el-form-item>
            <el-form-item label="是否支持英文" v-if="!isTemplateInto">
                <el-select v-model="font.supportEs" placeholder="请选择是否支持英文">
                    <el-option
                            v-for="item in selectSupportEs"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="是否是VIP可用" v-if="!isTemplateInto">
                <el-select v-model="font.isVip" placeholder="请选择是否是VIP可用">
                    <el-option
                            v-for="item in selectVips"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
          <el-form-item label="字体预览:" v-show="isShowFontPreview">
            <div class="font_preview">
              <canvas id="myCanvas"></canvas>
            </div>
          </el-form-item>
          <el-form-item label="字体预览:" v-show="!isShowFontPreview && isUpdate">
            <div class="font_preview">
              <img :src="font.previewImageUrl">
            </div>
          </el-form-item>
            <el-form-item label="上传文件">
                <el-upload
                        class="upload-demo"
                        action="none"
                        :file-list="font.fileList"
                        :on-exceed="imageCountOut"
                        :on-remove="handleRemove"
                        :on-change="fileChange"
                        :auto-upload="false"
                        :limit="1">
                    <el-button size="small" type="primary">点击上传</el-button>
                    <div slot="tip" class="el-upload__tip">只能上传一个文件，且不超过50MB</div>
                </el-upload>
            </el-form-item>
            <el-form-item class="btn">
                <el-button type="primary" @click="save">保存</el-button>
                <el-button @click="cancel">取消</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
    export default {
        name: "FontEdit",
        props: ['font',"isUpdate","isTemplateInto"],
        data:function () {
            return{
                showLoading:false,
                fontName:"",
                selectSupportEs:[
                    {
                        label:"不支持英文",
                        value:0
                    },{
                        label:"支持英文",
                        value:1
                    }
                ],
                selectVips:[
                    {
                        label:"不是VIP",
                        value:0
                    },
                    {
                        label:"VIP可用",
                        value:1
                    },
                ],
                isShowFontPreview:false,
                fontImagePreview:null//字体预览文件
            }
        },
        methods:{
            imageCountOut: function () {
                this.showErrorMessage("只能选择一个文件")
            },
            /**
             * 图片移除回调
             * @param file
             * @param fileList
             */
            handleRemove: function (file, fileList) {
               this.font.file = null
               this.isShowFontPreview = false
            },
            /**
             * 图片选择回调
             * @param file
             * @param fileList
             */
            fileChange: function (file, fileList) {
                this.isShowFontPreview = true
                this.font.file = file.raw
                //选择完成后，进行绘制
                this.drawFontPreview()
            },
            /**
             * 显示错误信息弹框
             * @param msg 错误信息弹框
             */
            showErrorMessage: function (msg) {
                this.$confirm(msg, "提示", {
                    confirmButtonText: '确定',
                    showClose: false,
                    showCancelButton: false,
                    center: true,
                    type: 'error'
                })
            },
            cancel: function () {
                this.$emit('saveStatus', false)
            },
            /**
             * 保存上传
             * */
            save() {
                if (!this.isUpdate){
                    this.addFont()
                }else {
                     this.updateFont()
                }
            },
            addFont:function () {
                if (this.font.name === undefined || this.font.name === ""){
                    this.showErrorMessage("请输入字体名称")
                    return
                }
                if (this.font.supportEs === undefined && !this.isTemplateInto) {
                    this.showErrorMessage("请选择字体是否支持英文")
                    return
                }
                if (this.font.isVip === undefined && !this.isTemplateInto){
                    this.showErrorMessage("请选择字体是否是VIP可用" )
                    return
                }
                if (this.font.file === undefined || this.font.file == null){
                    this.showErrorMessage("请选择一个字体文件")
                    return
                }
                this.fontImagePreview = this.dataURLtoFile();
                this.showLoading = true;
                const that = this;
                const params = new FormData()
                params.append("file", this.font.file)
                params.append("previewFile", this.fontImagePreview)
                params.append("fontName", this.font.name)

                if (this.isTemplateInto === true){
                  // params.append("isTemplateFont", "0")
                  params.append("supportEs", "1")
                  params.append("isVip","0")
                }else {
                  params.append("supportEs", this.font.supportEs)
                  params.append("isVip", this.font.isVip)
                }
                this.$request.post("/font/addFont", params)
                    .then(function (data) {
                        that.showLoading = false
                        if (data.status === 200) {
                            that.$confirm("添加成功", "提示", {
                                confirmButtonText: '确定',
                                showClose: false,
                                showCancelButton: false,
                                center: true,
                                type: 'success'
                            }).then(function () {
                                that.$emit('saveStatus', true)
                            })
                        } else {
                            that.showErrorMessage(data.message)
                        }
                    }).catch(function (err) {
                        that.showLoading = false
                        that.showErrorMessage("网络错误")
                })
            },
            updateFont:function () {
                if (this.font.name === undefined || this.font.name === ""){
                    this.showErrorMessage("请输入字体名称")
                    return
                }
                if (this.font.supportEs === undefined) {
                    this.showErrorMessage("请选择字体是否支持英文")
                    return
                }
                if (this.font.isVip === undefined){
                    this.showErrorMessage("请选择字体是否是VIP可用")
                    return
                }

                this.showLoading = true;
                const that = this;
                const params = new FormData()
                if (this.font.file !== undefined && null !== this.font.file){
                    params.append("file", this.font.file)
                }
                this.fontImagePreview = this.dataURLtoFile();
                if (this.fontImagePreview !== undefined && null !== this.fontImagePreview){
                  params.append("previewFile", this.fontImagePreview)
                }
                params.append("id", this.font.id)
                params.append("fontName", this.font.name)
                params.append("supportEs", this.font.supportEs)
                params.append("isVip", this.font.isVip)
                this.$request.post("/font/updateFont", params)
                    .then(function (data) {
                        that.showLoading = false
                        if (data.status === 200) {
                            that.$confirm("修改成功", "提示", {
                                confirmButtonText: '确定',
                                showClose: false,
                                showCancelButton: false,
                                center: true,
                                type: 'success'
                            }).then(function () {
                                that.$emit('saveStatus', true)
                            })
                        } else {
                            that.showErrorMessage(data.data)
                        }
                    }).catch(function (err) {
                    that.showLoading = false
                    that.showErrorMessage("网络错误")
                })
            },
            /**
             * 绘制字体预览
             */
            drawFontPreview:function (){
              //创建缓存URL
              const selectedFile = this.font.file;
              let url = null;
              let binaryData = [];
              binaryData.push(selectedFile);
              if (window.createObjcectURL !== undefined) {
                url =  window.URL.createObjectURL(new Blob(binaryData));

              } else if (window.URL !== undefined) {
                url =  window.URL.createObjectURL(new Blob(binaryData));

              } else if (window.webkitURL !== undefined) {
                url =  window.URL.createObjectURL(new Blob(binaryData));

              }
              //添加样式文件
              this.addCSS('@font-face { font-family:"fontPreview";src: url("'+url+'"); ')
              //延时绘制，等待数据读取完成，100毫秒应该可以读取完成
              const that = this
              setTimeout(function (){
                const canvas = document.querySelector('#myCanvas')
                canvas.width=100;
                canvas.height=40;
                const ctx = canvas.getContext('2d');
                ctx.clearRect(0, 0,100, 50);
                ctx.font = '25px fontPreview';
                ctx.fillStyle='#000000';
                ctx.textAlign = "center";
                ctx.textBaseline="middle"
                if (that.font.name === undefined || that.font.name === ""){
                  ctx.fillText("文字",50,20,100);
                }else {
                  ctx.fillText(that.font.name,50,20,100);
                }
              },200)
            },
          /**
           * 添加字体的样式
           * @param cssText
           */
          addCSS:function addCSS(cssText){
            const style = document.createElement('style'),  //创建一个style元素
                head = document.head || document.getElementsByTagName('head')[0]; //获取head元素
            style.id = "fontPreview"
            style.type = 'text/css'; //这里必须显示设置style元素的type属性为text/css，否则在ie中不起作用
            if(style.styleSheet){ //IE
              var func = function(){
                try{ //防止IE中stylesheet数量超过限制而发生错误
                  style.styleSheet.cssText = cssText;
                }catch(e){

                }
              }
              //如果当前styleSheet还不能用，则放到异步中则行
              if(style.styleSheet.disabled){
                setTimeout(func,10);
              }else{
                func();
              }
            }else{ //w3c
              //w3c浏览器中只要创建文本节点插入到style元素中就行了
              var textNode = document.createTextNode(cssText);
              style.appendChild(textNode);
              //移除之前添加的元素
              const node = document.getElementById("fontPreview")
              if (node != null){
                node.remove()
              }
            }
            head.appendChild(style); //把创建的style元素插入到head中
          },
          // 将canvas转换成file对象
          dataURLtoFile (filename = 'file') {
            const canvas = document.querySelector('#myCanvas')
            const  dataurl = canvas.toDataURL('image/png')
            let arr = dataurl.split(',')
            let mime = arr[0].match(/:(.*?);/)[1]
            let suffix = mime.split('/')[1]
            let bstr = atob(arr[1])
            let n = bstr.length
            let u8arr = new Uint8Array(n)
            while (n--) {
              u8arr[n] = bstr.charCodeAt(n)
            }
            return new File([u8arr], `${filename}.${suffix}`, {type: mime})
          }
        },
    }
</script>

<style scoped>
    .imageBox {
        width: 100%;
        min-height: 300px;
    }
    .btn /deep/ .el-form-item__content {
        margin-left: 0 !important;
        text-align: center;
    }
    .input{
        width: 300px;
    }
    .font_preview{
      width: 200px;
      height: 40px;
      font-family: fontPreview;
    }
    .font_preview canvas,.font_preview img{
      width: 100px;
      height: 40px;
    }
</style>