<template>
  <div class="image_box" v-loading.fullscreen.lock="showLoading">
    <div class="box_operation">
      <!--操作列表-->
      <div class="input_box" style="width: 300px">
        <el-input placeholder="输入文件路径" v-model="filePath" class="input-with-select"></el-input>
      </div>
      <div class="btn_box">
        <el-button type="success" @click="refreshCDNFile">刷新cdn</el-button>
        <el-button type="warning" @click="delFile">删除文件</el-button>
      </div>
    </div>

    <div>
      <div class="text-group">
        <el-button type="primary" @click="uploadFile">上传到服务器</el-button>
      </div>
      <div class="text-group">
        上传本地文件到oss，默认在release/other文件夹下
      </div>
      <div class="btn_box">
        <el-switch
            v-model="uploadSupportPath"
            active-text="支持自定义文件名">
        </el-switch>
      </div>
      <div class="btn_box">
        <el-upload
            class="upload-demo"
            drag
            action="none"
            :file-list="fileList"
            :on-remove="handleRemove"
            :on-change="fileChange"
            :auto-upload="false"
            :limit="1">
          <i class="el-icon-upload"></i>
          <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
        </el-upload>
      </div>

    </div>

  </div>
</template>

<script>

const baseUrl = process.env.VUE_APP_IMAGE_HOST;
export default {
  name: "fileManager",
  data: function () {
    console.log("rotuer.base =" + baseUrl)
    return {
      dialogTitle: "",//弹框标题
      showLoading: false,//是否显示刷新界面
      showDialog: false,//是否显示弹框
      postList: [],//帖子列表
      hotOrderType: 0,//热度排序规则 0:不排序 1:升序 2:降序
      timeOrderType: 0,//上传时间排序规则 0:不排序 1:升序 2:降序
      updateTimeOrderType: 0,//更新时间排序规则 0:不排序 1:升序 2:降序
      hasTemplateDataOrderType: 0,//是否有操作数据排序 0:不排序 1:升序 2:降序
      searchKey: "",//图片名称搜索关键字
      searchPostId: "",//搜索关键字
      postCategory: "all",//图片名称搜索关键字
      pageNo: 1,//当前页面
      pageSum: 1,//总页码
      pageCount: 200,//每页显示数据量
      startTime: '',
      endTime: "",
      delImageIds: [],
      loadDialogData: false,
      filePath: "",
      fileList: [],
      file: null,
      reviewStatus: "all",//审核状态
      imageBaseUrl: baseUrl,//审核状态
      uploadSupportPath: false,
    }
  },
  methods: {
    /**
     * 图片移除回调
     * @param file
     */
    handleRemove: function (file) {
      this.file = null
      this.fileList = []
      this.isShowFontPreview = false
    },
    /**
     * 图片选择回调
     * @param file
     */
    fileChange: function (file, fileList) {
      this.file = file.raw
      this.fileList = fileList
    },
    refreshCDNFile: function () {
      this.showLoading = true;
      const that = this;
      const params = new FormData()
      console.log(this.filePath)
      if (this.filePath === undefined || this.filePath.length === 0) {
        this.showErrorMessage("请输入正确的路径")
        this.showLoading = false;
        return
      }
      params.append("url", this.filePath)
      this.$request.post("/file/refreshCdn", params)
          .then(function (data) {
            console.log("data = " + JSON.stringify(data))
            that.hideLoading()
            if (data.status === 200) {
              that.$confirm(data.message, "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              })
            } else {
              that.$confirm(data.message, "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'error'
              })
            }
          }).catch(function (err) {
        console.log("请求结果返回 false =" + err.message)
        that.hideLoading()
        that.$confirm("网络错误", "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },
    /**
     * 删除当前文件
     * */
    deleteFile: function () {
      if (this.filePath === undefined || this.filePath.length === 0) {
        this.showErrorMessage("请输入正确的路径")
        this.showLoading = false;
        return
      }
      this.showLoading = true;
      const that = this;
      const params = new FormData()
      params.append("filePath", this.filePath)
      this.$request.post("/file/delete", params)
          .then(function (data) {
            console.log("data = " + JSON.stringify(data))
            that.hideLoading()
            if (data.status === 200) {
              that.$confirm(data.message, "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              })
            } else {
              that.$confirm(data.message, "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'error'
              })
            }
          }).catch(function (err) {
        console.log("请求结果返回 false =" + err.message)
        that.hideLoading()
        that.$confirm("网络错误", "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },
    uploadFile: function () {
      this.showLoading = true;
      this.requestNum++;
      const that = this;
      if (this.file === undefined || this.file == null) {
        this.showErrorMessage("请选择一个文件")
        return
      }
      const params = new FormData()
      params.append("file", this.file)
      if (this.uploadSupportPath && this.filePath && this.filePath.length > 0) {
        params.append("absoluteFilePath", this.filePath)
      }
      this.$request.post("/file/upload", params)
          .then(function (data) {
            console.log("data = " + JSON.stringify(data))
            that.hideLoading()
            if (data.status === 200) {
              that.$confirm(data.message, "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              })
            } else {
              that.$confirm(data.message, "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'error'
              })
            }
          }).catch(function (err) {
        console.log("请求结果返回 false =" + err.message)
        that.hideLoading()
        that.$confirm("网络错误", "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },
    /**
     * 调接判断隐藏加载界面
     * */
    hideLoading: function () {
      this.showLoading = false
    },
    /**
     * 弹框关闭时
     */
    closeDialog: function () {
      this.selectPostInfo = {}
      this.loadDialogData = false;
    },
    /**
     * 删除某个图片
     */
    delFile: function () {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        cancelButtonClass: "messagebox-off-button-color",
        confirmButtonClass: "messagebox-ok-button-color"
      }).then(() => {
        this.deleteFile()
      }).catch(() => {
      });
    },
    /**
     * 单行点击，选中或者不选中
     */
    toggleSelection(row) {
      this.$refs.multipleTable.toggleRowSelection(row);
    },
    /**
     * 多选回调
     * @param data 选中的对象数据
     */
    selectAllChange: function (data) {
      //清空数组
      this.delImageIds = new Array();
      //遍历选中的重新赋值
      data.forEach((item) => {
        this.delImageIds.push(item.id)
      })
    },
    showError: function (msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
    dialogOpen: function () {
      this.loadDialogData = true;
    },
    open: function () {
      this.showDialogtoo = true
    },
    /**
     * 显示错误信息弹框
     * @param msg 错误信息弹框
     */
    showErrorMessage: function (msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
  },
  mounted: function () {
    this.$bus.$emit("setMenuActiveIndex", "4-1");
  },
}
</script>

<style scoped>
.image_box {
  width: 100%;
  min-height: 100%;
  background: white;
  border-radius: 5px;
  padding: 10px 20px;
}

.box_operation {
  width: 100%;
  padding: 10px 0;
}

.input_box {
  display: inline-block;
  width: 200px;
  margin: 0 10px;
  margin-top: 10px;
}

.input_box /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 90%;
  margin: 0 auto;
}

.btn_box {
  height: 40px;
  margin-top: 10px;
  margin: 10px;
  vertical-align: top;
  display: inline-block;
}

.input_box_select {
  width: 150px;
}

.data_box {
  width: 100%;

}

.preview_image {
  width: 120px;
  height: 120px;
}

.data_box /deep/ .el-table .warning-row {
  background: oldlace;
}

.data_box /deep/ .el-table .success-row {
  background: #f0f9eb;
}

.page {
  width: 100%;
  margin: 20px 0;
  text-align: center;
}

.table {
  /*position: fixed;*/
  height: 100%;
}

.btn /deep/ .el-form-item__content {
  margin-left: 0 !important;
  text-align: center;
}
</style>
