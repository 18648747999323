<template>
  <div class="image_box" v-loading.fullscreen.lock="showLoading">
    <div class="box_operation">

      <!--操作列表-->
      <div class="btn_box" style="width: 400px">
        <el-input placeholder="通知邮箱地址" v-model="notifyEmail" class="input-with-select">
          <template slot="prepend">邮箱通知地址:</template>
        </el-input>
      </div>
      <div class="btn_box">
        <el-button type="primary" @click="fetchMonitoringStatus">实时检查</el-button>
      </div>
      <div class="btn_box">
        <el-button type="primary">上一次检测时间：{{ formatDate(lastCheckTime) }}</el-button>
      </div>
      <div class="btn_box">
        <el-button type="primary">下一次检测时间：{{ formatDate(lastCheckTime + checkInterval) }}</el-button>
      </div>

    </div>

    <div class="data_box">
      <el-table
          ref="multipleTable"
          class="table"
          :data="miniGameList"
          style="width: 100%">
        <el-table-column
            fixed
            align="center"
            prop="id"
            label="id"
            width="60"/>
        <el-table-column
            fixed
            align="left"
            prop="title"
            label="标题"
            width="200"/>

        <el-table-column
            align="left"
            label="链接"
            prop="url">
          <template slot-scope="scope">
            <el-link :type="scope.row.status >= 0 ? ( scope.row.status === '2' ? 'warning' : 'info'):'danger'"
                     :underline="false"
                     :href="scope.row.url" target="_blank">
              {{ scope.row.url }}
            </el-link>
          </template>
        </el-table-column>

        <el-table-column
            align="center"
            prop="checksum"
            label="checksum"
            width="100"/>

        <el-table-column
            align="center"
            prop="status"
            label="状态"
            width="200">
          <template slot-scope="scope">
            <el-tag :type="scope.row.status >= 0 ? ( scope.row.status === '2' ? 'warning' : 'success') :'danger' "
                    effect="dark">
              {{ getFunnyStatus(scope.row) }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            label="操作"
            width="200">
          <template slot-scope="scope">
            <el-button type="primary" size="medium" :disabled="scope.row.status>=0"
                       @click="updateFunnyPlay(scope.row)">检查通过
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      miniGameList: [],
      lastCheckTime: 0,
      checkInterval: 0,
      requestNum: 0,//当前请求数量
      showLoading: false,//是否显示刷新界面
      monitoringStatus: {},
      notifyEmail: '',
      countdown: 0
    };
  },
  beforeDestroy() {
    clearInterval(this.countdownInterval); // 在组件销毁前清除计时器
  },
  methods: {
    getFunnyStatus(row) {
      const status = row.status;
      console.log("status = " + status)
      //0：初始状态，1：正常，-1：变化异常，2：App不校验
      if (row.url.startsWith("baozou:") || status === '2') {
        return "App不校验"
      } else if (status === '0') {
        return "初始状态"
      } else if (status === '1') {
        return "正常"
      } else if (status === '-1') {
        return "变化异常"
      } else {
        return status
      }

    },
    formatDate(time) {
      var date = new Date(Number(time)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = (date.getSeconds() < 10 ? "0" + (date.getSeconds()) : date.getSeconds());
      return Y + M + D + h + m + s;
    },
    /**
     * 获取当前所有帖子列表
     * */
    queryAllMiniGameList: function () {
      this.showLoading = true;
      this.requestNum++;
      const that = this;
      const params = {}
      //如果是查看用户上传的模板帖子，需要显示同步按钮
      this.$request.post("/monitoring/queryFunnyPlayList", params)
          .then(function (data) {
            that.miniGameList = data.data.list
            that.lastCheckTime = data.data.lastCheckTime
            that.checkInterval = data.data.checkInterval
            that.notifyEmail = data.data.notifyEmail;
            that.hideLoading()
          }).catch(function (err) {
        console.log("请求结果返回 false =" + err.message)
        that.requestNum--;
        that.hideLoading()
        that.$confirm("网络错误", "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },
    /**
     * 获取当前所有帖子列表
     * */
    triggerCheckTask: function () {
      this.showLoading = true;
      this.requestNum++;
      const that = this;
      const params = {}
      //如果是查看用户上传的模板帖子，需要显示同步按钮
      this.$request.post("/monitoring/triggerCheckTask", params)
          .then(function (data) {
            that.miniGameList = data.data.list
            that.lastCheckTime = data.data.lastCheckTime
            that.checkInterval = data.data.checkInterval
            that.notifyEmail = data.data.notifyEmail;
            that.hideLoading()
          }).catch(function (err) {
        console.log("请求结果返回 false =" + err.message)
        that.requestNum--;
        that.hideLoading()
        that.$confirm("网络错误", "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },
    updateFunnyPlay: function (funnyPlayInfo) {
      this.showLoading = true;
      this.requestNum++;
      const that = this;
      const params = {
        id: funnyPlayInfo.id
      }
      //如果是查看用户上传的模板帖子，需要显示同步按钮
      this.$request.post("/monitoring/triggerSingleCheckTask", params)
          .then(function (data) {
            that.miniGameList = data.data.list
            that.lastCheckTime = data.data.lastCheckTime
            that.checkInterval = data.data.checkInterval
            that.notifyEmail = data.data.notifyEmail;
            that.hideLoading()
          }).catch(function (err) {
        console.log("请求结果返回 false =" + err.message)
        that.requestNum--;
        that.hideLoading()
        that.$confirm("网络错误", "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },
    /**
     * 调接判断隐藏加载界面
     * */
    hideLoading: function () {
      this.showLoading = false
    },
    fetchMonitoringStatus: function () {
      this.countdown = 60
      this.triggerCheckTask()
    },
  },
  mounted: function () {
    this.$bus.$emit("setMenuActiveIndex", "5-1");
    this.queryAllMiniGameList()
  },
};
</script>

<style scoped>
.image_box {
  width: 100%;
  min-height: 100%;
  background: white;
  border-radius: 5px;
  padding: 10px 20px;
}

.box_operation {
  width: 100%;
  padding: 10px 0;
}

.input_box {
  display: inline-block;
  width: 200px;
  margin: 0 10px;
  margin-top: 10px;
}

.input_box /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 90%;
  margin: 0 auto;
}

.btn_box {
  height: 40px;
  margin-top: 10px;
  margin: 10px;
  vertical-align: top;
  display: inline-block;
}

.input_box_select {
  width: 150px;
}

.data_box {
  width: 100%;

}

.preview_image {
  width: 120px;
  height: 120px;
}

.data_box /deep/ .el-table .warning-row {
  background: oldlace;
}

.data_box /deep/ .el-table .success-row {
  background: #f0f9eb;
}

.page {
  width: 100%;
  margin: 20px 0;
  text-align: center;
}

.table {
  /*position: fixed;*/
  height: 100%;
}

</style>
