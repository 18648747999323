<template>
    <div class="model_box" v-loading.fullscreen.lock="showLoading">
        <div class="box_operation">
            <!--            操作列表-->
            <div class="input_box">
                <el-input placeholder="请输入模型名称" v-model="searchName" class="input-with-select"></el-input>
            </div>
            <div class="input_box">
                <el-input placeholder="请输入模型类型" v-model="searchType" class="input-with-select"></el-input>
            </div>
            <div class="btn_box">
                <el-button type="warning"  @click="search">搜索</el-button>
            </div>
            <div class="btn_box">
                <el-button type="primary"  @click="addModel">添加</el-button>
            </div>
            <div class="btn_box">
                <el-button type="success"  @click="reset">重置</el-button>
            </div>
            <div class="btn_box">
                <el-button type="danger"  @click="getModels">刷新</el-button>
            </div>
        </div>
        <div class="data_box">
            <el-table
                    border
                    :data="models"
                    style="width: 100%">
                <el-table-column
                        align="center"
                        prop="id"
                        label="id"
                        width="80">
                </el-table-column>
                <el-table-column
                        align="center"
                        prop="name"
                        label="模型名称"
                        width="300">
                </el-table-column>
                <el-table-column
                        prop="type"
                        align="center"
                        label="类型"
                        width="100">
                </el-table-column>
                <el-table-column
                        align="center"
                        label="简介"
                        prop="describe"
                        width="180">
                </el-table-column>
                <el-table-column
                        align="center"
                        label="模型文件数量"
                        width="180">
                    <template slot-scope="scope">
                        <p>{{(scope.row.urlList.length)}}</p>
                    </template>
                </el-table-column>
                <el-table-column
                        min-width="240"
                        align="center"
                        label="操作">
                    <template slot-scope="scope">
                        <el-button type="success" size="medium" icon="el-icon-download" @click="downModel(scope.row)"></el-button>
                        <el-button type="primary" size="medium" icon="el-icon-edit" @click="updateModel(scope.row)"></el-button>
                        <el-button type="danger" size="medium" icon="el-icon-delete" @click="delModel(scope.row.id)"></el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div class="page">
                <el-pagination
                        background
                        :page-size="5"
                        :current-page="pageNo"
                        layout="prev, pager, next"
                        :page-count="pageSum"
                        @current-change="changePage">
                </el-pagination>
            </div>
        </div>
        <el-dialog :title="dialogTitle"
                   @close="closeDialog"
                   top="5vh"
                   :visible.sync="showDialog"
                   :close-on-click-modal="false"
                   width="800px"
                   :destroy-on-close="true"
                   :fullscreen="false">
            <ModelEdit v-on:saveStatus="saveStatus"  :model="selectModel"  :is-update="isUpdate"/>
        </el-dialog>
    </div>
</template>

<script>
    import ModelEdit from "./ModelEdit";
    export default {
        name: "ModelResource",
        components:{
            ModelEdit
        },
        data:function(){
            return{
                dialogTitle:"",
                showDialog:false,
                showLoading:false,
                selectModel:{},
                isUpdate:false,
                searchName:"",
                searchType:"",
                models:[],
                pageNo:1,
                pageCount:20,
                pageSum:1
            }
        },
        methods:{
            getModels:function () {
                this.showLoading = true;
                const that = this;
                const params = new URLSearchParams()
                params.append("isPage",1)
                params.append("pageNo",this.pageNo)
                params.append("pageCount",this.pageCount)
                params.append("name",this.searchName)
                params.append("type",this.searchType)
                this.$request.post("/model/getModeFileList",params)
                    .then(function (data) {
                        if (data.status === 200){
                            that.models = data.data.records
                            that.pageSum = data.data.pages
                            that.showLoading = false
                        }else {
                            that.showMsg(data.data)
                        }
                    }).catch(function (err) {
                        console.log(err)
                        that.showLoading = false
                        that.showMsg("网络错误")
                })
            },
            /**
             * 显示错误消息
             * @param title
             */
            showMsg:function (title) {
                this.$confirm(title, "提示", {
                    confirmButtonText: '确定',
                    showClose:false,
                    showCancelButton:false,
                    center:true,
                    type: 'error'
                })
            },
            /**
             * 页码改变时
             * @param pageNo
             */
            changePage:function (pageNo) {
                this.pageNo = pageNo
                this.getModels()
            },
            /**
             * 搜索
             */
            search:function(){
               this.pageNo = 1;
               this.getModels()
            },
            /**
             * 添加
             */
            addModel:function(){
                this.dialogTitle = "添加模型文件"
                this.isUpdate = false
                this.showDialog = true
            },
            /**
             * 重置
             */
            reset:function(){
                this.searchName = ""
                this.searchType = ""
                this.pageNo = 1
                this.getModels()
            },
            /**
             * 下载
             * @param model
             */
            downModel:function (model) {
                let list = model.urlList
                for(let i = 0; i < list.length; i++){
                    window.open(list[i])
                }
            },
            /**
             * 更新
             * @param model
             */
            updateModel:function (model) {
                model.fileList = [];
                let list = model.urlList;
                for(let i = 0; i < list.length; i++){
                    model.fileList[i] = {name:list[i],url:list[i]}
                }
                this.dialogTitle = "修改模型";
                this.isUpdate = true;
                this.showDialog = true;
                this.selectModel = model;
            },
            /**
             * 删除
             * @param id
             */
            delModel:function (id) {
                this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning',
                  cancelButtonClass:"messagebox-off-button-color",
                  confirmButtonClass:"messagebox-ok-button-color"
                }).then(() => {
                    this.del(id)
                }).catch(()=>{});
            },
            del:function(id){
                this.showLoading = true;
                const that = this;
                const params = new URLSearchParams()
                params.append("id",id)
                this.$request.post("/model/delModelFile",params)
                    .then(function (data) {
                        that.showLoading = false;
                        if (data.status === 200){
                            that.$confirm("删除成功", "提示", {
                                confirmButtonText: '确定',
                                showClose:false,
                                showCancelButton:false,
                                center:true,
                                type: 'success'
                            }).then(function () {
                                that.getModels()
                            })
                        }else {
                            that.showMsg(data.data)
                        }
                    }).catch(function (err) {
                        that.showLoading = false;
                        that.showMsg("网络错误")
                })
            },
            closeDialog:function () {
                this.selectModel = {}
            },
            saveStatus:function (flag) {
                if (flag){
                    this.searchType ="";
                    this.searchName = "";
                    this.pageNo = 1;
                    this.getModels()
                }
                this.showDialog = false
            }
        },
        mounted() {
            this.$bus.$emit("setMenuActiveIndex","1-2-2");
            this.getModels()
        }
    }
</script>

<style scoped>
    .model_box{
        width: 100%;
        min-height: 100%;
        background: white;
        border-radius: 5px;
        padding: 10px 20px;
    }
    .box_operation{
        width: 100%;
        padding: 20px 0;
    }
    .input_box{
        display: inline-block;
        width: 200px;
        margin: 0 10px;
        margin-top: 10px;
    }
    .btn_box{
        margin-top: 10px;
        height: 40px;
        margin-left: 20px;
        vertical-align: top;
        display: inline-block;
    }
    .input_box_select{
        width: 150px;
    }
    .data_box{
        width: 100%;
    }
    .preview_image{
        width: 80px;
        height: 80px;
    }
    .data_box /deep/ .el-table .warning-row {
        background: oldlace;
    }

    .data_box /deep/ .el-table .success-row {
        background: #f0f9eb;
    }
    .page{
        width: 100%;
        margin: 20px 0;
        text-align: center;
    }
</style>
