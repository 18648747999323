<template>
  <div class="image_box" v-loading.fullscreen.lock="showLoading">
    <!--    <div class="box_operation">-->
    <!--      <div class="btn_box">-->
    <!--        <el-button type="primary" @click="addNewFunnyPlay">新增趣玩</el-button>-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="grid-container">
      <div v-for="item in funnyPlayList" :key="item.id" class="grid-item">
        <div class="grid-item-content">
          <p>{{ item.title }}</p>
          <a :href="item.imgUrl" target="_blank">
            <img :src="item.imgUrl" class="preview_image"/>
          </a>
          <p>ID: {{ item.id }}</p>
          <p>热度:
            <el-tag type="success">{{ item.heat }}</el-tag>
          </p>
          <p>类型:
            <el-tag>{{ item.tag }}</el-tag>
          </p>
          <p style="color: #409EFF; font-size: 12px;">提示: {{ item.toast }}</p>
          <p>禁用渠道: {{ item.disabledChannel }}</p>
          <div class="actions">
            <el-button type="primary" size="medium" icon="el-icon-edit" @click="updateImage(item)">更新图标</el-button>
          </div>
        </div>
      </div>
    </div>


    <el-dialog :title="dialogTitle"
               @close="closeDialog"
               top="5vh"
               :visible.sync="showDialog"
               :close-on-click-modal="false"
               width="800px"
               :destroy-on-close="true"
               :fullscreen="false">
      <FunnyPlayEdit v-on:saveStatus="saveStatus" :funnyPlayItem="curFunnyPlayItem" :is-update="isUpdate"/>
    </el-dialog>
  </div>
</template>

<script>

import FunnyPlayEdit from "@/components/funnyPlay/FunnyPlayEdit.vue";

export default {
  components: {FunnyPlayEdit},
  data() {
    return {
      funnyPlayList: [],
      lastCheckTime: 0,
      checkInterval: 0,
      requestNum: 0,//当前请求数量
      showLoading: false,//是否显示刷新界面
      monitoringStatus: {},
      notifyEmail: '',
      countdown: 0,
      dialogTitle: "",
      curFunnyPlayItem: {},
      isUpdate: false,
      showDialog: false,
    };
  },
  beforeDestroy() {
    clearInterval(this.countdownInterval); // 在组件销毁前清除计时器
  },
  methods: {
    updateImage: function (funnyItem) {
      this.dialogTitle = "更新趣玩"
      this.curFunnyPlayItem = funnyItem
      this.isUpdate = true
      this.showDialog = true
    },
    addNewFunnyPlay: function () {
      this.dialogTitle = "增加新趣玩"
      this.isUpdate = false
      this.showDialog = true
    },
    closeDialog: function () {
      this.curFunnyPlayItem = {}
    },
    saveStatus: function (flag) {
      if (flag) {
        this.queryAllFunnyPlayList()
      }
      this.showDialog = false
    },
    formatDate(time) {
      var date = new Date(Number(time)); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() + "-";
      var M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
      var D = date.getDate() + " ";
      var h = date.getHours() + ":";
      var m = date.getMinutes() + ":";
      var s = (date.getSeconds() < 10 ? "0" + (date.getSeconds()) : date.getSeconds());
      return Y + M + D + h + m + s;
    },
    /**
     * 获取当前所有帖子列表
     * */
    queryAllFunnyPlayList: function () {
      this.showLoading = true;
      this.requestNum++;
      const that = this;
      const params = {}
      //如果是查看用户上传的模板帖子，需要显示同步按钮
      this.$request.post("/funnyPlay/queryFunnyPlayList", params)
          .then(function (data) {
            console.log("请求结果成功  =" + data.data)
            that.funnyPlayList = data.data
            that.hideLoading()
          }).catch(function (err) {
        console.log("请求结果返回 false =" + err.message)
        that.requestNum--;
        that.hideLoading()
        that.$confirm("网络错误", "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },
    /**
     * 获取当前所有帖子列表
     * */
    triggerCheckTask: function () {
      this.showLoading = true;
      this.requestNum++;
      const that = this;
      const params = {}
      //如果是查看用户上传的模板帖子，需要显示同步按钮
      this.$request.post("/monitoring/triggerCheckTask", params)
          .then(function (data) {
            // 使用 reduce 对数据进行分类
            let categorizedData = data.data.reduce((acc, item) => {
              // 如果分类不存在则创建
              if (!acc[item.tag]) {
                acc[item.tag] = [];
              }
              // 将 item 放入对应的 tag 分类中
              acc[item.tag].push(item);
              return acc;
            }, {});

            // 将分类数据按顺序展开为数组
            let sortedData = [];
            for (let tag in categorizedData) {
              sortedData = sortedData.concat(categorizedData[tag]);
            }

            // 将分类后的数据赋值给 funnyPlayList
            that.funnyPlayList = sortedData;
            that.hideLoading()
          }).catch(function (err) {
        console.log("请求结果返回 false =" + err.message)
        that.requestNum--;
        that.hideLoading()
        that.$confirm("网络错误", "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },
    /**
     * 调接判断隐藏加载界面
     * */
    hideLoading: function () {
      this.showLoading = false
    },
    fetchMonitoringStatus: function () {
      this.countdown = 60
      this.triggerCheckTask()
    },
  },
  mounted: function () {
    this.$bus.$emit("setMenuActiveIndex", "5-2");
    this.queryAllFunnyPlayList()
  },
};
</script>

<style scoped>
.image_box {
  width: 100%;
  min-height: 100%;
  background: white;
  border-radius: 5px;
  padding: 10px 20px;
}

.box_operation {
  width: 100%;
  padding: 10px 0;
}

.input_box {
  display: inline-block;
  width: 200px;
  margin: 0 10px;
  margin-top: 10px;
}

.input_box /deep/ .el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 90%;
  margin: 0 auto;
}

.btn_box {
  height: 40px;
  margin-top: 10px;
  margin: 10px;
  vertical-align: top;
  display: inline-block;
}

.input_box_select {
  width: 150px;
}

.data_box {
  width: 100%;

}

.preview_image {
  width: 120px;
  height: 120px;
}

.data_box /deep/ .el-table .warning-row {
  background: oldlace;
}

.data_box /deep/ .el-table .success-row {
  background: #f0f9eb;
}

.page {
  width: 100%;
  margin: 20px 0;
  text-align: center;
}

.table {
  /*position: fixed;*/
  height: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(7, 1fr); /* 每排四个 */
  gap: 20px;
  padding: 10px;
}

.grid-item {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 15px;
  text-align: center;
}

.preview_image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.actions {
  margin-top: 10px;
}

.grid-item-content p {
  margin: 5px 0;
}
</style>
