<template>
  <div class="tutorial_box" v-loading.fullscreen.lock="showLoading">
    <div class="box_operation">
      <!--            操作列表-->
      <div class="btn_box">
        <el-button type="primary" @click="addMessage">添加</el-button>
      </div>
      <div class="btn_box">
        <el-button type="success" @click="reset">重置</el-button>
      </div>
      <div class="btn_box">
        <el-button type="danger" @click="getComfyStyleList">刷新</el-button>
      </div>
    </div>
    <div class="data_box">
      <el-table
          class="table"
          @sort-change="sortChange"
          border
          :data="comfyStyleList"
          style="width: 100%">
        <el-table-column
            align="center"
            prop="id"
            label="id"
            width="60">
        </el-table-column>
        <el-table-column
            prop="type"
            width="100"
            align="center"
            label="类型">
          <template slot-scope="scope">
            <p>{{ getType(scope.row.type) }}</p>
          </template>
        </el-table-column>
        <el-table-column
            align="center"
            width="200"
            label="图片">
          <template slot-scope="scope">
            <a :href="'http://file.baozouptu.intelimeditor.com/'+scope.row.url" target="_blank"
               v-if="scope.row.url != null&&scope.row.url.length>0 && !scope.row.url.endsWith('mp4')">
              <img :src="'http://file.baozouptu.intelimeditor.com/'+scope.row.url" class="preview_image"/>
            </a>
            <p v-else>暂无</p>
          </template>
        </el-table-column>
        <el-table-column
            prop="tag"
            width="100"
            align="center"
            label="原图-效果图">
        </el-table-column>
        <el-table-column
            width="120"
            prop="editPartName"
            align="center"
            label="编辑的部位名称">
        </el-table-column>
        <el-table-column
            width="100"
            prop="prompt"
            align="center"
            label="提示词">
        </el-table-column>
        <el-table-column
            width="180"
            align="center"
            label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="medium" icon="el-icon-edit"
                       @click="updateMessage(scope.row)"></el-button>

            <el-button type="danger" size="medium" icon="el-icon-delete"
                       @click="delMessage(scope.row.id)"></el-button>


          </template>
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
            background
            :page-size="5"
            :current-page="pageNo"
            layout="prev, pager, next"
            :page-count="pageSum"
            @current-change="changePage">
        </el-pagination>
      </div>
    </div>
    <el-dialog :title="dialogTitle"
               @close="closeDialog"
               top="5vh"
               :visible.sync="showDialog"
               :close-on-click-modal="false"
               width="800px"
               :destroy-on-close="true"
               :fullscreen="false">
      <MessageEdit v-on:saveStatus="saveStatus" :messageInfo="selectMessage" :is-update="isUpdate"/>
    </el-dialog>
  </div>
</template>

<script>
import MessageEdit from "./ComfyStyleEdit.vue";

export default {
  name: "messageCenter",
  components: {
    MessageEdit
  },
  data: function () {
    return {
      showLoading: false,//是否显示弹框
      comfyStyleList: [],//教程列表
      pageNo: 1,
      pageCount: 20,
      pageSum: 1,
      searchName: "",
      searchKey: "",
      orderType: 2,
      showMore: -1,
      showMores: [
        {
          label: "全部",
          value: -1
        }, {
          label: "不显示更多",
          value: 0
        }, {
          label: "显示更多",
          value: 1
        }
      ],
      dialogTitle: "",
      selectMessage: {
        type: 1,
        title: '',
        editPartName: '',
        description: '',
        fileList: [],
        jumpUrl: '',
        channel: ''
      },
      isUpdate: false,
      showDialog: false,
    }
  },
  methods: {
    //获取类型
    getType: function (type) {
      if (type === 1) {
        return "发型"
      } else if (type === 2) {
        return "服装"
      } else if (type === 3) {
        return "写真"
      } else {
        return "其他"
      }
    },
    //获取真实地址信息
    getRealUrl: function (url) {
      return 'http://file.baozouptu.intelimeditor.com/' + url;
    },
    getComfyStyleList: function () {
      this.showLoading = true;
      const that = this;
      const params = {}
      this.$request.post("/comfyAI/styleList", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      })
          .then(function (data) {
            that.showLoading = false
            console.log("返回结果", data)
            if (data.status === 200) {
              that.comfyStyleList = data.data
            } else {
              that.showMsg(data.data)
            }
          }).catch(function (err) {
        console.log(err)
        that.showLoading = false
        that.showMsg("网络错误")
      })
    },
    /**
     * 显示错误消息
     * @param title
     */
    showMsg: function (title) {
      this.$confirm(title, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
    changePage: function (pageNo) {
      this.pageNo = pageNo;
      this.getComfyStyleList()
    },
    search: function () {
      this.pageNo = 1;
      this.orderType = 2;
      this.getComfyStyleList()
    },
    reset: function () {
      this.pageNo = 1;
      this.searchName = "";
      this.searchKey = "";
      this.showMore = -1;
      this.orderType = 2;
      this.getComfyStyleList()
    },
    /**
     * 排序规则改变
     */
    sortChange: function ({column, prop, order}) {
      switch (order) {
        case "ascending":
          //升序
          this.orderType = 1;
          break;
        case "descending":
          //升序
          this.orderType = 2;
          break;
        case null:
          //不排序
          this.orderType = 0;
          break;
      }
      this.pageNo = 1;//重置页码
      this.getComfyStyleList()
    },
    closeDialog: function () {
      this.selectMessage = {}
    },
    saveStatus: function (flag) {
      if (flag) {
        this.reset();
        this.getComfyStyleList()
      }
      this.showDialog = false
    },
    addMessage: function () {
      this.dialogTitle = "添加消息"
      this.isUpdate = false
      this.showDialog = true
    },
    updateMessage: function (message) {
      if (message.url != null) {
        message.fileList = [{
          name: this.getRealUrl(message.url),
          url: this.getRealUrl(message.url)
        }]
      } else {
        message.fileList = []
      }
      if (message.videoUrl != null) {
        message.videoFile = [{
          name: this.getRealUrl(message.videoUrl),
          url: this.getRealUrl(message.videoUrl)
        }]
      } else {
        message.videoFile = []
      }

      this.dialogTitle = "修改消息"
      this.isUpdate = true
      this.showDialog = true
      this.selectMessage = message
    },
    delMessage: function (id) {
      this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        cancelButtonClass: "messagebox-off-button-color",
        confirmButtonClass: "messagebox-ok-button-color"
      }).then(() => {
        this.del(id)
      }).catch(() => {
      });
    },
    /**
     * 删除一个图片
     * @param id
     */
    del: function (id) {
      this.requestNum++;
      this.showLoading = true;
      const that = this;
      const params = {
        "delete": [id]
      }
      this.$request.post("/comfyAI/deleteStyle", params, {
        // json格式
        headers: {
          "Content-Type": "application/json"
        },
      })
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("删除成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.getComfyStyleList()
              })
            } else {
              that.showMsg(data.data);
            }
          }).catch(function (err) {
        that.showLoading = false
        that.showMsg("网络错误");
      })
    }
  },
  mounted() {
    this.$bus.$emit("setMenuActiveIndex", "7-1");
    this.getComfyStyleList()
  }
}
</script>

<style scoped>
.tutorial_box {
  width: 100%;
  min-height: 100%;
  background: white;
  border-radius: 5px;
  padding: 10px 20px;
}

.box_operation {
  width: 100%;
  padding: 20px 0;
}

.input_box {
  display: inline-block;
  width: 200px;
  margin: 0 10px;
  margin-top: 10px;
}

.btn_box {
  margin-top: 10px;
  height: 40px;
  margin-left: 20px;
  vertical-align: top;
  display: inline-block;
}

.input_box_select {
  width: 150px;
}

.data_box {
  width: 100%;
}

.preview_image {
  width: 80px;
  height: 80px;
}

.data_box /deep/ .el-table .warning-row {
  background: oldlace;
}

.data_box /deep/ .el-table .success-row {
  background: #f0f9eb;
}

.page {
  width: 100%;
  margin: 20px 0;
  text-align: center;
}

.table {
  /*position: fixed;*/
  height: 100%;
}
</style>
