<template>
  <!--    图片添加修改-->
  <div class="imageBox" v-loading="showLoading">
    <el-form ref="form" :model="images" label-width="80px">
      <div class="inputBox">
        <el-form-item label="预览图片">
          <el-upload
              class="upload-demo"
              action="none"
              multiple
              accept="image/*"
              :limit="100"
              :file-list="images.fileList"
              :on-exceed="imageCountOut"
              :on-remove="handleRemove"
              :on-change="fileChange"
              :before-upload="beforeUpload"
              :auto-upload="false"
              list-type="picture-card">
            <el-button size="small" type="primary">
              点击选择
            </el-button>
            <div slot="tip" class="el-upload__tip">一次最多上传50张图片文件，且不超过1MB</div>
          </el-upload>
        </el-form-item>
        <el-form-item label-width="300" label="是否差异化标签名，图片名也作为一个标签名，例如：输入的标签-图片名">
          <el-switch
              v-model="isUseImageName"
              :active-value="true"
              :inactive-value="false"
              active-color="#67c23a"
              inactive-color="#dcdfe6">
          </el-switch>

        </el-form-item>
        <el-form-item label="图片标签">
          <el-input v-model="images.tag" class="input"></el-input>&nbsp;&nbsp;
          图片热度&nbsp;&nbsp;<el-input v-model="images.heat" class="input" type="number"></el-input>
          <el-tag style="margin-left: 20px">图片类型：{{ imageType }}</el-tag>
        </el-form-item>
        <el-form-item label="图片分类">
          <el-cascader
              @change="selectChange"
              v-model="images.categoryIds"
              :options="categoryList"
              :props="{ value:'id',label:'categoryName',children:'childList'}">
          </el-cascader>

        </el-form-item>
        <el-form-item label="模板类型" v-if="hasShowOperation()">
          <el-select v-model="images.templateType" placeholder="请选择模板类型">
            <el-option
                v-for="item in templateType"
                :key="item.value"
                :label="item.label"
                :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </div>
      <el-form-item class="btn">
        <el-button type="primary" @click="save">保存</el-button>
        <el-button @click="cancel">取消</el-button>
      </el-form-item>
    </el-form>
    <el-dialog :title="dialogTitle"
               @close="closeDialog"
               top="5vh"
               @opened="dialogOpen"
               :visible.sync="showDialog"
               :close-on-click-modal="false"
               width="800px"
               append-to-body
               :destroy-on-close="true"
               :fullscreen="false">
      <TemplateDataAdd v-on:saveStatus="saveStatus"
                       :templateData="templateData"
                       :id="images.id"
                       :is-title="isTitle"
                       :type="images.templateType"
                       :is-load-data="isLoadDialog"
                       :is-add-image-data="isAddImageData"
                       :is-update="isUpdateTemplateData"/>
    </el-dialog>
  </div>
</template>

<script>
import TemplateDataAdd from "./TemplateDataAdd";
import {stringify} from "../../../public/minigame/secha/secha_files/21287325";

export default {
  name: "SearchImageUpload",
  props: ['images', "categoryList", "isUpdate", "loadData", "imageType"],
  components: {
    TemplateDataAdd
  },
  watch: {
    loadData: function () {
    }
  },
  data() {
    return {
      dialogTitle: "",
      showDialog: false,
      showLoading: false,
      postCount: 0,
      templateData: {},
      templateDataList: [],
      secondCategoryId: 2,
      isTitle: false,
      isUseImageName: false,
      isUpdateTemplateData: false,
      isAddImageData: false,
      isLoadDialog: false,
      templateType: [{
        label: "图片",
        value: 0
      }, {
        label: "视频",
        value: 1
      }]
    }
  },
  methods: {
    getFontLocation: function (location) {
      switch (location) {
        case "left":
          return "左边";
        case "right":
          return "右边";
        case "center":
          return "中间";
      }
      return "";
    },
    getOperations: function (operations) {
      switch (operations) {
        case "head":
          return "换头";
        case "face":
          return "抠脸";
      }
      return "无";
    },
    //是否显示操作选项
    hasShowOperation: function () {
      if (this.isUpdate) {
        if (this.images.secondCategory != null && this.images.secondCategory.id === 2 && this.secondCategoryId === 0) {
          return true;
        } else {
          return this.secondCategoryId === 2
        }
      } else {
        return false;
      }
    },
    imageCountOut: function () {
      this.showErrorMessage("只能选择一个文件")
    },
    /**
     * 图片移除回调
     * @param file
     * @param fileList
     */
    handleRemove: function (file, fileList) {
      console.log("fileChange file " + file + " fileList " + fileList.raw)
      // this.images.files = fileList
      if (this.images.files === undefined) {
        this.images.files = []
      }
      this.images.files = this.images.files.filter(function (item, index, arr) {
        return (file.name !== item.name);
      })
    },
    cancel: function () {
      this.$emit('saveStatus', false)
    },
    /**
     * 保存上传
     * */
    save() {
      if (!this.isUpdate) {
        this.addImage()
      } else {
        this.update()
      }
    },
    /**
     * 图片选择回调
     * @param file
     * @param fileList
     */
    fileChange: function (file, fileList) {
      console.log("fileChange file " + file + " fileList " + fileList)
      if (!this.beforeUpload(file)) {
        return true
      }
      if (this.images.files === undefined) {
        this.images.files = []
      }
      this.images.files.push(file.raw)
      // this.images.files = fileList
      //如果是更新，更新图片时，不替换标签
      // if (!this.isUpdate)
      //   this.$set(this.images, "tag", file.raw.name.substring(0, file.raw.name.indexOf('.')))
    },
    beforeUpload(file) {
      // const isImage = file.type.startsWith('image/');
      const isLt2M = file.size / 1024 / 1024 < 1;
      console.log("beforeUpload file.type:" + file.type + " isLt2M " + isLt2M)
      // if (!isImage) {
      //   this.$message.error('上传头像图片只能是 图片（image） 格式!');
      // }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 1MB!');
      }
      return isLt2M;
    },
    selectChange: function (data) {
      //匹配三级id
      if (data !== undefined) {
        if (data.length === 3) {
          this.images.categoryId = data[2]
        }
        if (data.length >= 2) {
          this.secondCategoryId = data[1];
        }
      }
    },
    /**
     * 显示错误信息弹框
     * @param msg 错误信息弹框
     */
    showErrorMessage: function (msg) {
      this.$confirm(msg, "提示", {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        center: true,
        type: 'error'
      })
    },
    /**
     * 上传图片
     */
    addImage: function () {
      if ((this.images.tag === '' || this.images.tag === undefined) && !this.isUseImageName) {
        this.showErrorMessage("图片名不能为空，请输入标签或者选中使用文件名")
        return
      }
      //如果没有输入标签，选中了使用文件名，标签赋值为空
      if ((this.images.tag === '' || this.images.tag === undefined) && this.isUseImageName) {
        this.images.tag = ""
      }
      if (this.images.heat === undefined || this.images.heat === "") {
        this.showErrorMessage("热度不能为空")
        return
      }
      if (this.images.categoryId === null || this.images.categoryId === undefined) {
        this.showErrorMessage("分类必须是三级分类")
        return
      }
      if (this.images.files === null) {
        this.showErrorMessage("图片不能为空")
        return
      }
      this.showLoading = true;
      const that = this;
      const params = new FormData()
      params.append("tag", this.images.tag)
      params.append("hot", this.images.heat)
      params.append("categoryId", this.images.categoryId)
      params.append("isLock", 0)
      params.append("addFileNameToTag", this.isUseImageName)
      params.append("imageType", this.imageType)

      // 遍历文件数组并打印文件名
      for (let i = 0; i < this.images.files.length; i++) {
        const file = this.images.files[i];
        const fileName = file.name;
        console.log(`File ${i + 1} Name: ${fileName}`);
      }

      for (let i = 0; i < this.images.files.length; i++) {
        params.append("files", this.images.files[i])
      }

      // 遍历 FormData 中的数据并打印
      for (const pair of params.entries()) {
        console.log(pair[0], pair[1]);
      }
      console.log("isUseImageName" + this.isUseImageName)
      this.$request.post("/images/uploadImages", params, {
        timeout: 180000, //3分钟
      })
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              console.log("返回结果：" + data.data)

              that.$confirm("添加成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.$emit('saveStatus', true)
              })
            } else {
              that.showErrorMessage(data.message)
            }
          }).catch(function (err) {
        that.showLoading = false
        that.$confirm("网络错误", "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },
    update: function () {
      this.showLoading = true;
      const that = this;
      const params = new FormData()
      params.append("id", this.images.id)
      params.append("tag", this.images.tag)
      params.append("isLock", this.images.isLock)
      // for (let i = 0; i < this.images.files.length; i++) {
      //   params.append("files", this.images.files[i])
      // }
      if (this.images.heat === undefined || this.images.heat === "") {
        this.showErrorMessage("热度不能为空")
        return
      }
      params.append("hot", this.images.heat)
      params.append("categoryId", this.images.categoryId)
      this.$request.post("/images/updateImage", params)
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("修改成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
                that.$emit('saveStatus', true)
              })
            } else {
              that.showErrorMessage(data.message)
            }
          }).catch(function (err) {
        that.showLoading = false
        that.$confirm("网络错误", "提示", {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          center: true,
          type: 'error'
        })
      })
    },
    //添加文字信息
    addFontData: function () {
      this.dialogTitle = "添加文字信息"
      this.isAddImageData = false
      this.showDialog = true
    },
    //添加图片信息
    addImageData: function () {
      this.dialogTitle = "添加图片信息"
      this.isAddImageData = true
      this.showDialog = true
    },
    closeDialog: function () {
      this.templateData = {}
      this.isUpdateTemplateData = false
      this.isAddImageData = false
      this.isLoadDialog = false
    },
    /**
     * 数据保存状态
     * @param info
     */
    saveStatus: function (info) {
      this.showDialog = false
    },
    updateTemplateData: function (data) {
      this.isUpdateTemplateData = true
      this.templateData = data
      if (data.type === 1) {
        this.dialogTitle = "修改图片信息"
        this.showDialog = true
      } else {
        this.dialogTitle = "修改文本信息"
        this.showDialog = true
      }
    },
    delTemplateData: function (id) {
      this.showLoading = true
      const params = new URLSearchParams()
      const that = this;
      params.append("id", id)
      this.$request.post("/autoTemplate/delAutoTemplateById", params)
          .then(function (data) {
            that.showLoading = false
            if (data.status === 200) {
              that.$confirm("删除成功", "提示", {
                confirmButtonText: '确定',
                showClose: false,
                showCancelButton: false,
                center: true,
                type: 'success'
              }).then(function () {
              })
            } else {
              that.showMessage(data.message)
            }
          }).catch(function (err) {
        that.showLoading = false
        that.showMessage("网络错误")
      })
    },
    dialogOpen: function () {
      this.isLoadDialog = true
    }
  },
  mounted() {
    if (this.images.heat === undefined) {
      this.$set(this.images, "heat", 4000)
    }

    if (this.images.categoryIds === undefined) {
      this.$set(this.images, "categoryIds", [1, 2, 4])
      this.$set(this.images, "categoryId", 4)

    }
  }
}
</script>

<style scoped>
.imageBox {
  width: 100%;
  min-height: 300px;
}

.inputBox {
  max-height: 600px;
  overflow-y: scroll;
  margin-bottom: 20px;
  padding-bottom: 20px;
}

.inputBox::-webkit-scrollbar {
  display: none;
}

.btn /deep/ .el-form-item__content {
  margin-left: 0 !important;
  text-align: center;
}

.input {
  width: 300px;
}

.operationBox {
  width: 100%;
}
</style>
